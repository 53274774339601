import React, { useEffect } from "react";
import { Col, Form, Input, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import { FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";

export const PolicyholderDetails = ({ policyholderSameAsPatientField, policyholderNameField }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const policyholderSameAsPatient = Form.useWatch(policyholderSameAsPatientField, form);

  useEffect(() => {
    form.validateFields();
  }, [policyholderSameAsPatient]);

  return (
    <>
      <Row gutter={2}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            style={{ paddingTop: 10 }}
            name={policyholderSameAsPatientField}
            label={t("application_form_editor.pfizer.insurance.labels.policyholder_same_as_patient")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
        {policyholderSameAsPatient === false && (
          <Col span={8}>
            <FormItem
              name={policyholderNameField}
              label={t("application_form_editor.pfizer.insurance.labels.policyholder_full_name")}
              rules={[{ required: true }, onlyWhitespacesRule]}
            >
              <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.policyholder_full_name")} />
            </FormItem>
          </Col>
        )}
      </Row>
    </>
  );
};
