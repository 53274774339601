import { Alert, Typography } from "antd";
import React from "react";
import { BLUE_3, ERROR_25, ERROR_600, LIGHT_BLUE_5 } from "../../../../../constant/colors";
import { CloseCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { fontWeights, sizes } from "../../../../../constant/styles";

const { Text } = Typography;

const styles = Object.freeze({
  blue: {
    backgroundColor: LIGHT_BLUE_5,
    color: BLUE_3,
    type: "info",
    icon: <InfoCircleOutlined style={{ marginTop: 5, fontSize: sizes.large }} />
  },
  red: {
    backgroundColor: ERROR_25,
    color: ERROR_600,
    type: "error",
    icon: <CloseCircleOutlined style={{ marginTop: 5, fontSize: sizes.large }} />
  }
});

export const Note = ({ ...props }) => {
  const color = props?.color || "blue";

  return (
    <div>
      <Alert
        style={{ backgroundColor: styles[color].backgroundColor, borderRadius: 8, marginBottom: 20 }}
        message={
          <Text style={{ color: styles[color].color, fontWeight: fontWeights.bold, fontSize: sizes.medium }}>
            {props.title}
          </Text>
        }
        description={<Text style={{ color: styles[color].color }}>{props.description}</Text>}
        type={styles[color].type}
        showIcon
        icon={styles[color].icon}
      />
    </div>
  );
};
