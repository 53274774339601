import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Divider, Form, Input, InputNumber, Space, Typography } from "antd";
import styled from "styled-components";
import _, { debounce, noop } from "lodash";
import moment from "moment-timezone";
import confirm from "antd/lib/modal/confirm";
import { CloseOutlined } from "@ant-design/icons";
import { displayedMRN } from "@tailormed/common-client/util/mrn";

import { flattenObject } from "../../utils/misc";
import { addNewPatient, validateMrnUniqueness } from "../../api/api";
import { ReactComponent as AlertIcon } from "../../assets/svg/alert-icon-popup.svg";
import { PrimaryButton } from "../customComponent/Button";
import { WHITE } from "../../constant/colors";
import { CustomDatePicker } from "../customComponentNewDesign/customDatePicker";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { CREATE_NEW_PATIENT_STEPS } from "../../constant/patient";
import { ReactComponent as InputValidIcon } from "../../assets/svg/input-valid-icon.svg";
import { ReactComponent as InputInvalidIcon } from "../../assets/svg/input-invalid-icon.svg";
import { customerTypes } from "../../constant/customerType";
import { phoneNumberLengthRegEx } from "../../utils/formValidation";
import { useGetSetting } from "../../hooks/getSetting";
import { formatPhoneNumberWhileTyping } from "../../utils/formaters";
import { StyledNewPatientHeader } from "./styles";

const { Text } = Typography;

const CONFLICT_STATUS_CODE = 409;

const CustomInput = styled(Input)`
  &.ant-input {
    height: 40px;
    width: 184.25px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
  }
  &.ant-input-group-addon {
    color: #98a2b3;
    background: #ffffff;
    border-radius: 4px 0px 0px 4px;
  }
  &.ant-input-affix-wrapper {
    height: 40px;
    width: 184.25px;
  }
`;

const CustomInputNumber = styled(InputNumber)`
  width: 184.25px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;

  .ant-input-number-group-addon {
    color: #98a2b3;
    background: #ffffff;
    border-radius: 4px 0px 0px 4px;
  }
  .ant-input-number-affix-wrapper {
    height: 40px;
    width: 184.25px;
  }
  .ant-input-number-input {
    height: 40px;
  }
`;

const DemographicStep = ({
  changeStep = noop,
  closePopover = noop,
  setDiscardChangesPopupVisibility = noop,
  updatePatientData = noop
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const mrnInputRef = useRef(null);

  const [isFetchingNewPatient, setIsFetchingNewPatient] = useState(false);
  const [isMrnValidationDone, setIsMrnValidationDone] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [isMrnValid, setIsMrnValid] = useState({ isValid: true });
  let [customerType] = useGetSetting(["fe-customer-type"]);
  customerType = customerType.toLowerCase();

  useEffect(() => {
    if (customerType !== customerTypes.RETAIL) {
      mrnInputRef.current.focus({
        cursor: "end"
      });
    }
  }, []);

  useEffect(() => {
    setIsSubmitActive(isFormValid());
  }, [isMrnValidationDone]);

  const isFormValid = () => {
    const formValues = form.getFieldsValue();
    const formErrors = form.getFieldsError();

    if (!isMrnValidationDone) return false;

    if (
      formValues.firstName === undefined ||
      formValues.lastName === undefined ||
      formValues.mrn === undefined ||
      formValues.dob === undefined
    ) {
      return false;
    }

    return formErrors.every((field) => {
      return field.errors.length == 0;
    });
  };

  const debouncedMrnValidation = useCallback(
    debounce((value) => {
      if (!value) return;
      if (customerType === customerTypes.RETAIL && value.toString().length < 10) return;

      validateMrnUniqueness(value)
        .then(() => {
          setIsMrnValid({ isValid: true });
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            const conflictingPatientId = _.get(err.response.data.errors[0], "patientId");
            const conflictingPatientJourneyId = _.get(err.response.data.errors[0], "journeyId");
            setIsMrnValid({ isValid: false, conflictingPatientId, conflictingPatientJourneyId });
          }
        })
        .finally(() => {
          setIsMrnValidationDone(true);
          form.validateFields(["mrn"]);
        });
    }, 500),
    []
  );

  const showErrorModal = (errorMessage) => {
    confirm({
      centered: true,
      className: "alert",
      okText: t("ok"),
      cancelText: t("cancel"),
      icon: <AlertIcon />,
      title: t("errorMessages.patient_creation_failure"),
      content: `${errorMessage}. ${t("errorMessages.please_contact_support")}`,
      footer: null,
      zIndex: 1051,
      maskClosable: true
    });
  };

  const handleSubmit = async () => {
    try {
      setIsFetchingNewPatient(true);

      const formValues = form.getFieldsValue(true);
      const dob = dateFormatter(formValues.dob);
      if (customerType === customerTypes.RETAIL) {
        formValues.phoneNumber = formValues.mrn;
      }
      const newPatientResult = await addNewPatient(flattenObject({ ...formValues, dob }));

      if (newPatientResult?.data.success) {
        const patientData = newPatientResult.data.patient;

        updatePatientData({
          ...formValues,
          dob,
          id: patientData.id,
          journeyId: patientData.journeyId
        });
        return changeStep(CREATE_NEW_PATIENT_STEPS.whatNext);
      } else {
        return showErrorModal(t("errorMessages.failed_to_create_patient"));
      }
    } catch (err) {
      const isConflictIssue = err?.response?.status === CONFLICT_STATUS_CODE;
      showErrorModal(
        (isConflictIssue && err?.response?.data?.errors && err?.response?.data?.errors[0]?.message) ||
          t("errorMessages.failed_to_create_patient")
      );
      setIsFetchingNewPatient(false);
    }
  };

  return (
    <div>
      <StyledNewPatientHeader>
        <Text>{t("new_patient.title")}</Text>
        <CloseOutlined
          id="close_create_new_patient_popover"
          onClick={() => (form.isFieldsTouched() ? setDiscardChangesPopupVisibility(true) : closePopover())}
        />
      </StyledNewPatientHeader>
      <Form
        onFinish={handleSubmit}
        form={form}
        labelAlign="left"
        width={"100%"}
        style={{ padding: "16px 24px 24px 24px", overflow: "hidden" }}
        onFieldsChange={() => {
          setIsSubmitActive(isFormValid());
        }}
      >
        <Space direction="vertical">
          <Text>
            {customerType === customerTypes.RETAIL
              ? t("new_patient.form_fields.phone_number")
              : t("new_patient.form_fields.mrn")}
          </Text>
          <Form.Item
            name={"mrn"}
            hasFeedback={true}
            validateStatus={!isMrnValidationDone && form.getFieldValue("mrn") ? "validating" : ""}
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject(new Error(t("new_patient.input_required")));

                  if (customerType === customerTypes.RETAIL) {
                    if (!phoneNumberLengthRegEx.test(value)) {
                      setIsMrnValid({ isValid: false });
                      return Promise.reject(new Error(t("managePatientDetails.please_enter_digits")));
                    }
                  } else {
                    if (!/^[A-Za-z0-9][A-Za-z0-9]*$/.test(displayedMRN(value))) {
                      setIsMrnValid({ isValid: false });
                      return Promise.reject(new Error(t("new_patient.mrn_only_english")));
                    }
                  }

                  if (!/\d/.test(displayedMRN(value))) {
                    setIsMrnValid({ isValid: false });
                    return Promise.reject(new Error(t("new_patient.mrn_must_contain_numbers")));
                  }

                  if (!isMrnValid.isValid) {
                    return Promise.reject(
                      <div>
                        {t("new_patient.patient_already_exist_error")}
                        {isMrnValid.conflictingPatientId && isMrnValid.conflictingPatientJourneyId && (
                          <a
                            id="view_existing_patient_link"
                            onClick={() => {
                              history.push(
                                `/patient/${isMrnValid.conflictingPatientId}/journey/${isMrnValid.conflictingPatientJourneyId}/optimizations`
                              );
                              closePopover();
                            }}
                          >
                            {t("new_patient.view_existing_patient_link")}
                          </a>
                        )}
                      </div>
                    );
                  }
                  return Promise.resolve();
                }
              }
            ]}
          >
            {customerType === customerTypes.RETAIL ? (
              <CustomInputNumber
                autoFocus={true}
                id="patientPhoneIdentifier"
                controls={false}
                placeholder={t("managePatientDetails.usPhonePattern")}
                maxLength={14}
                formatter={(value) => formatPhoneNumberWhileTyping(value)}
                parser={(value) => value.replace(/[^0-9]/g, "")}
                onChange={(value) => {
                  setIsMrnValidationDone(false);
                  setIsMrnValid({ isValid: true });
                  debouncedMrnValidation(value);
                }}
                prefix={
                  form.getFieldValue("mrn") && isMrnValidationDone ? (
                    isMrnValid.isValid ? (
                      <InputValidIcon />
                    ) : (
                      <InputInvalidIcon />
                    )
                  ) : (
                    <></>
                  )
                }
              />
            ) : (
              <CustomInput
                id="patientIdentifier"
                placeholder={t("new_patient.place_holders.mrn")}
                ref={mrnInputRef}
                onChange={(e) => {
                  setIsMrnValidationDone(false);
                  setIsMrnValid({ isValid: true });
                  debouncedMrnValidation(e.target.value);
                }}
                suffix={
                  form.getFieldValue("mrn") && isMrnValidationDone ? (
                    isMrnValid.isValid ? (
                      <InputValidIcon />
                    ) : (
                      <InputInvalidIcon />
                    )
                  ) : (
                    <></>
                  )
                }
              />
            )}
          </Form.Item>
        </Space>

        <Space direction="horizontal" style={{ width: "100%" }} size={16}>
          <Space direction="vertical">
            <Text>{t("new_patient.form_fields.first_name")}</Text>
            <Form.Item name={"firstName"} rules={[{ required: true, message: t("new_patient.input_required") }]}>
              <CustomInput maxLength={25} placeholder={t("new_patient.place_holders.first_name")} />
            </Form.Item>
          </Space>
          <Space direction="vertical">
            <Text>{t("new_patient.form_fields.middle_name")}</Text>
            <Form.Item name={"middleName"}>
              <CustomInput maxLength={25} placeholder={t("new_patient.place_holders.middle_name")} />
            </Form.Item>
          </Space>
          <Space direction="vertical">
            <Text>{t("new_patient.form_fields.last_name")}</Text>
            <Form.Item name={"lastName"} rules={[{ required: true, message: t("new_patient.input_required") }]}>
              <CustomInput maxLength={25} placeholder={t("new_patient.place_holders.last_name")} />
            </Form.Item>
          </Space>
          <Space direction="vertical">
            <Text>{t("new_patient.form_fields.dob")}</Text>
            <Form.Item name={"dob"} rules={[{ required: true, message: t("new_patient.input_required") }]}>
              <CustomDatePicker
                style={{ width: 184.25, height: 40 }}
                size="default"
                defaultPickerValue={new moment().add(-50, "Y")}
                disabledDate={(d) => !d || d.isAfter(moment())}
                format={DATE_FORMAT}
                placeholder={DATE_FORMAT}
                label={t("new_patient.date_of_birth_placeholder")}
              />
            </Form.Item>
          </Space>
        </Space>
        <Divider style={{ margin: "4px 0px 24px 0px " }} />
        <div style={{ float: "right" }}>
          <PrimaryButton
            id="create_new_patient_form_submit_button"
            style={{ fontSize: 16, height: 44, width: isFetchingNewPatient ? 170 : 143 }}
            htmlType="submit"
            loading={isFetchingNewPatient}
            disabled={!isSubmitActive}
          >
            <Text style={{ color: WHITE }}>{t("new_patient.create_patient_button")}</Text>
          </PrimaryButton>
        </div>
      </Form>
    </div>
  );
};

export default DemographicStep;
