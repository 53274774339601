import { useSelectedApplication, useSelectedExpressEnrollTemplateName } from "../hooks";
import egalePemfexyConfiguration from "./Eagle/configurations";
import pfizerCopayIvConfigurations from "./Pfizer/copay/iv/configurations";
import { useGenentechFreeDrugConfiguration } from "./Genentech/freeDrug/useGenentechFreeDrugConfiguration";
import pfizerFreeDrugConfigurations from "./Pfizer/configurations";
import genentechCopayConfigurations from "./Genentech/copay/configurations";
import pfizerCopayOralConfigurations from "./Pfizer/copay/oral/configurations";
import { useNovartisCopayConfiguration } from "./Novartis/copay/useNovartisCopayConfiguration";
import { usePfizerCopayOralIQVIAConfiguration } from "./Pfizer/copay/oral/iqvia/usePfizerCopayOralConfiguration";

export const vendorNames = Object.freeze({
  PFIZER_FREE_DRUG: "pfizer-oncologytogether",
  GENENTECH_COPAY: "genentech-Oncology",
  GENENTECH_FREE_DRUG: "genentech-free-drug",
  PFIZER_COPAY_ORAL: "pfizer-copay-oral",
  PFIZER_COPAY_ORAL_IQVIA: "pfizer-copay-oral-iqvia",
  PFIZER_COPAY_IV: "pfizer-copay-iv",
  EAGLE_PEMFEXY: "eagle-pemfexy",
  NOVARTIS_COPAY: "novartis-copay"
});

export const ExpressEnrollMapper = Object.freeze({
  [vendorNames.PFIZER_FREE_DRUG]: pfizerFreeDrugConfigurations,
  [vendorNames.GENENTECH_COPAY]: genentechCopayConfigurations,
  [vendorNames.PFIZER_COPAY_ORAL]: pfizerCopayOralConfigurations,
  [vendorNames.PFIZER_COPAY_IV]: pfizerCopayIvConfigurations,
  [vendorNames.EAGLE_PEMFEXY]: egalePemfexyConfiguration,
  [vendorNames.GENENTECH_FREE_DRUG]: () => useGenentechFreeDrugConfiguration(),
  [vendorNames.NOVARTIS_COPAY]: () => useNovartisCopayConfiguration(),
  [vendorNames.PFIZER_COPAY_ORAL_IQVIA]: () => usePfizerCopayOralIQVIAConfiguration()
});

export const getExpressEnrollmentMapping = () => {
  const expressEnrollTemplateName = useSelectedExpressEnrollTemplateName();
  const application = useSelectedApplication();

  const expressEnrollMapping = application?.potentialSaving?.hasExpressEnroll
    ? ExpressEnrollMapper[expressEnrollTemplateName]
    : null;

  const configuration = expressEnrollMapping instanceof Function ? expressEnrollMapping() : expressEnrollMapping;
  return configuration || {};
};
