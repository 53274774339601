const patientFields = {
  id: ["patient", "id"],
  firstName: ["patient", "firstName"],
  middleName: ["patient", "middleName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  phoneType: ["patient", "phoneType"],
  phone: ["patient", "phone"],
  email: ["patient", "email"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  primaryLanguage: ["patient", "primaryLanguage"],
  contactTime: ["patient", "contactTime"],
  caregiverName: ["patient", "caregiver", "name"],
  caregiverRelationship: ["patient", "caregiver", "relationship"],
  caregiverPhone: ["patient", "caregiver", "phone"],
  caregiverPhoneType: ["patient", "caregiver", "phoneType"],
  reEnrollment: ["patient", "reEnrollment"]
};

const prescriptionFields = {
  dosingInstructions: ["prescription", "dosingInstructions"],
  quantity: ["prescription", "quantity"],
  numberOfRefills: ["prescription", "numberOfRefills"],
  concomitant: ["prescription", "concomitant"],
  allergies: ["prescription", "allergies"],
  allergiesDetails: ["prescription", "allergiesDetails"],
  frequency: ["prescription", "frequency"],
  numberOfVials: ["prescription", "numberOfVials"],
  treatmentStartDate: ["prescription", "treatmentStartDate"],
  drug: ["prescription", "drug"],
  name: ["prescription", "drug", "name"],
  ndc: ["prescription", "drug", "ndc"],
  ttyCui: ["prescription", "drug", "ttyCui"],
  category: ["prescription", "drug", "category"],
  dosage: ["prescription", "dosage"],
  vialStrength: ["prescription", "vialStrength"],
  vialSize: ["prescription", "vialSize"],
  preferredSpecialtyPharmacyName: ["prescription", "preferredSpecialtyPharmacyName"],
  preferredSpecialtyPharmacyAddress: ["prescription", "preferredSpecialtyPharmacyAddress"],
  selfDispensingPharmacy: ["prescription", "selfDispensingPharmacy"]
};

const financialFields = {
  householdSize: ["financial", "householdSize"],
  householdIncome: ["financial", "householdIncome"]
};

const diagnosisFields = {
  primary: ["diagnosis", "primary", "icd10"],
  secondary: ["diagnosis", "secondary", "icd10"],
  talzennaPositiveHrrTest: ["diagnosis", "talzennaPositiveHrrTest"]
};

const insuranceFields = {
  medicalType: ["insurance", "medical", "type"],
  medicalName: ["insurance", "medical", "payerName"],
  medicalPhone: ["insurance", "medical", "phone"],
  medicalGroup: ["insurance", "medical", "groupId"],
  medicalMember: ["insurance", "medical", "memberId"],
  medicalPolicyholderName: ["insurance", "medical", "policyholderName"],
  medicalPolicyholderSameAsPatient: ["insurance", "medical", "policyholderSameAsPatient"],
  medicalOtherTypeName: ["insurance", "medical", "otherTypeName"],
  primaryPrescriptionType: ["insurance", "primaryPrescription", "type"],
  primaryPrescriptionPolicyholderSameAsPatient: ["insurance", "primaryPrescription", "policyholderSameAsPatient"],
  primaryPrescriptionPolicyholderName: ["insurance", "primaryPrescription", "policyholderName"],
  primaryPrescriptionPayerName: ["insurance", "primaryPrescription", "payerName"],
  primaryPrescriptionBIN: ["insurance", "primaryPrescription", "BIN"],
  primaryPrescriptionPCN: ["insurance", "primaryPrescription", "PCN"],
  primaryPrescriptionPhoneNumber: ["insurance", "primaryPrescription", "phone"],
  primaryPrescriptionGroupId: ["insurance", "primaryPrescription", "groupId"],
  primaryPrescriptionMemberId: ["insurance", "primaryPrescription", "memberId"],
  primaryPrescriptionOtherTypeName: ["insurance", "primaryPrescription", "otherTypeName"],
  secondaryPrescriptionType: ["insurance", "secondaryPrescription", "type"],
  secondaryPrescriptionName: ["insurance", "secondaryPrescription", "payerName"],
  secondaryPrescriptionPhone: ["insurance", "secondaryPrescription", "phone"],
  secondaryPrescriptionGroup: ["insurance", "secondaryPrescription", "groupId"],
  secondaryPrescriptionMember: ["insurance", "secondaryPrescription", "memberId"],
  secondaryPrescriptionBIN: ["insurance", "secondaryPrescription", "BIN"],
  secondaryPrescriptionPCN: ["insurance", "secondaryPrescription", "PCN"],
  secondaryPrescriptionPolicyholderName: ["insurance", "secondaryPrescription", "policyholderName"],
  secondaryPrescriptionPolicyholderSameAsPatient: ["insurance", "secondaryPrescription", "policyholderSameAsPatient"],
  secondaryPrescriptionOtherTypeName: ["insurance", "secondaryPrescription", "otherTypeName"],
  medicarePartD: ["insurance", "medicarePartD"],
  medicareIdNumber: ["insurance", "medicareIdNumber"],
  partDAddress: ["insurance", "partDAddress"],
  partDCity: ["insurance", "partDCity"],
  partDState: ["insurance", "partDState"],
  partDZip: ["insurance", "partDZip"],
  coveredByMedicalOrPrescription: ["insurance", "medicalOrPrescriptionCovered"],
  unaffordableCopay: ["insurance", "unaffordableCopay"],
  insurerRequiredCopayment: ["insurance", "insurerRequiredCopayment"],
  outOfPocketForPrescriptions: ["insurance", "outOfPocketForPrescriptions"],
  amountMetTowardsOopMax: ["insurance", "amountMetTowardsOopMax"],
  dateInformationObtainedFromPayerOrSpp: ["insurance", "dateInformationObtainedFromPayerOrSpp"],
  payerRequirePriorAuthorization: ["insurance", "payerRequirePriorAuthorization"],
  priorAuthorizationNumber: ["insurance", "priorAuthorizationNumber"],
  priorAuthorizationDates: ["insurance", "priorAuthorizationDates"],
  docusignTemplateMapperKey: ["insurance", "docusignTemplateMapperKey"]
};

const hcpFields = {
  shippingAddress: ["shipping", "address"],
  shippingCity: ["shipping", "city"],
  shippingState: ["shipping", "state"],
  shippingZip: ["shipping", "zip"],
  shippingName: ["shipping", "name"],
  providerId: ["provider", "id"],
  providerFirstName: ["provider", "firstName"],
  providerLastName: ["provider", "lastName"],
  payerSpecificNumber: ["provider", "payerSpecificNumber"],
  providerNpi: ["provider", "npi"],
  providerStateLicense: ["provider", "stateLicense"],
  facilityId: ["facility", "id"],
  facilityName: ["facility", "name"],
  facilityAddress: ["facility", "address"],
  facilityCity: ["facility", "city"],
  facilityState: ["facility", "state"],
  facilityZip: ["facility", "zip"],
  facilityContactFullName: ["facility", "contact", "fullName"],
  facilityContactPhone: ["facility", "contact", "phone"],
  facilityContactFax: ["facility", "contact", "fax"],
  facilityContactEmail: ["facility", "contact", "email"],
  shippingType: ["shipping", "type"]
};
export { patientFields, prescriptionFields, financialFields, diagnosisFields, insuranceFields, hcpFields };
