import React from "react";
import { Form } from "antd";

import { InputPhone, InputPhoneWithPhoneTypeAddon } from "../InputPhone";
import { phoneNumberLengthRegEx } from "../../../../../utils/form/validation";

export const PhoneNumberFormItem = ({
  name,
  label,
  required,
  rules = [],
  placeholder,
  typeName,
  style = { display: "flex" },
  addon = true
}) => (
  <Form.Item
    rules={[{ required, message: "" }, { pattern: phoneNumberLengthRegEx, message: "" }, ...rules]}
    name={name}
    label={label}
  >
    {addon ? (
      <InputPhoneWithPhoneTypeAddon placeholder={placeholder} typeName={typeName} style={style} />
    ) : (
      <InputPhone placeholder={placeholder} style={style} />
    )}
  </Form.Item>
);
