import React from "react";
import { Form, InputNumber, Select } from "antd";
import { HomeOutlined, MobileOutlined } from "@ant-design/icons";

import { formatPhoneNumberWhileTyping } from "../../../../utils/formaters";
import { getPurePhoneNumber } from "../../../../utils/misc";

const PhoneTypeAddon = ({ typeName }) => (
  <Form.Item noStyle name={typeName}>
    <Select>
      <Select.Option value="home">
        <HomeOutlined />
      </Select.Option>
      <Select.Option value="mobile">
        <MobileOutlined />
      </Select.Option>
    </Select>
  </Form.Item>
);

const InputPhone = ({ ...props }) => (
  <InputNumber
    step={null}
    {...props}
    controls={false}
    maxLength={14}
    formatter={formatPhoneNumberWhileTyping}
    parser={getPurePhoneNumber}
  />
);

const InputPhoneWithPhoneTypeAddon = ({ typeName, ...props }) => (
  <InputPhone addonBefore={typeName && <PhoneTypeAddon typeName={typeName} />} {...props} />
);

export { InputPhone, InputPhoneWithPhoneTypeAddon };
