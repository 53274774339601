import React, { useRef } from "react";
import _ from "lodash";
import { Button, Tooltip, Upload } from "antd";
import { ExportOutlined, SaveOutlined, UploadOutlined } from "@ant-design/icons";

import { JsonEditor } from "./JsonEditor";
import { BOOLEAN_OBJECT, FIELD_DATA_STRUCTURE } from "./sharedData";

const defaultColSize = 12;
const datepickerDefaultColSize = 8;

const expectedHeaders = Object.values(FIELD_DATA_STRUCTURE);
const trulyArray = [true, "true", "TRUE"];

export const CsvToJson = ({ setJson, json }) => {
  const jsonEditorRef = useRef(null);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const result = parseCSV(text);

      if (result) {
        setJson(result);
      }
    };
    reader.readAsText(file);
    return false;
  };

  const parseCSV = (text) => {
    const lines = text.split("\n");
    const headers = lines[0].split(",").map((header) => header.trim());

    if (!_.isEqual(headers, expectedHeaders)) {
      alert("CSV headers do not match the expected format.");
      return null;
    }

    const rows = lines.slice(1).map((line) => {
      const values = line.split(",").map((value) => value.trim());
      const processedRow = {};

      expectedHeaders.forEach((header, index) => {
        let value = values[index];
        if (header === "section" && !value) {
          return;
        }
        if (!value) {
          value = header === "required" ? BOOLEAN_OBJECT.false : null;
        } else {
          switch (header) {
            case "options":
              // For "options" header, split the string by ";" and trim each part to create an array

              value = value.split(";").map((item) => item.trim());
              break;
            case "required":
              value = trulyArray.includes(value) ? BOOLEAN_OBJECT.true : BOOLEAN_OBJECT.false;
              break;
          }
        }
        // if (!value) {
        //   value = header === "required" ? "false" : null;
        // } else if (header === "options") {
        //   // For "options" header, split the string by ";" and trim each part to create an array
        //   value = value.split(";").map((item) => item.trim());
        // } else if (header === "required") {
        //   value = trulyArray.includes(value) ? "true" : "false";
        // }
        processedRow[header] = value;
      });

      processedRow["col_size"] = processedRow["type"] !== "datepicker" ? defaultColSize : datepickerDefaultColSize;
      return processedRow;
    });

    return rows;
  };

  const handleSvgExport = () => {
    // TODO: Implement this
  };

  const handleJSONExport = () => {
    const jsonStr = JSON.stringify(json, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "data.json";
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  const handleSave = () => {
    if (jsonEditorRef.current) {
      jsonEditorRef.current.handleSave();
    }
  };

  return (
    <div>
      {/* TODO: add option to export SVG file */}
      <Tooltip placement="top" title={"Will implements soon"}>
        <Button type="primary" onClick={handleSvgExport} icon={<ExportOutlined />} style={{ margin: 5 }} disabled>
          SVG
        </Button>
      </Tooltip>
      <Button type="primary" onClick={handleJSONExport} icon={<ExportOutlined />} style={{ margin: 5 }}>
        JSON
      </Button>
      <Button type="primary" onClick={handleSave} icon={<SaveOutlined />} style={{ margin: 5 }}>
        Save
      </Button>
      <Upload accept=".csv" beforeUpload={handleFileUpload} showUploadList={false}>
        <Button icon={<UploadOutlined />} style={{ margin: 5 }}>
          Upload CSV
        </Button>
      </Upload>
      <JsonEditor ref={jsonEditorRef} json={json} onChange={setJson} />
    </div>
  );
};
