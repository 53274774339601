import React, { useState } from "react";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { PreviewField } from "./PreviewField";

export const SectionFields = ({ fields, setJson }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [spanValues, setSpanValues] = useState(
    Object.keys(fields || {}).reduce((field, key) => {
      field[key] = fields[key]["col_size"];
      return field;
    }, {})
  );

  const fullLineToggle = (key, field) => {
    const fieldLabel = field.label;
    setJson((prevJson) => {
      const thisFieldIndex = prevJson.findIndex((field) => field.label === fieldLabel);
      if (thisFieldIndex !== -1) {
        const updatedJson = [...prevJson];
        const currentFullLine = updatedJson[thisFieldIndex].full_line;
        updatedJson[thisFieldIndex] = {
          ...updatedJson[thisFieldIndex],
          full_line: currentFullLine === "true" ? "false" : "true"
        };
        return updatedJson;
      }
      return prevJson;
    });
  };

  const handleSpanChange = (key, field, increment) => {
    const fieldLabel = field.label;
    setSpanValues((prev) => {
      const newSpan = Math.min(Math.max(prev[key] + increment, 1), 24);
      setJson((prevJson) => {
        const thisFieldIndex = prevJson.findIndex((field) => field.label === fieldLabel);
        if (thisFieldIndex !== -1) {
          const updatedJson = [...prevJson];
          updatedJson[thisFieldIndex] = {
            ...updatedJson[thisFieldIndex],
            col_size: newSpan
          };
          return updatedJson;
        }
        return prevJson;
      });
      return { ...prev, [key]: newSpan };
    });
  };
  const handleFieldChange = (fieldName, value) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value
    }));
  };
  return (
    <Row gutter={[16, 16]}>
      {fields && Object.keys(fields).length > 0 ? (
        Object.keys(fields).map((key, index) => {
          const field = fields[key];
          if (!field) {
            return null;
          }
          const isFullLine = field.full_line === "true";
          const tooltipText = isFullLine
            ? t("online_wizard.unset_full_line_field_tooltip")
            : t("online_wizard.set_full_line_field_tooltip");
          return (
            <Col key={index} span={isFullLine ? 24 : spanValues[key]}>
              <div className={"fieldParentDiv"}>
                <PreviewField
                  value={formData[field.label]}
                  field={field}
                  handleFieldChange={handleFieldChange}
                  span={isFullLine ? spanValues[key] : 24}
                />
                <div className="hoverElement">
                  <Space direction="vertical" align="center" size="small">
                    <Button size="small" onClick={() => handleSpanChange(key, field, 1)}>
                      +
                    </Button>
                    <Tooltip placement="right" title={tooltipText}>
                      <Button size="small" onClick={() => fullLineToggle(key, field)} style={{ cursor: "default" }}>
                        {spanValues[key]}
                        {isFullLine && "*"}
                      </Button>
                    </Tooltip>
                    <Button size="small" onClick={() => handleSpanChange(key, field, -1)}>
                      -
                    </Button>
                  </Space>
                </div>
              </div>
            </Col>
          );
        })
      ) : (
        <p>No fields available</p>
      )}
    </Row>
  );
};
