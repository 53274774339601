import React, { useEffect } from "react";
import { Form } from "antd";

import SectionPart from "../../../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../../../ConfigurableRow";
import { patientFields } from "../../../../../../Pfizer/copay/oral/iqvia/form/fieldNames";
import { calculateAge } from "../../../../../../utils";

export default function PfizerCopayOralPatient() {
  const patientFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.PFIZER_COPAY_ORAL_IQVIA)()
    .patient;

  const form = Form.useFormInstance();
  const caregiver = Form.useWatch(patientFields.caregiver, form);
  const patientGender = Form.useWatch(patientFields.gender, form);
  const caregiverGender = Form.useWatch(patientFields.caregiverGender, form);
  const patientDob = Form.useWatch(patientFields.dob, form);
  const isBelow18 = calculateAge(patientDob) < 18;

  useEffect(() => {
    form.validateFields();
  }, [caregiver]);

  return (
    <SectionPart
      sectionId="patient"
      headerTranslationKey="application_form_editor.pfizer.copay.oral.patient.labels.header"
    >
      <>{patientFormComponents.id()}</>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
        {[patientFormComponents.firstName({ required: true }), patientFormComponents.lastName({ required: true })]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
        {[
          patientFormComponents.dob({ required: true, disabled: true }),
          patientFormComponents.gender({
            required: true,
            showError: !patientGender,
            showThirdOption: false
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_3_2_2_2}>
        {[
          patientFormComponents.address({ required: true }),
          patientFormComponents.city({ required: true }),
          patientFormComponents.state({ required: true }),
          patientFormComponents.zip_code({ required: true })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
        {[patientFormComponents.email({ required: true }), patientFormComponents.phone_number({ required: true })]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_BLANK}>
        {[
          patientFormComponents.caregiver({
            required: isBelow18,
            disabled: isBelow18
          })
        ]}
      </ConfigurableRow>
      {caregiver && (
        <>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
            {[
              patientFormComponents.caregiver_firstName({ required: true }),
              patientFormComponents.caregiver_lastName({ required: true })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
            {[
              patientFormComponents.caregiver_dob({ required: true }),
              patientFormComponents.caregiver_gender({
                required: true,
                showThirdOption: false,
                showError: !caregiverGender
              })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_3_2_2_2}>
            {[
              patientFormComponents.caregiver_address({ required: true }),
              patientFormComponents.caregiver_city({ required: true }),
              patientFormComponents.caregiver_state({ required: true }),
              patientFormComponents.caregiver_zip_code({ required: true })
            ]}
          </ConfigurableRow>
          <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_0}>
            {[
              patientFormComponents.caregiver_email({ required: true }),
              patientFormComponents.caregiver_phone_number({ required: true })
            ]}
          </ConfigurableRow>
        </>
      )}
    </SectionPart>
  );
}
