import React, { useEffect, useState } from "react";
import { Col, PageHeader, Row } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { OnlinePreview } from "./OnlinePreview";
import { CsvToJson } from "./CsvToJson";
import { SidebarIcon } from "../Patient/ApplicationFormEditor/components";

import "./OnlineWizard.css";
import { FIELD_DATA_STRUCTURE } from "./sharedData";

export const OnlineWizard = () => {
  const [sections, setSections] = useState({});
  const [json, setJson] = useState({});
  const [isJsonFromUrl, setIsJsonFromUrl] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const jsonParam = searchParams.get("json");

    if (jsonParam) {
      try {
        const parsedJson = JSON.parse(decodeURIComponent(jsonParam));
        setJson(parsedJson);
        setIsJsonFromUrl(true);
      } catch (error) {
        console.error("Invalid JSON in URL parameters", error);
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (json?.length) {
      const sectionSidebar = parseSections(json);
      setSections(sectionSidebar);
    }
  }, [json]);

  const parseSections = (json) => {
    const sectionsMap = {};
    json.map((item) => {
      const section = item[FIELD_DATA_STRUCTURE.SECTION];

      if (sectionsMap[section]) {
        sectionsMap[section].fields.push(item);
      } else if (section) {
        sectionsMap[section] = {
          href: `#${item[FIELD_DATA_STRUCTURE.SECTION]}`,
          title: item[FIELD_DATA_STRUCTURE.SECTION_HEADER],
          key: section,
          icon: SidebarIcon,
          fields: [item]
        };
      }
    });

    return sectionsMap;
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => history.push("/")}
        title={t("online_wizard.title")}
        subTitle={t("online_wizard.subtitle")}
      />

      <Row style={{ padding: "0px 5px" }}>
        {!isJsonFromUrl && (
          <Col span={8}>
            <CsvToJson setJson={setJson} json={json} />
          </Col>
        )}
        <Col span={16} id="exportableView">
          <OnlinePreview sections={sections} setJson={setJson} json={json} />
        </Col>
      </Row>
    </div>
  );
};
