/* eslint-disable no-unused-vars */
import React from "react";
import moment from "moment-timezone";

import { EEsignatureStatuses } from "../../../../../../constant/EEsignatureStatuses";
import { dateFormatter, DATE_FORMAT } from "../../../../../../utils/date";
import { SubmittedFormResultModal } from "../../../../PatientApplications/enrollTab/submission/SubmittedFormResultModal";
import { SaveButton } from "../../../header";
import getPfizerCopayIVSidebarItems from "./sidebarItems";
import { applyTrimFields } from "../../../utils/updateFormData";
import { PfizerCopayIvPatient } from "./form/formSections/patient";
import * as pfizerCopayIvFieldNames from "./form/fieldNames";
import { PfizerCopayIvPractice } from "./form/formSections/practice";
import { DynamicQuestionnaire } from "../../../components/DynamicQuestionnaire";
import { getPfizerCopayIvFormInitialValues } from "./form/initialValues";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../../constants";
import { CompleteFormButton } from "../../../CompleteFormButton";

const pfizerCopayIvConfigurations = {
  getSidebarItems: (t) => getPfizerCopayIVSidebarItems(t),
  activeFormSection: "#enrollment-form-copay-iv-patient-information-anchor",
  formId: "pfizer_copay_iv_smart_editor",
  resultId: "pfizer_copay_iv_enroll_result",
  fieldNames: pfizerCopayIvFieldNames,
  getFormInitialValues: getPfizerCopayIvFormInitialValues,
  getFormSectionComponents: (t, assistanceProgram) => (
    <>
      <PfizerCopayIvPatient />
      <PfizerCopayIvPractice />
      <DynamicQuestionnaire
        DividerText={"application_form_editor.pfizer.copay.iv.consent.header"}
        ineligibleAnswerError={t("application_form_editor.pfizer.copay.iv.consent.ineligible_answer_error", {
          phone_number: assistanceProgram?.phone2
        })}
        ineligibleCheckboxAnswerError={t(
          "application_form_editor.pfizer.copay.iv.consent.ineligible_checkbox_answer_error",
          { phone_number: assistanceProgram?.phone2 }
        )}
      />
    </>
  ),
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormResultModal onCancel={onCancel} visible={visible} />,
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_IQVIA_submit"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.SUBMIT_FORM,
  saveButton: (props) => <SaveButton onSave={(form) => applyTrimFields(form)} id={"EE_IQVIA_save_form"} {...props} />,
  shouldSaveAppear: () => true,
  getShowEnrollResult: (applicationForm) => applicationForm?.status === EEsignatureStatuses.SUBMITTED,
  prepareButtonId: "EE_IQVIA_prepare_application_form",
  resubmitButtonId: "EE_IQVIA_resubmit_application_form",
  viewDetailsButtonId: "EE_IQVIA_view_submission_details",
  applicationSupportsEEId: "application_supports_IQVIA_EE",
  getEnrollResultData: ({ application, applicationForm }) => ({
    group: application.group,
    member_id: application.memberId,
    request_id: application.externalRequestId,
    npi_associated_to_portal: applicationForm.formData.facility.npi,
    card_activation_date: {
      value: dateFormatter(application.approvedDate),
      style: { marginTop: "44.6px" }
    }
  }),
  getApprovedResultDescription: () => <></>,
  getErrorText: (t, error) => {
    const errorMap = {
      "Practice not registered": "patientApplications.enroll.enroll_result.practice_not_registered",
      "Patient is already enrolled": "patientApplications.enroll.enroll_result.patient_is_already_enrolled",
      "Patient has government insurance": "patientApplications.enroll.enroll_result.patient_has_government_insurance"
    };

    return errorMap[error] && t(errorMap[error]);
  },
  approvalDetailsButtonId: "EE_IQVIA_view_approval_details"
};

export default pfizerCopayIvConfigurations;
