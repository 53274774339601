import React from "react";
import { Anchor, Col, Space, Typography } from "antd";
import styled from "styled-components";

import { fontWeights } from "../../../constant/styles";
import { SidebarRow } from "./components";
import { GRAY_400, GRAY_700, PRIMARY_600 } from "../../../constant/colors";

const { Text } = Typography;

const SideBarText = styled(Text).withConfig({
  shouldForwardProp: (prop) => !["isActive", "isCompleted"].includes(prop)
})`
  font-weight: ${fontWeights.semibold};
  color: ${(props) => {
    if (props.isActive) return PRIMARY_600;
    return props.isCompleted ? GRAY_400 : GRAY_700;
  }};
`;

export default function SidebarItem({ href, title, isActive, isCompleted, icon: Icon }) {
  return (
    <Col span={24}>
      <SidebarRow isActive={isActive} wrap={false} align="middle">
        <Anchor.Link
          href={href}
          title={
            <Space>
              <Icon isCompleted={isCompleted} />
              <SideBarText isCompleted={isCompleted} isActive={isActive}>
                {title}
              </SideBarText>
            </Space>
          }
        />
      </SidebarRow>
    </Col>
  );
}
