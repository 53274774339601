import React from "react";
import { Divider, Space } from "antd";

import { FormDividerText } from "../Patient/ApplicationFormEditor/components";
import { GRAY_500 } from "../../constant/colors";
import { SectionFields } from "./SectionFields";

export const HeaderSection = ({ sections, setJson }) => {
  return (
    <div>
      <div id="enrollment-form-patient-information">
        {sections && Object.keys(sections).length > 0 ? (
          Object.keys(sections).map((key, index) => {
            const section = sections[key];
            return (
              <Space
                key={index}
                id={section.key}
                direction="vertical"
                size={0}
                style={{ width: "100%", padding: "24px 16px" }}
              >
                <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
                  <FormDividerText>{section.title}</FormDividerText>
                </Divider>
                <SectionFields fields={section.fields} setJson={setJson} />
              </Space>
            );
          })
        ) : (
          <p>No sections available</p>
        )}
      </div>
    </div>
  );
};
