import React from "react";
import { Col, Input, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";

import { GRAY_900 } from "../../../../../../../constant/colors";
import { fontWeights, sizes } from "../../../../../../../constant/styles";
import { prescriptionFields } from "../../fieldNames";
import { FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { PatientInformation } from "./PatientInformation";

export default function OralForm() {
  const { t } = useTranslation();
  return (
    <>
      <Row gutter={14} wrap={false}>
        <Col span={12}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.dosingInstructions}
            label={t("application_form_editor.pfizer.prescription.labels.dosing_instructions")}
          >
            <Input placeholder={t("application_form_editor.pfizer.prescription.placeholders.dosing_instructions")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.prescription.labels.quantity")}
            name={prescriptionFields.quantity}
          >
            <InputNumber
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.quantity")}
              controls={false}
              addonAfter={
                <div style={{ color: GRAY_900, fontWeight: fontWeights.regular, fontSize: sizes.medium_large }}>
                  {t("application_form_editor.pfizer.prescription.labels.day_supply")}
                </div>
              }
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            style={{ width: "100%" }}
            rules={[{ required: true }]}
            name={prescriptionFields.numberOfRefills}
            label={t("application_form_editor.pfizer.prescription.labels.refills")}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.prescription.placeholders.refills")}
            />
          </FormItem>
        </Col>
      </Row>
      <PatientInformation />
    </>
  );
}
