import React, { useEffect } from "react";
import { Col, Divider, Form, Input, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import { GRAY_500 } from "../../../../../../../constant/colors";
import { InsuranceDetails } from "./InsuranceDetails";
import { insuranceFields, patientFields } from "../../fieldNames";
import { FormDividerText, FormItem } from "../../../../components";
import { tristate } from "../../../../../../../constant/tristate";
import { PrescriptionInsuranceDetails } from "./PrescriptionInsuranceDetails";
import { MedicarePartDDetails } from "./MedicarePartDDetails";

import { PLAN_MARKETS } from "../../../../../../../constant/planMarkets";

export const PRIMARY_INSURANCE = "primary";
export const SECONDARY_INSURANCE = "secondary";

const DOCUSIGN_MAPPER = {
  PATIENT_SIGNING_NO_MEDICARE_ADVANTAGE_OR_PART_D: "PatientSigningNoMedicareAdvantageOrPartD",
  PATIENT_SIGNING_HAS_MEDICARE_ADVANTAGE_OR_PART_D: "PatientSigningHasMedicareAdvantageOrPartD",
  CAREGIVER_SIGNING_NO_MEDICARE_ADVANTAGE_OR_PART_D: "CaregiverSigningNoMedicareAdvantageOrPartD",
  CAREGIVER_SIGNING_HAS_MEDICARE_ADVANTAGE_OR_PART_D: "CaregiverSigningHasMedicareAdvantageOrPartD"
};

export default function PfizerFreeDrugInsurance() {
  const { t } = useTranslation();

  const form = Form.useFormInstance();

  const medicarePartD = Form.useWatch(insuranceFields.medicarePartD, form);
  const payerRequirePriorAuthorization = Form.useWatch(insuranceFields.payerRequirePriorAuthorization, form);
  const coveredByMedicalOrPrescription = Form.useWatch(insuranceFields.coveredByMedicalOrPrescription, form);

  const primaryPrescriptionType = Form.useWatch(insuranceFields.primaryPrescriptionType);
  const secondaryPrescriptionType = Form.useWatch(insuranceFields.secondaryPrescriptionType);
  const medicalType = Form.useWatch(insuranceFields.medicalType);
  const docusignTemplateMapperKey = Form.useWatch(insuranceFields.docusignTemplateMapperKey);
  const patientInsurances = [primaryPrescriptionType, secondaryPrescriptionType, medicalType];
  const patientHasMedicarePartD = patientInsurances.includes(PLAN_MARKETS.MedicarePartD);
  const unaffordableCopay = Form.useWatch(insuranceFields.unaffordableCopay);
  const caregiverName = Form.useWatch(patientFields.caregiverName, form);

  useEffect(() => {
    const patientHasMedicareAdvantage = patientInsurances.includes(PLAN_MARKETS.MedicareAdvantage);
    let templateMapperKey;

    const patientHasMedicareAdvantageOrPartD = patientHasMedicareAdvantage || patientHasMedicarePartD;

    if (patientHasMedicareAdvantageOrPartD) {
      if (caregiverName) {
        templateMapperKey = DOCUSIGN_MAPPER.CAREGIVER_SIGNING_HAS_MEDICARE_ADVANTAGE_OR_PART_D;
      } else {
        templateMapperKey = DOCUSIGN_MAPPER.PATIENT_SIGNING_HAS_MEDICARE_ADVANTAGE_OR_PART_D;
      }
    } else {
      if (caregiverName) {
        templateMapperKey = DOCUSIGN_MAPPER.CAREGIVER_SIGNING_NO_MEDICARE_ADVANTAGE_OR_PART_D;
      } else {
        templateMapperKey = DOCUSIGN_MAPPER.PATIENT_SIGNING_NO_MEDICARE_ADVANTAGE_OR_PART_D;
      }
    }
    form.setFieldValue(insuranceFields.docusignTemplateMapperKey, templateMapperKey);
  }, [primaryPrescriptionType, secondaryPrescriptionType, medicalType, caregiverName]);

  useEffect(() => {
    form.validateFields();
  }, [
    medicarePartD,
    unaffordableCopay,
    coveredByMedicalOrPrescription,
    payerRequirePriorAuthorization,
    docusignTemplateMapperKey
  ]);

  return (
    <>
      <Divider
        id="enrollment-form-insurance-information"
        orientation="left"
        orientationMargin="0"
        style={{ color: GRAY_500 }}
      >
        <FormDividerText>{t("application_form_editor.pfizer.insurance.labels.header")}</FormDividerText>
      </Divider>
      <Row gutter={14}>
        <Col span={16}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.coveredByMedicalOrPrescription}
            label={t("application_form_editor.pfizer.insurance.labels.covered_by_insurance")}
          >
            <Radio.Group>
              <Radio value={tristate.YES}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={tristate.NO}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            name={insuranceFields.unaffordableCopay}
            label={t("application_form_editor.pfizer.insurance.labels.unaffordable_copay")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      <InsuranceDetails />
      <PrescriptionInsuranceDetails type={PRIMARY_INSURANCE} />
      <PrescriptionInsuranceDetails type={SECONDARY_INSURANCE} />

      {/*
       this form.item value is used to help determine which Docusign template to use
     because we Docusign doesn't let you choose dynamically wether to add a signature or not so we have to prepare a few in advance
     */}
      <FormItem rules={[{ required: true }]} name={insuranceFields.docusignTemplateMapperKey} hidden={true} />
      {patientHasMedicarePartD && <MedicarePartDDetails />}
      <Row gutter={14}>
        <Col span={15}>
          <FormItem
            name={insuranceFields.insurerRequiredCopayment}
            rules={[{ required: unaffordableCopay === true }]}
            label={t("application_form_editor.pfizer.insurance.labels.insurer_required_copayment")}
          >
            <Input
              maxLength={5}
              size="large"
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.insurer_required_copayment")}
            />
          </FormItem>
        </Col>
        <Col span={9}>
          <FormItem
            name={insuranceFields.outOfPocketForPrescriptions}
            rules={[{ required: unaffordableCopay === true }]}
            label={t("application_form_editor.pfizer.insurance.labels.out_of_pocket_for_prescriptions")}
          >
            <Input
              size="large"
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.out_of_pocket_for_prescriptions")}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={10}>
          <FormItem
            name={insuranceFields.amountMetTowardsOopMax}
            rules={[{ required: unaffordableCopay === true }]}
            label={t("application_form_editor.pfizer.insurance.labels.amount_met_towards_oop_max")}
          >
            <Input
              size="large"
              placeholder={t("application_form_editor.pfizer.insurance.placeholders.amount_met_towards_oop_max")}
            />
          </FormItem>
        </Col>
        <Col span={14}>
          <FormItem
            name={insuranceFields.dateInformationObtainedFromPayerOrSpp}
            rules={[{ required: unaffordableCopay === true }]}
            label={t("application_form_editor.pfizer.insurance.labels.date_information_obtained_from_payer_or_spp")}
          >
            <Input
              size="large"
              placeholder={t(
                "application_form_editor.pfizer.insurance.placeholders.date_information_obtained_from_payer_or_spp"
              )}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            label={t("application_form_editor.pfizer.insurance.labels.payer_require_prior_authorization")}
            name={insuranceFields.payerRequirePriorAuthorization}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.insurance.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.insurance.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      {payerRequirePriorAuthorization && (
        <Row gutter={14}>
          <Col span={8}>
            <FormItem
              name={insuranceFields.priorAuthorizationNumber}
              rules={[{ required: true }]}
              label={t("application_form_editor.pfizer.insurance.labels.prior_authorization_number")}
            >
              <Input
                size="large"
                placeholder={t("application_form_editor.pfizer.insurance.placeholders.prior_authorization_number")}
              />
            </FormItem>
          </Col>
          <Col span={16}>
            <FormItem
              name={insuranceFields.priorAuthorizationDates}
              rules={[{ required: true }]}
              label={t("application_form_editor.pfizer.insurance.labels.prior_authorization_dates")}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
      )}
    </>
  );
}
