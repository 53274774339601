import React, { useEffect } from "react";
import { Form } from "antd";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { useSelectedApplication } from "../../../../../hooks";
import { EVRYSDI } from "../../../../constants";
import { patientConsentFields } from "../fieldNames";
import { patientFields } from "../../../../components/common/fieldNames";
import { buildFieldValueMap } from "../../../../../../../utils/form";

const SELF_SIGNED = "self";

export default function GenentechFreeDrugPatientConsent() {
  const patientConsentFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)()
    .patientConsent;

  const application = useSelectedApplication();
  const isEvrysdiDrug = application.drugDisplayName === EVRYSDI;

  const form = Form.useFormInstance();

  const patientFirstName = Form.useWatch(patientFields.firstName, form);
  const patientLastName = Form.useWatch(patientFields.lastName, form);
  const patientEmail = Form.useWatch(patientFields.email, form);

  const personalInfoConsentSignerRelationship = Form.useWatch(
    patientConsentFields.personalInfoConsentSignerRelationship,
    form
  );

  const isSelfSigned = personalInfoConsentSignerRelationship === SELF_SIGNED;

  useEffect(() => {
    if (personalInfoConsentSignerRelationship === SELF_SIGNED) {
      const fieldsToUpdate = buildFieldValueMap([
        [patientConsentFields.personalInfoConsentSignerFirstName, patientFirstName],
        [patientConsentFields.personalInfoConsentSignerLastName, patientLastName],
        [patientConsentFields.personalInfoConsentSignerEmail, patientEmail]
      ]);

      form.setFieldsValue(fieldsToUpdate);
    }
  }, [patientFirstName, patientLastName, patientEmail, personalInfoConsentSignerRelationship, form]);

  const handleSignerRelationshipChange = (relationshipValue) => {
    const isSelf = relationshipValue === SELF_SIGNED;

    const fieldsToUpdate = buildFieldValueMap([
      [patientConsentFields.personalInfoConsentSignerFirstName, isSelf ? patientFirstName : ""],
      [patientConsentFields.personalInfoConsentSignerLastName, isSelf ? patientLastName : ""],
      [patientConsentFields.personalInfoConsentSignerEmail, isSelf ? patientEmail : ""]
    ]);

    form.setFieldsValue(fieldsToUpdate);
    form.validateFields();
  };
  return (
    <SectionPart
      sectionId="consent"
      headerTranslationKey="application_form_editor.genentech.free_drug.patient_consent.labels.patient_resources_consent_divider"
    >
      <ConfigurableRow>{[patientConsentFormComponents.patientResourcesConsentText({ isEvrysdiDrug })]}</ConfigurableRow>
      <ConfigurableRow>
        {[patientConsentFormComponents.patientResourcesConsent({ required: false, isEvrysdiDrug })]}
      </ConfigurableRow>

      {!isEvrysdiDrug && (
        <ConfigurableRow>{[patientConsentFormComponents.patientResourcesConsentTextBelow()]}</ConfigurableRow>
      )}

      {patientConsentFormComponents.authorizationRequiredDivider()}
      <ConfigurableRow>{[patientConsentFormComponents.authorizationRequiredText()]}</ConfigurableRow>
      <ConfigurableRow>{[patientConsentFormComponents.personalInfoConsent({ required: true })]}</ConfigurableRow>
      <ConfigurableRow>
        {[
          patientConsentFormComponents.personalInfoConsentSignerRelationship({
            required: true,
            onChange: handleSignerRelationshipChange
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_2}>
        {[
          patientConsentFormComponents.personalInfoConsentSignerFirstName({
            required: true,
            fieldProps: { disabled: isSelfSigned && patientFirstName }
          }),
          patientConsentFormComponents.personalInfoConsentSignerLastName({
            required: true,
            fieldProps: { disabled: isSelfSigned && patientLastName }
          }),
          patientConsentFormComponents.personalInfoConsentSignerEmail({
            required: false,
            fieldProps: { disabled: isSelfSigned }
          })
        ]}
      </ConfigurableRow>
    </SectionPart>
  );
}
