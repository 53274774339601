import {
  EmailItem,
  InputItem,
  PhoneNumberItem,
  PreferredLanguageItem,
  SelectItem,
  StateItem,
  CheckboxItem,
  RadioItem,
  Note,
  GenderItem
} from "../../form";
import { DateFormItem, DobFormItem } from "../../../components";

export const formFieldComponents = Object.freeze({
  input: InputItem,
  phone: PhoneNumberItem,
  email: EmailItem,
  singleSelect: SelectItem,
  datepicker: DateFormItem,
  dob: DobFormItem,
  gender: GenderItem,
  language: PreferredLanguageItem,
  state: StateItem,
  checkbox: CheckboxItem,
  radio: RadioItem,
  note: Note
});

export default formFieldComponents;
