import React from "react";
import { Select } from "antd";

import { SingleSelect } from "../../../../customComponent/CustomSelect";
import { States } from "../../../../../constant/states";
import { Form } from "antd";
const { Option } = Select;

export const StateItem = ({ name, label, required = false, rules = [], placeholder }) => {
  return (
    <Form.Item rules={[{ required }, ...rules]} name={name} label={label}>
      <SingleSelect useCommonStyle={false} bordered={true} placeholder={placeholder}>
        {Object.values(States).map((state, index) => (
          <Option key={index} value={state.code}>
            {state.name}
          </Option>
        ))}
      </SingleSelect>
    </Form.Item>
  );
};
