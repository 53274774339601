export const placeholderForFieldOptions = Object.freeze(["First Options", "Second Options", "Third Options"]);
export const BOOLEAN_OBJECT = Object.freeze({ true: "true", false: "false" });
export const FIELD_DATA_STRUCTURE = Object.freeze({
  FE_NAME: "FE name",
  BE_NAME: "BE name",
  FHIR_NAME: "FHIR name",
  LABEL: "label",
  TYPE: "type",
  OPTIONS: "options",
  REQUIRED: "required",
  PLACEHOLDER: "placeholder",
  INITIAL_VALUE: "initial value",
  DEFAULT_VALUE: "default value",
  CONDITION: "condition",
  SECTION: "section",
  SECTION_HEADER: "section header",
  SECTION_SIDEBAR: "section sidebar"
});
