import { Collapse, Select, Space, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { selectedViews } from "../../constant/table";
import { GREEN, LIGHT_BLUE, NEW_BLUE, GRAY5, WHITE } from "../../constant/colors";
import { MultiSelect } from "../customComponent/CustomSelect";
import { DateRangeFilter } from "../filter/DateRangeFilter";
import { ALL_TIMES } from "../../constant/filters";
import { useDispatch, useSelector } from "react-redux";
import { selectFilters, selectFiltersInputs, selectProvidersFacility, selectSelectedView } from "../../store/selector";
import ACTIONS from "../../store/action";
import { searchRxnormDrugsAPI } from "../../api/api";
import { useTranslation } from "react-i18next";
import { statuses } from "../../constant/claim";
import { MultiSelectDrugSearch } from "@tailormed/common-client/component/MultiSelectDrugSearch";
import { filterSort } from "../../utils/sort";
const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

export const ClaimFiltersPanel = ({ handleInputMultiSelect, handleInputDate, resetDatesToDefault, filtersVisible }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dates, multiples } = useSelector(selectFilters);
  const filters = useSelector(selectFilters);
  const filtersInputs = useSelector(selectFiltersInputs);
  const selectedView = useSelector(selectSelectedView);
  const facilities = useSelector(selectProvidersFacility);

  const setFiltersInputs = (filterName, value) => dispatch(ACTIONS.setFiltersInputs(filterName, value));
  const setFiltersAct = (filters) => dispatch(ACTIONS.setFiltersAction(filters));

  const [isClaimDrugNameSelectOpen, setIsClaimDrugNameSelectOpen] = useState(false);
  const [isClaimDrugNameSelectHover, setIsClaimDrugNameSelectHover] = useState(false);
  const [facilitySearchInput, setFacilitySearchInput] = useState("");
  const { claimDate, dateOfService, claimFollowupDate, claimSubmittedDate, claimReceivedDate } = dates;

  const isClaimsFiltersActive = () => {
    const claimStatus = filtersInputs.claimStatuses;
    const claimDate = dates.claimDate.value;
    const claimFollowupDate = dates.claimFollowupDate.value;
    const claimSubmittedDate = dates.claimSubmittedDate.value;
    const claimReceivedDate = dates.claimReceivedDate.value;
    const claimRxnormDrugs = filtersInputs.claimRxnormDrugs;
    const claimFacilities = filtersInputs.claimFacilities;
    const claimServiceDate = dates.dateOfService.value;

    if (
      claimStatus.length > 0 ||
      claimDate !== ALL_TIMES ||
      claimFollowupDate !== ALL_TIMES ||
      claimSubmittedDate !== ALL_TIMES ||
      claimReceivedDate !== ALL_TIMES ||
      claimServiceDate !== ALL_TIMES ||
      claimRxnormDrugs.length > 0 ||
      claimFacilities.length > 0
    ) {
      return true;
    }
  };

  const searchRxnormDrugs = async (searchTerm) => {
    const response = await searchRxnormDrugsAPI({ text: searchTerm });
    return response?.data?.rxnormDrugs || [];
  };

  const handleSelectedDrugsChange = (selectedDrugs) => {
    setFiltersInputs("claimRxnormDrugs", selectedDrugs);

    setFiltersAct({
      ...filters,
      multiples: { ...multiples, claimRxnormDrugs: selectedDrugs }
    });
  };

  const claimDrugNameTextToDisplay =
    (filtersInputs.claimRxnormDrugs &&
      filtersInputs.claimRxnormDrugs.length > 0 &&
      filtersInputs.claimRxnormDrugs.map(({ tmDisplayName }) => tmDisplayName).join(" | ")) ||
    "";

  return (
    selectedView === selectedViews.allClaims && (
      <Collapse ghost expandIconPosition="end" style={{ display: !filtersVisible ? "none" : undefined }}>
        <Panel
          header={
            <Title level={5} style={{ color: NEW_BLUE }}>
              {t("tableFilters.claims")}
              {isClaimsFiltersActive() && (
                <div
                  style={{
                    position: "absolute",
                    background: GREEN,
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    left: "247px",
                    top: "24px"
                  }}
                />
              )}
            </Title>
          }
        >
          <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_status")}
              </Text>
              <MultiSelect
                id="filter_claim_status"
                onChange={(values, options) => handleInputMultiSelect(values, options, "claimStatuses")}
                value={filtersInputs.claimStatuses}
                tooltipContent={Object.values(multiples.claimStatuses || {})}
                autoClearSearchValue={false}
                mode="multiple"
                maxTagCount={1}
                maxTagTextLength={filtersInputs.claimStatuses.length > 1 ? 8 : 16}
                allowClear={true}
                style={{ width: "100%" }}
                placeholder={t("all")}
                bordered={false}
              >
                {statuses.map((status) => {
                  return (
                    <Option key={status.value} value={status.value}>
                      {status.label}
                    </Option>
                  );
                })}
              </MultiSelect>
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_date")}
              </Text>
              <DateRangeFilter
                id="filter_claim_date"
                handleInput={(value = ALL_TIMES) => setFiltersInputs("claimDate", value)}
                onSelect={(fromDate, toDate, value = null) => handleInputDate(fromDate, toDate, "claimDate", value)}
                allowClear={true}
                onClear={() => {
                  resetDatesToDefault("claimDate", ALL_TIMES);
                }}
                defaultValue={ALL_TIMES}
                placeHolder={t(ALL_TIMES)}
                dateFilters="claimDateFilters"
                timeRange={claimDate}
              />
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_follow_up_date")}
              </Text>
              <DateRangeFilter
                id="filter_claim_follow_up_date"
                handleInput={(value = ALL_TIMES) => setFiltersInputs("claimFollowupDate", value)}
                onSelect={(fromDate, toDate, value = null) =>
                  handleInputDate(fromDate, toDate, "claimFollowupDate", value)
                }
                allowClear={true}
                onClear={() => {
                  resetDatesToDefault("claimFollowupDate", ALL_TIMES);
                }}
                defaultValue={ALL_TIMES}
                placeHolder={t(ALL_TIMES)}
                dateFilters="claimFollowupDateFilters"
                timeRange={claimFollowupDate}
              />
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_submitted_date")}
              </Text>
              <DateRangeFilter
                id="filter_claim_submitted_date"
                handleInput={(value = ALL_TIMES) => setFiltersInputs("claimSubmittedDate", value)}
                onSelect={(fromDate, toDate, value = null) =>
                  handleInputDate(fromDate, toDate, "claimSubmittedDate", value)
                }
                allowClear={true}
                onClear={() => {
                  resetDatesToDefault("claimSubmittedDate", ALL_TIMES);
                }}
                defaultValue={ALL_TIMES}
                placeHolder={t(ALL_TIMES)}
                dateFilters="claimSubmittedDateFilters"
                timeRange={claimSubmittedDate}
              />
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.date_of_service")}
              </Text>
              <DateRangeFilter
                id="filter_claim_date_of_Service"
                handleInput={(value = ALL_TIMES) => setFiltersInputs("dateOfService", value)}
                onSelect={(fromDate, toDate, value = null) => handleInputDate(fromDate, toDate, "dateOfService", value)}
                allowClear={true}
                onClear={() => {
                  resetDatesToDefault("dateOfService", ALL_TIMES);
                }}
                defaultValue={ALL_TIMES}
                placeHolder={t(ALL_TIMES)}
                dateFilters="dateOfServiceFilters"
                timeRange={dateOfService}
              />
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_received_date")}
              </Text>
              <DateRangeFilter
                id="filter_claim_received_date"
                handleInput={(value = ALL_TIMES) => setFiltersInputs("claimReceivedDate", value)}
                onSelect={(fromDate, toDate, value = null) =>
                  handleInputDate(fromDate, toDate, "claimReceivedDate", value)
                }
                allowClear={true}
                onClear={() => {
                  resetDatesToDefault("claimReceivedDate", ALL_TIMES);
                }}
                defaultValue={ALL_TIMES}
                placeHolder={t(ALL_TIMES)}
                dateFilters="claimReceivedDateFilters"
                timeRange={claimReceivedDate}
              />
            </Space>

            <Space size={0} style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_drug_name")}
              </Text>
              <Tooltip
                open={claimDrugNameTextToDisplay && !isClaimDrugNameSelectOpen && isClaimDrugNameSelectHover}
                title={<Text>{claimDrugNameTextToDisplay}</Text>}
                color={WHITE}
                placement="right"
                overlayStyle={{
                  maxWidth: "calc(100% - 600px)",
                  whiteSpace: "normal",
                  wordWrap: "break-word"
                }}
              >
                <MultiSelectDrugSearch
                  id="filter_claim_drug_name"
                  searchRxnormDrugs={searchRxnormDrugs}
                  mode="multiple"
                  placeholder={t("all")}
                  onChangeSelectedDrugs={(selectedDrugs) => handleSelectedDrugsChange(selectedDrugs)}
                  onClear={() => handleSelectedDrugsChange([])}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  value={filtersInputs.claimRxnormDrugs}
                  displayNameMaxChars={25}
                  bordered={false}
                  style={{
                    width: "100%",
                    border: `0px`,
                    borderBottom: `1px solid ${GRAY5}`,
                    padding: 0
                  }}
                  onDropdownVisibleChange={(open) => setIsClaimDrugNameSelectOpen(open)}
                  onMouseEnter={() => setIsClaimDrugNameSelectHover(true)}
                  onMouseLeave={() => setIsClaimDrugNameSelectHover(false)}
                />
              </Tooltip>
            </Space>

            <Space style={{ width: "100%" }} direction="vertical">
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.claim_location")}
              </Text>
              <MultiSelect
                id="filter_claim_facilities"
                dropdownMatchSelectWidth={false}
                onChange={(values, options) => handleInputMultiSelect(values, options, "claimFacilities")}
                value={filtersInputs.claimFacilities}
                tooltipContent={Object.values(multiples.claimFacilities || {})}
                autoClearSearchValue={false}
                showSearch
                mode="multiple"
                maxTagCount={1}
                maxTagTextLength={filtersInputs.claimFacilities?.length > 1 ? 8 : 16}
                allowClear
                style={{ width: "100%" }}
                placeholder={`${t("all")}`}
                filterOption={(input, option) => {
                  setFacilitySearchInput(input.toLowerCase());
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                filterSort={filterSort}
                bordered={false}
                clearAll={true}
                selectAll={true}
                onSelectAll={() => {
                  const filteredFacilities = facilities.filter((d) =>
                    d.name?.toLowerCase().includes(facilitySearchInput)
                  );
                  const values = filteredFacilities.map(({ id }) => id);
                  const options = facilities.map(({ id, name }, index) => ({
                    value: id,
                    key: index,
                    children: name
                  }));
                  handleInputMultiSelect(values, options, "claimFacilities");
                }}
                onClearAll={() => {
                  handleInputMultiSelect([], [], "claimFacilities");
                  setFacilitySearchInput("");
                }}
              >
                {facilities?.map((facility, index) => (
                  <Option key={index} value={facility.id} localSortKey={facility.name}>
                    {facility.name}
                  </Option>
                ))}
              </MultiSelect>
            </Space>
          </Space>
        </Panel>
      </Collapse>
    )
  );
};
