import React from "react";

import { Form, Checkbox } from "antd";
import IneligiblePatientWarning from "../IneligiblePatientWarning";
import useConditionalValidation from "./utils/useConditionalValidation";

export const CheckboxItem = ({
  name,
  label,
  required = false,
  rules = [],
  errorMessage = "",
  shouldValidateFields = false,
  ...props
}) => {
  useConditionalValidation(name, shouldValidateFields);
  const rquiredRules = [];

  if (required)
    rquiredRules.push({
      validator: (_, value) =>
        value
          ? Promise.resolve()
          : Promise.reject(
              errorMessage ? (
                <IneligiblePatientWarning ineligibleAnswerError={new Error(errorMessage)} />
              ) : (
                new Error("")
              )
            )
    });

  return (
    <Form.Item rules={[{ required }, ...rquiredRules, ...rules]} name={name} valuePropName="checked" {...props}>
      <Checkbox>{label}</Checkbox>
    </Form.Item>
  );
};
