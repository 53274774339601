import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "antd";

import { GenderFormItem } from "../../GenderFormItem";

export const GenderItem = (props) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const gender = Form.useWatch(props.name, form);

  return <GenderFormItem showError={!gender} thirdOption={t(props.thirdOption)} {...props} />;
};
