import React from "react";

import { CommonFieldBankController } from "../../components/common/commonFieldBankController";
import { useGetLabels } from "../../../../../hooks/useGetLabels";
import { novartisCopayi18nRoutes } from "./form/fieldLabels";
import { CheckboxItem, RadioItem } from "../../components/form";
import {
  conditionFields,
  eligibilityAndAttestationFields,
  patientFields,
  supportOptionsFields
} from "./form/fieldNames";
import { formSections } from "./form/sections";

export const getNovartisSpecificSections = () => {
  const { getAllSections } = useGetLabels(novartisCopayi18nRoutes);
  const {
    [formSections.PATIENT_SECTION]: patientLabels,
    [formSections.ELIGIBILITYANDATTESTATION_SECTION]: attestationLabels,
    [formSections.CONDITION_SECTION]: conditionLabels,
    [formSections.SUPPORTOPTIONS_SECTION]: supportOptionsLabels
  } = getAllSections();
  const commonFields = CommonFieldBankController();
  const genderAdditionalOptions = [
    { value: patientLabels.gender_option_3, label: patientLabels.gender_option_3 },
    { value: patientLabels.gender_option_4, label: patientLabels.gender_option_4 }
  ];

  return {
    patient: {
      ...commonFields.common,
      ...commonFields.patient,
      gender: ({ required, widthForAdditionalOptions, minWidth, minWidthForAdditionalOptions, showError }) =>
        commonFields.patient.gender({
          required,
          label: patientLabels.gender_header,
          additionalOptions: genderAdditionalOptions,
          showThirdOption: false,
          width: false,
          widthForAdditionalOptions: widthForAdditionalOptions,
          minWidth: minWidth,
          minWidthForAdditionalOptions: minWidthForAdditionalOptions,
          showError: showError
        }),
      biologicalMedication: ({ required }) => (
        <RadioItem
          name={patientFields.biologicalMedication}
          label={patientLabels.biologicalMedication}
          rules={[{ required }]}
          optionItems={biologicalMedicationOptions}
        />
      ),
      caregiverFirstName: ({ required }) =>
        commonFields.common.firstName({
          required,
          name: patientFields.caregiverFirstName
        }),
      caregiverLastName: ({ required }) =>
        commonFields.common.lastName({
          required,
          name: patientFields.caregiverLastName
        }),
      caregiverDob: ({ required }) => commonFields.patient.dob({ required, name: patientFields.caregiverDob }),
      caregiverGender: ({ required, minWidth, minWidthForAdditionalOptions, showError }) =>
        commonFields.patient.gender({
          required,
          name: patientFields.caregiverGender,
          additionalOptions: genderAdditionalOptions,
          showThirdOption: false,
          minWidth: minWidth,
          width: false,
          minWidthForAdditionalOptions: minWidthForAdditionalOptions,
          showError: showError
        }),
      caregiverAddress: ({ required }) =>
        commonFields.common.address({
          required,
          name: patientFields.caregiverAddress,
          label: patientLabels.address,
          listOfOptions: genderAdditionalOptions,
          showThirdOption: false
        }),
      caregiverCity: ({ required }) => commonFields.common.city({ required, name: patientFields.caregiverCity }),
      caregiverState: ({ required }) => commonFields.common.state({ required, name: patientFields.caregiverState }),
      caregiverZipCode: ({ required }) =>
        commonFields.common.zip_code({ required, name: patientFields.caregiverZipCode }),
      caregiverEmail: ({ required, additionalRules }) =>
        commonFields.common.email({
          required,
          name: patientFields.caregiverEmail,
          additionalRules: additionalRules
        }),
      caregiverPhoneNumber: ({ required }) =>
        commonFields.common.phone_number({
          required,
          name: patientFields.caregiverPhoneNumber,
          typeName: patientFields.caregiverPhoneType
        }),
      caregiverDivider: () =>
        commonFields.common.divider({ id: "novartis_caregiver_section", text: patientLabels.caregiverDivider })
    },
    condition: {
      primaryCondition: ({ required, optionItems }) => (
        <RadioItem
          label={conditionLabels.condition}
          name={conditionFields.primaryCondition}
          rules={[{ required }]}
          optionItems={optionItems}
          groupStyle={{ display: "flex", flexDirection: "column" }}
        />
      )
    },
    eligibilityAndAttestation: {
      HIPAA_Cosentyx: ({ required }) => (
        <CheckboxItem
          name={eligibilityAndAttestationFields.hipaaAuthConsent}
          label={attestationLabels.cosentyxConsent}
          required={required}
          errorMessage=""
        />
      ),
      HIPAA_Auth: ({ required }) => (
        <CheckboxItem
          name={eligibilityAndAttestationFields.hipaaAuthConsent}
          label={attestationLabels.hipaaAuthConsentText}
          required={required}
          errorMessage=""
        />
      )
    },
    supportOptions: {
      supportOption: ({ required, optionItems }) => (
        <RadioItem
          name={supportOptionsFields.supportOption}
          label={supportOptionsLabels.supportOption}
          rules={[{ required }]}
          optionItems={optionItems}
        />
      )
    }
  };
};
export const biologicalMedicationOptions = [
  { value: "Yes", displayValue: "Yes" },
  { value: "No", displayValue: "No" },
  { value: "Unsure", displayValue: "Unsure" }
];
