import { trimStart } from "lodash";
import { all, put, select, spawn, take, takeEvery, takeLatest } from "redux-saga/effects";

import { ApiNames, parseSearchFormParams, serverFiltersDecoder } from "../../api/api";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";
import SuccessMessage from "../../component/customComponent/customMessages/SuccessMessage";
import { MANUAL_AUTOMATION_SOURCES } from "../../constant/coverages";
import { defaultFilters, defaultSavedListFilters } from "../../constant/filters";
import { formPopupsContentKeys } from "../../constant/formPopupsContentKeys";
import { Routes } from "../../constant/routes";
import { PAGE_SIZE, SAVED_LIST_PAGE_SIZE, selectedViewTypes, selectedViews } from "../../constant/table";
import { tableColumns } from "../../constant/tableColumns";
import { history } from "../../router/history";
import { parseSelectedColumns } from "../../utils/columns";
import ACTION from "../action";
import {
  getUserSelectedColumns,
  selectFilterListStatuses,
  selectFilters,
  selectIsPhiAllowed,
  selectIsSavedListInit,
  selectPatientJourney,
  selectPotentialSavings,
  selectSelectedAssistanceProgramById,
  selectSelectedView,
  selectTableColumns,
  selectViewTablesData,
  selectedSavedListActiveColumn,
  selectedSavedListFilters,
  selectedViewTableData
} from "../selector";
import applicationFormSaga from "./applicationFormActions";
import { refreshClaimsTables } from "./claimsTablesActions";
import { callApi } from "./networkActions";

function* searchAssistanceProgram({ searchQuery, filters }) {
  let filtersData = {};
  Object.entries(filters).map(([filterName, values]) => {
    Object.entries(values).map(([value, text], index) => {
      if (typeof text !== "object") {
        filtersData = { ...filtersData, [`filters[${filterName}][${index}][value]`]: value };
        filtersData = { ...filtersData, [`filters[${filterName}][${index}][text]`]: text };
      } else {
        const newValues = text;
        Object.entries(newValues).map(([key, newValue]) => {
          filtersData = { ...filtersData, [`filters[${filterName}][${index}][${key}]`]: newValue };
        });
      }
    });
  });

  const assistanceProgramRes = yield callApi(ApiNames.AssistanceProgramSearch, searchQuery, filtersData);
  if (assistanceProgramRes) {
    yield put(ACTION.setAssistancePrograms(assistanceProgramRes.programsInPage));
  }
}

function* searchAssistanceProgramById({ id }) {
  const assistanceProgramRes = yield callApi(ApiNames.AssistanceProgram, id);
  if (assistanceProgramRes) {
    const assistanceProgram = assistanceProgramRes;
    yield put(ACTION.setSelectedAssistanceProgramById(assistanceProgram));
  }
}

function* searchPatientAction({ page, text, searchBy, sorter }) {
  if (text.trim() === "") {
    yield put(ACTION.setSearchedPatients([], 1));
    return;
  }

  let searchRes;
  const isPhiAllowed = yield isPhiAllowedFunc();
  searchRes = yield callApi(
    isPhiAllowed ? ApiNames.PhiSearchFreeText : ApiNames.SearchFreeText,
    page,
    trimStart(text),
    searchBy,
    sorter
  );

  if (searchRes) {
    yield put(ACTION.setSearchedPatients(searchRes, page, sorter));
  }
}

function* changeProviderNetworkAction({ policyId, patientId, journeyId, network }) {
  yield callApi(ApiNames.ChangeProviderNetwork, policyId, patientId, journeyId, network);
}

function* setFiltersAction({ filters, tableName }) {
  yield put(ACTION.setFilters(filters));
  const selectedView = tableName ? tableName : yield select(selectSelectedView);
  const { sorter } = yield select(selectedViewTableData);

  // Note: Page 1 is because whenever a filter change we must fetch from the 1th page.
  yield _fetchTableData({ page: 1, selectedView, sorter });
}

export function* isPhiAllowedFunc() {
  let isPhiAllowed = yield select(selectIsPhiAllowed);
  if (isPhiAllowed === undefined) {
    yield take(ACTION.Types.SET_IS_PHI_ALLOWED);
    isPhiAllowed = yield select(selectIsPhiAllowed);
  }
  return isPhiAllowed;
}

export function* setSummaryCounters() {
  const countersResult = yield callApi(ApiNames.CountByStatus);
  if (countersResult && countersResult.success) {
    const summaryCounters = countersResult.data;
    yield put(ACTION.setSummaryCounters(summaryCounters));
  } else {
    ErrorMessage(`Failed to get summary counters data`);
  }
}

function* _fetchTableData({ page, selectedView, sorter }) {
  if (selectedView === selectedViews.savedList) return;

  if (!selectedView) selectedView = selectedViews.allPatients;

  if (!selectedViewTypes[selectedView]) return;

  const isSavedListInit = yield select(selectIsSavedListInit);
  // Note: Need to wait for saved list until it's filter parameters is ready, otherwise there will be a data integrity issue
  if (window.location.pathname.indexOf(Routes.FILTER_LIST) > -1 && !isSavedListInit) {
    yield take(ACTION.Types.INIT_SAVED_LIST);
  }

  const currentView = yield select(selectSelectedView);
  const isUserSelectedView = currentView === selectedView;
  // we only need to fetch current selected view with filter, otherwise we should fetch data with default filters, so we won't affect other tables buckets.
  const filters = isUserSelectedView ? yield select(selectFilters) : defaultFilters;
  const isPhiAllowed = yield isPhiAllowedFunc();

  const tableData = yield callApi(
    ApiNames[`${selectedViewTypes[selectedView].title}${isPhiAllowed ? "PhiSearch" : "Search"}`],
    page,
    undefined,
    filters,
    sorter
  );

  if (tableData.patients.length === 0 && tableData.totalPatients % PAGE_SIZE === 0 && page > 1) {
    yield spawn(_fetchTableData, { page: page - 1, selectedView, sorter });
  }

  if (tableData) {
    yield put(ACTION.setTablesData(tableData, page, selectedView, sorter));
    if (tableData.totalPatients == 0 && page > 1) {
      // Note: refetch the first page if no data found for the requested page > 1.
      yield spawn(_fetchTableData, { page: 1, selectedView, sorter });
    }
  }
}

/**
 * Trigger the dashboard search call with the current user selected parameters(page, sort, filters), fallback to page 1 and no sorting
 * This must be used to refetch the data of a view.
 * All calls to this method must not provide the page/sorting params.
 * @param {*} tableViewParams : { page, selectedView, sorter }
 */
export function* setTableDataAction(tableViewParams) {
  const { selectedPage = 1, sorter = {} } = yield select(selectViewTablesData(tableViewParams.selectedView));
  yield _fetchTableData({ page: selectedPage, selectedView: tableViewParams.selectedView, sorter });
}

/**
 * Trigger the dashboard search call with the provided parameters(page, sort, filters)
 * This must be used to fetch the data of a view whenever pagination/sorting/filters changed.
 * @param {*} tableViewParams : { page, selectedView, sorter }
 */
function* handlePaginateSortChange(tableViewParams) {
  yield _fetchTableData(tableViewParams);
}

function safeStringify(obj) {
  try {
    return JSON.stringify(obj, null, 2);
  } catch (error) {
    console.error("Error stringifying object:", error, obj);
    return "[Error: Unable to stringify object]";
  }
}

function* patientDetailsAction({ patientId }) {
  let patientDetailsRes;
  let logs = []; // Collect logs to display them together

  try {
    logs.push(`Fetching PHI allowance status for patientId: ${patientId}`);
    const isPhiAllowed = yield isPhiAllowedFunc();
    logs.push(`PHI allowed status: ${isPhiAllowed}`);

    const apiName = isPhiAllowed ? ApiNames.PatientDetails : ApiNames.PatientDetailsNonPhi;
    logs.push(`Using API: ${apiName} for patientId: ${patientId}`);

    patientDetailsRes = yield callApi(apiName, patientId);
    logs.push(`API Response for patientId ${patientId}: ${safeStringify(patientDetailsRes)}`);

    if (patientDetailsRes) {
      // Log specific fields safely
      logs.push(
        `financial-navigation-patient-phis: ${
          patientDetailsRes["financial-navigation-patient-phis"]
            ? safeStringify(patientDetailsRes["financial-navigation-patient-phis"])
            : null
        }`
      );
      logs.push(
        `financial-navigation-patients: ${
          patientDetailsRes["financial-navigation-patients"]
            ? safeStringify(patientDetailsRes["financial-navigation-patients"])
            : null
        }`
      );

      if (
        patientDetailsRes["financial-navigation-patient-phis"] ||
        patientDetailsRes["financial-navigation-patients"]
      ) {
        const patientDetails =
          patientDetailsRes["financial-navigation-patient-phis"] || patientDetailsRes["financial-navigation-patients"];
        logs.push(`Patient details retrieved successfully: ${safeStringify(patientDetails)}`);

        yield put(ACTION.patientDetails(patientDetails));
      } else {
        logs.push(
          `Error: Both financial-navigation-patient-phis and financial-navigation-patients are null or undefined.`
        );
        logs.push(`Full Response: ${safeStringify(patientDetailsRes)}`);
        ErrorMessage("Could not retrieve patient details");
      }
    } else {
      logs.push(`Error: patientDetailsRes is null or undefined.`);
      ErrorMessage("Could not retrieve patient details");
    }
  } catch (error) {
    logs.push(`Error occurred while fetching patient details: ${error}`);
    ErrorMessage("Could not retrieve patient details");
  } finally {
    // Create the group and output all logs together
    console.groupCollapsed(`Patient Details Action - patientId: ${patientId}`);
    logs.forEach((log) => console.log(log));
    console.groupEnd();
  }
}

function* updatePatientDetailsAction({ patientDetails }) {
  const selectedView = yield select(selectSelectedView);
  if (selectedView) {
    yield spawn(setTableDataAction, { page: 1, selectedView });
  }
  yield put(ACTION.patientDetails([patientDetails]));
}

function* getPatientAction({ patientId, journeyId }) {
  yield put(ACTION.clearPatient());
  yield spawn(patientDetailsAction, { patientId });
  yield spawn(patientJourneyAction, { journeyId });
  yield getPotentialSavings();
}

function* providersAction({ providerType }) {
  try {
    const providerRes = yield callApi(ApiNames.Providers, providerType);
    if (providerRes) {
      yield put(ACTION.provider(providerType, providerRes));
    }
  } catch (error) {
    ErrorMessage("Could not retrieve providers");
  }
}

function* getPotentialSavings() {
  let patientJourney = yield select(selectPatientJourney);
  if (!patientJourney) {
    yield take(ACTION.Types.PATIENT_JOURNEY);
    patientJourney = yield select(selectPatientJourney);
  }

  if (!patientJourney.associatedPotentialSavings || !patientJourney.associatedPotentialSavings.length) return;

  const queryIds = patientJourney.associatedPotentialSavings;
  const isPhiAllowed = yield isPhiAllowedFunc();
  let potentialSavingsRes;
  potentialSavingsRes = yield callApi(
    isPhiAllowed ? ApiNames.PotentialSavings : ApiNames.PotentialSavingsNonPhi,
    queryIds
  );
  if (
    potentialSavingsRes &&
    (potentialSavingsRes["financial-navigation-potential-saving-phi"] ||
      potentialSavingsRes["financial-navigation-potential-saving"])
  ) {
    yield put(
      ACTION.potentialSavings(
        potentialSavingsRes["financial-navigation-potential-saving-phi"] ||
          potentialSavingsRes["financial-navigation-potential-saving"]
      )
    );
  } else {
    ErrorMessage("Potential savings could not be retrieved");
  }
}

function* patientJourneyAction({ journeyId }) {
  const isPhiAllowed = yield isPhiAllowedFunc();
  let patientJourneyRes;
  patientJourneyRes = yield callApi(isPhiAllowed ? ApiNames.PatientJourney : ApiNames.PatientJourneyNonPhi, journeyId);
  if (
    patientJourneyRes &&
    (patientJourneyRes["financial-navigation-patient-journey-phis"] ||
      patientJourneyRes["financial-navigation-patient-journey"])
  ) {
    yield put(
      ACTION.patientJourney(
        patientJourneyRes["financial-navigation-patient-journey-phis"] ||
          patientJourneyRes["financial-navigation-patient-journey"]
      )
    );
  } else {
    ErrorMessage("Could not get patient journey.");
  }
}

function* genericServicesAction({ queryIds }) {
  try {
    const genericServicesRes = yield callApi(ApiNames.GenericServices, queryIds);
    if (genericServicesRes && genericServicesRes["generic-services"]) {
      yield put(ACTION.genericServices(genericServicesRes["generic-services"]));
    }
  } catch (error) {
    ErrorMessage("Could not retrieve generic services");
  }
}

function* patientAutomateAction({ patientId, automateRule = MANUAL_AUTOMATION_SOURCES.MANUAL_REGULAR_AUTOMATION }) {
  try {
    const patientAutomateRes = yield callApi(ApiNames.PatientAutomate, patientId, automateRule);
    if (patientAutomateRes && patientAutomateRes.success) {
      yield patientDetailsAction({ patientId });
      SuccessMessage("Coverage data has been refreshed successfully");
    }
    if (!patientAutomateRes) {
      yield patientDetailsAction({ patientId });
      ErrorMessage("Coverage data has not been refreshed");
    }
  } catch (error) {
    ErrorMessage("Could not automate");
  }
}

function* updateDiscontinuedDateAction({ patientId, journeyId, journeyEvent, discontinuedDate }) {
  try {
    const updateDiscontinuedDateRes = yield callApi(
      ApiNames.UpdateDiscontinuedDate,
      patientId,
      journeyEvent,
      discontinuedDate
    );
    if (updateDiscontinuedDateRes) {
      yield patientJourneyAction({ journeyId });
      SuccessMessage(
        "You've just discontinued this drug. Note that if this drug arrives again in the data after TODAY, it will be created"
      );
      history.push(`/patient/${patientId}/journey/${journeyId}/edit`, { tab: "treatment" });
    }
  } catch (error) {
    ErrorMessage("Could not update discontinued date");
  }
}

function* updateOOPEstimationAction(data) {
  try {
    const updateOOPEstimation = yield callApi(ApiNames.UpdateOOPEstimation, data.data);
    if (updateOOPEstimation) {
      const { journey } = data;
      yield getOOPEstimationsAction({ journey });
      SuccessMessage("OOP estimation successfully saved.");
    }
  } catch (error) {
    ErrorMessage("Unable to save OOP estimation");
  }
}

function* getOOPEstimationsAction(journey) {
  try {
    const updateOOPEstimation = yield callApi(ApiNames.GetOOPEstimations, journey);
    if (updateOOPEstimation) {
      yield put(ACTION.setOOPEstimations(updateOOPEstimation));
    }
  } catch (error) {
    ErrorMessage("Unable to load OOP estimations");
  }
}

function* potentialSavingsJourneyAction({ journeyId }) {
  try {
    const potentialSavingsJourneyRes = yield callApi(ApiNames.PotentialSavingsJourney, journeyId);
    if (potentialSavingsJourneyRes && potentialSavingsJourneyRes["financial-navigation-potential-saving-phi"]) {
      yield put(
        ACTION.potentialSavingsJourney(potentialSavingsJourneyRes["financial-navigation-potential-saving-phi"])
      );
    }
  } catch (error) {
    ErrorMessage("Could not get potential savings");
  }
}

function* patientCoveragesAction({ patientId }) {
  try {
    const patientCoveragesRes = yield callApi(ApiNames.PatientCoverages, patientId);
    if (patientCoveragesRes && patientCoveragesRes["coverages"]) {
      yield put(ACTION.patientCoverages(patientCoveragesRes["coverages"]));

      const policyIds = [];

      patientCoveragesRes["coverages"].forEach((coverage) => policyIds.push(coverage.policy));

      if (policyIds.length === patientCoveragesRes["coverages"].length && patientCoveragesRes["coverages"].length > 0) {
        yield put(ACTION.policiesAction(policyIds));
      }
    }
  } catch (error) {
    ErrorMessage("Could not get patient coverages");
  }
}

function* patientUnknownAndInactiveInsuranceInfoAction({ patientId }) {
  try {
    const unknownAndInactiveInsuranceInfoRes = yield callApi(
      ApiNames.PatientUnknownAndInactiveInsuranceInfo,
      patientId
    );
    if (unknownAndInactiveInsuranceInfoRes) {
      yield put(ACTION.setPatientUnknownAndInactiveInsuranceInfo(unknownAndInactiveInsuranceInfoRes));
    }
  } catch (error) {
    ErrorMessage(`Failed to get Unknown Insurance Info: ${error}`);
  }
}

function* policiesAction({ queryIds }) {
  const policiesRes = yield callApi(ApiNames.Policies, queryIds);
  if (policiesRes && policiesRes["policies"]) {
    yield put(ACTION.policies(policiesRes["policies"]));
  } else {
    ErrorMessage("Could not get policies");
  }
}

function* refetchPendingRenewals() {
  yield spawn(setTableDataAction, { page: 1, selectedView: selectedViews.pendingRenewal });
}

function* refetchAllApplications() {
  yield spawn(setTableDataAction, { page: 1, selectedView: selectedViews.allApplications });
}

function* refetchAllClaims() {
  yield spawn(setTableDataAction, { page: 1, selectedView: selectedViews.allClaims });
}

function* refetchPendingRenewalsAllApplicationsAndClaims() {
  yield refetchPendingRenewals();
  yield refetchAllApplications();
  yield refetchAllClaims();
}

function* applicationEditAction({
  papId,
  papData,
  patientId,
  journeyId,
  apaId,
  afterApplicationUpdate,
  applicationForm
}) {
  const isPhiAllowed = yield isPhiAllowedFunc();
  const associatedDrugs = papData.associatedDrugName?.trim()?.split(",");

  const selectedAssistanceProgramById = yield select(selectSelectedAssistanceProgramById);

  const isInternalProgram = selectedAssistanceProgramById.internal;

  const applicationEditRes = yield callApi(
    isPhiAllowed ? ApiNames.ApplicationEdit : ApiNames.ApplicationEditNonPhi,
    papId,
    papData,
    apaId,
    isInternalProgram,
    associatedDrugs
  );
  if (!applicationEditRes) {
    return;
  }

  let updatedPap;
  const fieldName = "financial-navigation-potential-saving-phi";
  if (applicationEditRes[fieldName]?.length) {
    updatedPap = applicationEditRes[fieldName][0];
  }

  if (afterApplicationUpdate) {
    const afterApplicationUpdateRes = yield afterApplicationUpdate(applicationForm, apaId, papId);
    if (afterApplicationUpdateRes?.data) {
      updatedPap = afterApplicationUpdateRes.data;
    }
  }

  if (
    updatedPap?.applications?.some(({ status }) => {
      return status === "discarded";
    })
  ) {
    SuccessMessage("Potential assistance program deleted successfully");
    yield getPotentialSavings();
  } else {
    if (updatedPap) {
      SuccessMessage("Application updated successfully");
      yield put(ACTION.potentialSaving(updatedPap));

      if (history && patientId && journeyId) {
        history.push(
          `/patient/${patientId}/journey/${journeyId}/applications/${papId}/${applicationEditRes[fieldName][0].currentApplication}`
        );
      }
    }
  }

  yield refetchPendingRenewalsAllApplicationsAndClaims();
  yield put(ACTION.getSummaryCounters());
}

function* getApplicationAttachments({ applicationId }) {
  const applicationAttachments = yield callApi(ApiNames.ApplicationAttachments, applicationId);
  if (applicationAttachments) {
    yield put(ACTION.setApplicationAttachments(applicationAttachments));
  } else {
    ErrorMessage("Could not get Application Attachments");
  }
}

function* applicationDeleteAction({ papData, patientId, journeyId, papId, applicationId }) {
  try {
    const updatedApplications = papData.applications.filter((app) => applicationId !== app.id);
    const applicationDeleteRes = yield callApi(
      ApiNames.ApplicationDelete,
      patientId,
      papId,
      applicationId,
      [...updatedApplications].reverse()
    );
    if (applicationDeleteRes?.result === "success") {
      SuccessMessage("Application updated successfully");

      yield getPatientAction({ patientId, journeyId });
      yield refetchPendingRenewalsAllApplicationsAndClaims();
      yield put(ACTION.getSummaryCounters());
    }
  } catch (error) {
    ErrorMessage("Could not edit application");
  }
}

function* searchSavedList({ page }) {
  const { query, filterBy } = yield select(selectedSavedListFilters);
  const { name: orderBy, sortBy } = yield select(selectedSavedListActiveColumn);
  const skip = Math.abs((page - 1) * SAVED_LIST_PAGE_SIZE);
  const filters = {
    ...defaultSavedListFilters,
    query,
    filterBy,
    skip,
    orderBy,
    sortBy
  };

  const savedListSearchRes = yield callApi(ApiNames.searchSavedList, filters);

  if (savedListSearchRes) {
    yield put(ACTION.setTablesData(savedListSearchRes, page, selectedViews.savedList));
  }
}

function* getAllClaims({ applicationId }) {
  const allClaimsRes = yield callApi(ApiNames.getAllClaims, applicationId);

  if (allClaimsRes) {
    yield put(ACTION.setApplicationClaims(allClaimsRes.claims));
  }
}

function* archiveOrRestoreClaim({ patientId, claim, isActive }) {
  const archiveOrRestoreClaimsRes = yield callApi(ApiNames.ToggleClaimActiveStatus, patientId, claim.id, isActive);

  if (archiveOrRestoreClaimsRes) {
    yield refreshClaimsTables(archiveOrRestoreClaimsRes.claim, claim?.attachmentCount, claim?.notesCount);

    SuccessMessage(`Claim was ${isActive ? `restored` : `deleted`} successfully.`);
  } else {
    ErrorMessage(`There was a problem ${isActive ? `restoring` : `deleting`} the claim. Please try again.`);
  }
}

function* applyServerFiltersAction({ selectedPage, sorter }) {
  try {
    const serverFilters = yield select(selectFilters);
    const selectedView = yield select(selectSelectedView);
    const filterInputs = parseSearchFormParams({
      selectedView,
      page: selectedPage || 1,
      sorters: sorter,
      limit: PAGE_SIZE,
      filters: serverFilters
    });

    const decodedFilters = serverFiltersDecoder(filterInputs.filters, serverFilters);
    yield put(ACTION.setMultipleFiltersInputs(decodedFilters));
  } catch (error) {
    ErrorMessage("Could not apply saved list filters");
  }
}

function* clearSavedListRelatedDataAction() {
  yield put(ACTION.setFilterList({}));
  yield put(ACTION.setTableColumns(tableColumns));
}

function* findSavedListById(data) {
  const { selectedPage = 1, sorter = {}, isModified = false } = yield select(selectFilterListStatuses);

  const savedListSearchRes = yield callApi(ApiNames.FindSavedListById, data.data);
  if (savedListSearchRes && savedListSearchRes.isValid) {
    const paramsRes = JSON.parse(savedListSearchRes?.params);
    savedListSearchRes.params = paramsRes;
    const selectedView = savedListSearchRes?.selectedView;

    yield put(ACTION.setFilterList(savedListSearchRes));
    yield put(ACTION.initSavedListAction());

    if (isModified) {
      // Note: If user apply changes on the filter, we wanted to use the user selected page and sorter parameters
      const userSelectedFilter = yield select(selectFilters);
      const userSelectedParams = yield select(selectViewTablesData(selectedView));
      const userSelectedColumns = yield select(selectTableColumns);
      const userSelectedViewColumns = yield select(getUserSelectedColumns);
      // Note: merge columns
      const newTableColumns = JSON.parse(JSON.stringify(userSelectedColumns));
      userSelectedViewColumns && parseSelectedColumns(newTableColumns, selectedView, userSelectedViewColumns);
      yield put(ACTION.setTableColumns(newTableColumns));

      yield applySavedListFilters(
        selectedView,
        { ...userSelectedFilter },
        { selectedPage: userSelectedParams.selectedPage || selectedPage, sorter: userSelectedParams.sorter || sorter }
      );
    } else {
      // Note: merge columns
      const newTableColumns = JSON.parse(JSON.stringify(tableColumns));
      paramsRes?.selectedColumns && parseSelectedColumns(newTableColumns, selectedView, paramsRes.selectedColumns);
      yield put(ACTION.setTableColumns(newTableColumns));

      // Note: Use saved filter parameter if user didn't interact with the filters.
      yield applySavedListFilters(selectedView, { ...paramsRes.filters }, { selectedPage, sorter });
    }
  } else {
    ErrorMessage("This saved list could not be loaded - please try again or select another list.");
    history.push(`/?selectedView=${selectedViews.savedList}`);
  }
}

function* applySavedListFilters(selectedView, filters, { selectedPage, sorter }) {
  yield put(ACTION.setSelectedView(selectedView));
  // Note: update filters in the store and after that trigger data fetch request
  yield put(ACTION.setFilters(filters));
  yield spawn(_fetchTableData, {
    page: selectedPage,
    selectedView,
    sorter
  });
  yield put(ACTION.applyServerFiltersAction({ selectedPage, sorter }));
}

function* createFilterList(data) {
  try {
    const createSavedListRes = yield callApi(ApiNames.CreateFilterList, data);
    if (createSavedListRes) {
      SuccessMessage(`'${createSavedListRes.name}' list was saved`, {
        closeButtonId: "saved_list_view_list_closed",
        title: "View List",
        redirectTo: `${Routes.FILTER_LIST}/${createSavedListRes.id}`,
        linkId: "saved_list_view_list"
      });
      yield put(ACTION.setFormPopups(false, formPopupsContentKeys.ADD_NEW_LIST));
    }
  } catch (err) {
    const errMessage = err?.response?.data?.errors ? err.response.data.errors[0].message : err.message;
    ErrorMessage(errMessage);
  }
}

function* createClaimAttachments(attachmentsList, claimId, patientId) {
  const createAttachmentsClaimRes = yield all(
    attachmentsList.map((attachment) =>
      callApi(ApiNames.CreateAttachmentClaim, {
        claim: claimId,
        patient: patientId,
        file: attachment
      })
    )
  );

  const isUploadedSuccessfully = createAttachmentsClaimRes.every((res) => res.status === 1 && res.success === true);

  if (isUploadedSuccessfully) {
    SuccessMessage(`Claim attachment uploaded successfully.`);
  } else {
    ErrorMessage(`Attachment upload failed, Please try again.`);
  }
}

function* duplicateClaimAttachments(sourceClaimId, targetClaimId, patientId, skippedAttachments) {
  const duplicateAttachmentClaimData = {
    patientId,
    sourceClaimId,
    targetClaimId,
    skippedAttachments
  };

  const duplicateAttachmentClaimRes = yield callApi(ApiNames.DuplicateClaimAttachments, duplicateAttachmentClaimData);

  if (duplicateAttachmentClaimRes) {
    SuccessMessage(`Claim attachment was Duplicated successfully.`);
    return duplicateAttachmentClaimRes;
  } else {
    ErrorMessage(`Attachment duplicated failed, Please try again.`);
  }
}

function* duplicateClaim({ data, journeyId, potentialSavingId, attachmentsList }) {
  const { sourceClaimId, claimData, duplicateAttachments, duplicateNotes } = data;

  if (claimData.claim?.id) delete claimData.claim.id;

  if (claimData.claim?.claimLineItems.length > 0) {
    claimData.claim?.claimLineItems.forEach((item) => {
      item?.id ? delete item.id : "";
    });
  }

  const currentNotesList = claimData?.notes.filter((item) => !item.markAsDeleted);

  if (claimData.notes.length > 0) {
    claimData.notes.forEach((item) => {
      item?.id ? delete item.id : "";
    });
  }

  if (duplicateNotes === "false") {
    claimData.notes = [];
  } else {
    claimData.notes = currentNotesList;
  }

  const createClaimRes = yield callApi(ApiNames.CreateClaim, claimData);

  let notesCount = currentNotesList.length || 0;
  let attachmentsCount = 0;
  if (createClaimRes) {
    if (duplicateAttachments === "true") {
      const skippedAttachments = attachmentsList.filter((item) => item.markAsDeleted === true).map((item) => item.id);
      const attachmentsListForAdd = attachmentsList.filter((item) => !item.markAsDeleted && !item.id);

      const duplicateAttachmentClaimRes = yield duplicateClaimAttachments(
        sourceClaimId,
        createClaimRes.claim.id,
        claimData.patientId,
        skippedAttachments
      );

      if (attachmentsListForAdd.length > 0) {
        yield createClaimAttachments(attachmentsListForAdd, createClaimRes.claim.id, claimData.patientId);
      }

      attachmentsCount = duplicateAttachmentClaimRes.success ? 1 : 0;
    }

    history.push(
      `/patient/${claimData.patientId}/journey/${journeyId}/applications/${potentialSavingId}/${claimData.claim.assistanceProgramApplication}/${Routes.CLAIMS}/`
    );

    SuccessMessage(`Claim was Duplicated successfully.`);
    yield refreshClaimsTables(createClaimRes.claim, attachmentsCount, notesCount);
  } else {
    ErrorMessage(`Duplicate claim failed, Please try again.`);
  }
}

function* createClaim({ data, attachmentsList, journeyId, potentialSavingId }) {
  const createClaimRes = yield callApi(ApiNames.CreateClaim, data);
  const currentAttachmentsList = attachmentsList.filter((item) => !item.markAsDeleted);
  const currentNotesList = data?.notes.filter((item) => !item.markAsDeleted);

  if (createClaimRes) {
    if (attachmentsList.length > 0) {
      yield createClaimAttachments(attachmentsList, createClaimRes.claim.id, data.patientId);
    }
    history.push(
      `/patient/${data.patientId}/journey/${journeyId}/applications/${potentialSavingId}/${data.claim.assistanceProgramApplication}/${Routes.CLAIMS}/`
    );

    yield refreshClaimsTables(createClaimRes.claim, currentAttachmentsList.length, currentNotesList.length);
    SuccessMessage(`Claim was saved successfully.`);
  } else {
    ErrorMessage(`Failed to save claim - please try again.`);
  }
}

function* updateClaim({ data, claimId, attachmentsList, journeyId, potentialSavingId }) {
  const attachmentsListForDelete = attachmentsList.filter((item) => item.markAsDeleted && item.id);
  const attachmentsListForAdd = attachmentsList.filter((item) => !item.markAsDeleted && !item.id);
  const updateClaimRes = yield callApi(ApiNames.UpdateClaim, data, claimId);
  const currentAttachmentsList = attachmentsList.filter((item) => !item.markAsDeleted);
  const currentNotesList = data?.notes.filter((item) => !item.markAsDeleted);

  yield all(attachmentsListForDelete.map((attachment) => callApi(ApiNames.DeleteAttachmentClaim, attachment.id)));

  if (attachmentsListForAdd.length > 0) {
    yield createClaimAttachments(attachmentsListForAdd, claimId, data.patientId);
  }

  if (updateClaimRes) {
    SuccessMessage(`Claim was saved successfully.`);
    history.push(
      `/patient/${data.patientId}/journey/${journeyId}/applications/${potentialSavingId}/${data.claim.assistanceProgramApplication}/${Routes.CLAIMS}/`
    );
    yield refreshClaimsTables(updateClaimRes.claim, currentAttachmentsList.length, currentNotesList.length);
  } else {
    ErrorMessage(`Failed to save claim - please try again.`);
  }
}

function* updateFilterList({ data, filterListId }) {
  try {
    const updateSavedListRes = yield callApi(ApiNames.UpdateFilterList, data, filterListId);
    if (updateSavedListRes) {
      SuccessMessage(`List was successfully updated `);
      yield put(ACTION.setFilterList(updateSavedListRes));
      const selectedView = yield select(selectSelectedView);
      if (selectedView === selectedViews.savedList) {
        // Note: if we are in saved list view. after update a filter Just refetch the saved filters records
        yield put(ACTION.searchSavedListAction(1));
      }
      yield put(ACTION.setFormPopups(false, formPopupsContentKeys.ADD_NEW_LIST));
    }
  } catch (err) {
    const errMessage = err?.response?.data?.errors ? err.response.data.errors[0].message : err.message;
    ErrorMessage(errMessage);
  }
}

function* applicationEditHistoricalAction({ patientId, journeyId, papId, applications }) {
  try {
    const applicationDeleteRes = yield callApi(ApiNames.ApplicationEditHistorical, patientId, papId, applications);
    if (applicationDeleteRes?.result === "success") {
      SuccessMessage("Application updated successfully");
      yield getPatientAction({ patientId, journeyId });
      yield refetchPendingRenewalsAllApplicationsAndClaims();
      yield put(ACTION.getSummaryCounters());
    }
  } catch (error) {
    ErrorMessage("Could not edit application");
  }
}

function* patientCreateApplicationAction({ patientId, journeyId, papData, status }) {
  const isPhiAllowed = yield isPhiAllowedFunc();
  const associatedDrugs = papData.associatedDrugName?.trim()?.split(",");

  try {
    const patientReapplyRes = yield callApi(
      isPhiAllowed ? ApiNames.PatientReapply : ApiNames.PatientReapplyNonPhi,
      papData.id,
      status,
      true,
      associatedDrugs
    );
    if (patientReapplyRes && patientReapplyRes.success && patientReapplyRes.createdNewApplication) {
      yield put(
        ACTION.potentialSaving(
          {
            ...papData,
            currentApplication: patientReapplyRes.createdNewApplication.id,
            applications: [...papData.applications, patientReapplyRes.createdNewApplication],
            status: patientReapplyRes.createdNewApplication.status
          },
          patientReapplyRes.createdNewApplication.id
        )
      );
      history.push(
        `/patient/${patientId}/journey/${journeyId}/applications/${patientReapplyRes.createdNewApplication.pap}/${patientReapplyRes.createdNewApplication.id}`
      );
      yield refetchAllApplications();
      yield put(ACTION.getSummaryCounters());
    }
  } catch (error) {
    ErrorMessage("Could not create a new application");
  }
}

function* patientReapplyAction({ patientId, journeyId, papData }) {
  const associatedDrugs = papData.associatedDrugName?.trim()?.split(",");
  const createNewApplication = false;
  const status = undefined;
  const isPhiAllowed = yield isPhiAllowedFunc();
  const patientReapplyRes = yield callApi(
    isPhiAllowed ? ApiNames.PatientReapply : ApiNames.PatientReapplyNonPhi,
    papData.id,
    status,
    createNewApplication,
    associatedDrugs
  );
  if (patientReapplyRes && patientReapplyRes.success && patientReapplyRes.createdNewApplication) {
    yield potentialSavingAction({ papId: papData.id, appId: patientReapplyRes.createdNewApplication.id });
    const potentialSavings = yield select(selectPotentialSavings);

    const papArray = potentialSavings.filter(
      ({ currentApplication }) =>
        currentApplication && currentApplication === patientReapplyRes.createdNewApplication.id
    );

    if (papArray.length === 1) {
      SuccessMessage("Reapplied to application successfully");
      history.push(
        `/patient/${patientId}/journey/${journeyId}/applications/${papData.id}/${papArray[0].currentApplication}`
      );
      yield refetchPendingRenewalsAllApplicationsAndClaims();
      yield put(ACTION.getSummaryCounters());
    } else {
      ErrorMessage("Failed to reapply to a new application");
    }
  } else {
    ErrorMessage("Failed to reapply to a new application");
  }
}

function* potentialSavingAction({ papId, appId }) {
  const isPhiAllowed = yield isPhiAllowedFunc();

  const getPotentialSavingRes = isPhiAllowed
    ? yield callApi(ApiNames.PotentialSaving, papId)
    : yield callApi(ApiNames.PotentialSavingsNonPhi, [papId]);

  const fieldName = isPhiAllowed
    ? "financial-navigation-potential-saving-phi"
    : "financial-navigation-potential-saving";
  if (getPotentialSavingRes && getPotentialSavingRes[fieldName] && getPotentialSavingRes[fieldName][0]) {
    const receivedPap = getPotentialSavingRes[fieldName][0];
    yield put(ACTION.potentialSaving(receivedPap, appId));
  } else {
    ErrorMessage("Could not get potential saving");
  }
}

function* applicationRelevanceForRenewal({ papId, applicationId, isIrrelevant }) {
  try {
    const patientReapplyRes = yield callApi(ApiNames.RelevanceForRenewal, papId, applicationId, isIrrelevant);
    yield put(ACTION.updateApplicationRelevanceAction(papId, patientReapplyRes[0].isIrrelevantForRenewal));
    yield refetchPendingRenewals();
    yield put(ACTION.getSummaryCounters());
  } catch (error) {
    ErrorMessage("Could not update application's relevance");
  }
}

function* getShippingOptions({ patientId, papId }) {
  try {
    const shippingOptions = yield callApi(ApiNames.shippingOptions, patientId, papId);
    yield put(ACTION.shippingOptions(shippingOptions));
  } catch (error) {
    ErrorMessage("Could not get patient's shipping options");
  }
}

function* actions() {
  yield takeEvery(ACTION.Types.SEARCH_ASSISTANCE_PROGRAM_ACTION, searchAssistanceProgram);
  yield takeEvery(ACTION.Types.SEARCH_ASSISTANCE_PROGRAM_BY_ID_ACTION, searchAssistanceProgramById);
  yield takeEvery(ACTION.Types.SET_FILTERS_ACTION, setFiltersAction);
  yield takeEvery(ACTION.Types.SET_TABLE_DATA_ACTION, setTableDataAction);
  yield takeEvery(ACTION.Types.PAGINATE_SORT_CHANGE, handlePaginateSortChange);
  yield takeEvery(ACTION.Types.CREATE_FILTER_LIST_ACTION, createFilterList);
  yield takeEvery(ACTION.Types.UPDATE_FILTER_LIST_ACTION, updateFilterList);
  yield takeEvery(ACTION.Types.SEARCH_PATIENTS_ACTION, searchPatientAction);
  yield takeEvery(ACTION.Types.CHANGE_PROVIDER_NETWORK_ACTION, changeProviderNetworkAction);
  yield takeEvery(ACTION.Types.GET_PATIENT_ACTION, getPatientAction);
  yield takeEvery(ACTION.Types.GET_POTENTIAL_SAVINGS, getPotentialSavings);
  yield takeEvery(ACTION.Types.GET_PATIENT_DETAILS_ACTION, patientDetailsAction);
  yield takeEvery(ACTION.Types.GET_PATIENT_JOURNEY_ACTION, patientJourneyAction);
  yield takeEvery(ACTION.Types.UPDATE_PATIENT_DETAILS_ACTION, updatePatientDetailsAction);
  yield takeEvery(ACTION.Types.PROVIDERS_ACTION, providersAction);
  yield takeEvery(ACTION.Types.GENERIC_SERVICES_ACTION, genericServicesAction);
  yield takeEvery(ACTION.Types.PATIENT_AUTOMATE_ACTION, patientAutomateAction);
  yield takeEvery(ACTION.Types.UPDATE_DISCONTINUED_DATE_ACTION, updateDiscontinuedDateAction);
  yield takeEvery(ACTION.Types.UPDATE_OOP_ESTIMATION, updateOOPEstimationAction);
  yield takeEvery(ACTION.Types.GET_OOP_ESTIMATION, getOOPEstimationsAction);
  yield takeEvery(ACTION.Types.POTENTIAL_SAVINGS_JOURNEY_ACTION, potentialSavingsJourneyAction);
  yield takeEvery(ACTION.Types.PATIENT_COVERAGES_ACTION, patientCoveragesAction);
  yield takeEvery(
    ACTION.Types.PATIENT_UNKNOWN_AND_INACTIVE_INSURANCE_ACTION,
    patientUnknownAndInactiveInsuranceInfoAction
  );
  yield takeLatest(ACTION.Types.SEARCH_SAVED_LIST_BY_ID, findSavedListById);
  yield takeEvery(ACTION.Types.POLICIES_ACTION, policiesAction);
  yield takeEvery(ACTION.Types.APPLICATION_EDIT_ACTION, applicationEditAction);
  yield takeEvery(ACTION.Types.GET_APPLICATION_ATTACHMENTS, getApplicationAttachments);
  yield takeEvery(ACTION.Types.APPLICATION_DELETE_ACTION, applicationDeleteAction);
  yield takeEvery(ACTION.Types.APPLICATION_EDIT_HISTORICAL_ACTION, applicationEditHistoricalAction);
  yield takeEvery(ACTION.Types.PATIENT_REAPPLY_ACTION, patientReapplyAction);
  yield takeEvery(ACTION.Types.PATIENT_CREATE_APPLICATION_ACTION, patientCreateApplicationAction);
  yield takeEvery(ACTION.Types.APPLICATION_RELEVANCE_FOR_RENEWAL, applicationRelevanceForRenewal);
  yield takeEvery(ACTION.Types.GET_SHIPPING_OPTIONS, getShippingOptions);
  yield takeLatest(ACTION.Types.SEARCH_SAVED_LIST_ACTION, searchSavedList);
  yield takeEvery(ACTION.Types.APPLY_SERVER_FILTERS_ACTION, applyServerFiltersAction);
  yield takeEvery(ACTION.Types.CLEAR_SAVED_LIST_RELATED_DATA_ACTION, clearSavedListRelatedDataAction);
  yield takeLatest(ACTION.Types.GET_SUMMARY_COUNTERS, setSummaryCounters);
  yield takeLatest(ACTION.Types.GET_ALL_CLAIMS, getAllClaims);
  yield takeEvery(ACTION.Types.CREATE_CLAIM_ACTION, createClaim);
  yield takeEvery(ACTION.Types.UPDATE_CLAIM_ACTION, updateClaim);
  yield takeEvery(ACTION.Types.TOGGLE_CLAIM_ACTIVE_STATUS_ACTION, archiveOrRestoreClaim);
  yield takeEvery(ACTION.Types.DUPLICATE_CLAIM_ACTION, duplicateClaim);
  yield takeEvery(ACTION.Types.GET_POTENTIAL_SAVING, potentialSavingAction);
  yield takeEvery(ACTION.Types.REFETCH_ALL_APPLICATIONS, refetchAllApplications);

  // Application Form Sagas
  yield spawn(applicationFormSaga);
}

export { actions };
