import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { Option } from "antd/lib/mentions";
import _ from "lodash";
import React, { useState } from "react";
import * as API from "../api/api";
import { MultiSelect } from "./customComponent/CustomSelect";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";

export default function PatientPayersMultiSelect({ onChange, onClear, value, ...props }) {
  const [isFetching, setFetching] = useState(false);
  const [isTyping, setTyping] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchQry, setSearchQry] = useState("");

  const mergedOptions = isTyping ? options : _.uniq([...value, ...options]);

  const fetchPayers = async (searchQry) => {
    setOptions([]);
    try {
      const resp = await API.searchPatientPayers(searchQry);
      setOptions(resp.data);
    } catch (error) {
      ErrorMessage("failed to fetch patients payers");
    } finally {
      setFetching(false);
      setTyping(false);
    }
  };

  const handleSearch = async (value) => {
    if (value?.length > 0) {
      setTyping(true);
    } else {
      setTyping(false);
    }
    setSearchQry(value);
    if (value?.length < 3) {
      setOptions([]);
      setFetching(false);
      return;
    }

    setFetching(true);
    _.debounce(fetchPayers, 600, { maxWait: 800 })(value);
  };

  const handleNotFoundContent = () => {
    if (isFetching) {
      return <Spin size={"small"} indicator={<LoadingOutlined spin />} />;
    }
    if (options.length == 0 && searchQry.length >= 3) {
      return "Not Found";
    }
    return null;
  };

  return (
    <MultiSelect
      onChange={(values, currentOptions) => {
        setSearchQry("");
        onChange(values, currentOptions);
      }}
      mode="multiple"
      allowClear={true}
      style={{ width: "100%" }}
      bordered={false}
      onSearch={handleSearch}
      onBlur={() => setOptions([])}
      onClearAll={onClear}
      loading={isFetching}
      value={value}
      notFoundContent={handleNotFoundContent()}
      {...props}
    >
      {mergedOptions.map((payer, idx) => (
        <Option key={`payer_${idx}`} id={`payer_${idx}`} value={payer}>
          {payer}
        </Option>
      ))}
    </MultiSelect>
  );
}
