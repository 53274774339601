import React from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";

import { ReactComponent as NoPatientsFoundIcon } from "../../assets/svg/NoPatientsFoundIcon.svg";
import { ReactComponent as NoEnrollmentsFoundIcon } from "../../assets/svg/no-enrollments-found-Icon.svg";

import { fontWeights, sizes } from "../../constant/styles";
import { BLACK } from "../../constant/colors";

const { Text } = Typography;

const emptyContentMap = (t) => {
  return {
    patients: {
      icon: <NoPatientsFoundIcon />,
      messageKey: t("no_patients_found")
    },
    enrollments: {
      icon: <NoEnrollmentsFoundIcon />,
      messageKey: t("no_enrollments_found")
    }
  };
};

const EmptyTableMessage = ({ type = "patients" }) => {
  const { t } = useTranslation();
  const { icon, messageKey } = emptyContentMap(t)[type];

  return (
    <div
      id="empty-table-message"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: type === "enrollments" ? 8 : 0,
        margin: "1em"
      }}
    >
      {icon}
      <Text style={{ fontWeight: fontWeights.semibold, color: BLACK, fontSize: sizes.medium_large, opacity: "0.5" }}>
        {messageKey}
      </Text>
    </div>
  );
};

export default EmptyTableMessage;
