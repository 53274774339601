import { getGenentechSpecificSections } from "./Genentech/freeDrug/genentechPapFieldsConfiguration";
import { getNovartisSpecificSections } from "./Novartis/copay/novartisCopayFieldsConfiguration";
import { getPfizerCopayOralIQVIASpecificSections } from "./Pfizer/copay/oral/iqvia/pfizerCopayOralFieldsConfiguration";

export const vendors = {
  GENENTECH_PAP: "genentech_pap",
  NOVARTIS_COPAY: "novartis_copay",
  PFIZER_COPAY_ORAL_IQVIA: "pfizer_copay_oral_iqvia"
};

export const formSectionsController = () => {
  const configurations = {
    [vendors.GENENTECH_PAP]: () => getGenentechSpecificSections(),
    [vendors.NOVARTIS_COPAY]: () => getNovartisSpecificSections(),
    [vendors.PFIZER_COPAY_ORAL_IQVIA]: () => getPfizerCopayOralIQVIASpecificSections()
  };

  return {
    getSectionFieldsByVendor: (vendor) => configurations[vendor]
  };
};
