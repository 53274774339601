import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { insuranceFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { InsuranceTypeFormItem, pfizerPapPlanMarkets } from "./InsuranceTypeFormItem";
import { PolicyholderDetails } from "./PolicyholderDetails";

export const MEDICAL_INSURANCE = "medical";

const InsuranceDetails = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const insuranceType = Form.useWatch(insuranceFields.medicalType, form);

  useEffect(() => {
    form.validateFields();
  }, [insuranceType]);

  return (
    <>
      <Row>
        <InsuranceTypeFormItem
          type={MEDICAL_INSURANCE}
          name={insuranceFields.medicalType}
          OtherOptionFormItemName={insuranceFields.medicalOtherTypeName}
        />
      </Row>
      {Object.values(pfizerPapPlanMarkets).includes(insuranceType) && insuranceType !== pfizerPapPlanMarkets.NONE && (
        <>
          <Row gutter={14}>
            <Col span={9}>
              <FormItem
                name={insuranceFields.medicalName}
                label={t("application_form_editor.pfizer.insurance.labels.payer_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.payer_name")} />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
                name={insuranceFields.medicalPhone}
                label={t("application_form_editor.pfizer.insurance.labels.payer_phone")}
              >
                <InputPhone placeholder={t("application_form_editor.pfizer.insurance.placeholders.payer_phone")} />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                name={insuranceFields.medicalGroup}
                label={t("application_form_editor.pfizer.insurance.labels.group_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.group_id")}
                />
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem
                name={insuranceFields.medicalMember}
                label={t("application_form_editor.pfizer.insurance.labels.member_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.member_id")}
                />
              </FormItem>
            </Col>
          </Row>
          <PolicyholderDetails
            policyholderSameAsPatientField={insuranceFields.medicalPolicyholderSameAsPatient}
            policyholderNameField={insuranceFields.medicalPolicyholderName}
          />
        </>
      )}
    </>
  );
};

export { InsuranceDetails };
