import React, { useState, useEffect } from "react";
import { Upload, Button, Typography, Select, List, Row, Col, Popconfirm, Modal, Space, Tooltip } from "antd";
import {
  CloseOutlined,
  DownloadOutlined,
  DownOutlined,
  FileImageOutlined,
  FolderOpenOutlined
} from "@ant-design/icons";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  DARK_LIVER,
  GRAY,
  GRAY_1600,
  GRAY_300,
  GRAY_500,
  GRAY_600,
  GRAY_700,
  GRAY_900,
  NEW_BLUE,
  PRIMARY_600,
  WHITE
} from "../constant/colors";
import {
  deletePatientAttachmentAPI,
  downloadPatientAttachmentAPI,
  getPatientAttachmentsAPI,
  uploadAttachmentAPI,
  uploadPatientAttachmentAPI
} from "../api/api";
import ErrorMessage from "./customComponent/customMessages/ErrorMessage";
import {
  CREATED_AT_ASCEND,
  CREATED_AT_DESCEND,
  FN,
  ORIGINAL_FILENAME_ASCEND,
  ORIGINAL_FILENAME_DESCEND,
  PHARMACY
} from "../constant/filters";
import { dateFormatter } from "../utils/date";
import SuccessMessage from "./customComponent/customMessages/SuccessMessage";
import download from "downloadjs";
import AttachmentPermissionsPopover from "./AttachmentPermissionsPopover";
import { ReactComponent as SecureUploadIcon } from "../assets/svg/secure-upload-icon-popup.svg";
import { ReactComponent as FileIcon } from "../assets/svg/file-icon.svg";
import { fontWeights, sizes } from "../constant/styles";
import { useDispatch, useSelector } from "react-redux";
import { selectShowSecureUpload } from "../store/selector";
import ACTIONS from "../store/action";
import { StyledTitleWithLine } from "./Patient/PatientApplications/PatientApplicationClaim/styles";

const { Option } = Select;
const { Text } = Typography;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 12px;
    width: 573px;
    height: 411px;
  }
  .ant-btn-default {
    padding: 10px 16px;
    gap: 8px;
    height: 44px;
    background: ${WHITE};
    border: 1px solid ${GRAY_300};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium_large};
    color: ${GRAY_700};
  }
  .ant-btn-primary {
    padding: 10px 16px;
    gap: 8px;
    height: 44px;
    background: ${PRIMARY_600};
    border: 1px solid ${PRIMARY_600};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.medium_large};
    color: ${WHITE};
    min-width: 100px;
    :disabled {
      :hover {
        color: ${WHITE};
        background: ${PRIMARY_600};
      }

      opacity: 0.3;
    }
  }

  .ant-modal-close-x {
    color: ${GRAY_900} !important;
  }

  #content {
    width: 100%;
  }

  #title-text {
    font-size: ${sizes.large};
    font-weight: ${fontWeights.semibold};
    color: ${GRAY_1600};
  }

  #sub-title-text {
    font-size: ${sizes.medium};
    font-weight: ${fontWeights.regular};
    color: ${GRAY_500};
  }

  #upload-container {
    button > span > svg {
      font-size: 20px;
    }
  }

  #uploader_file_size_text {
    color: ${GRAY_500};
  }

  #footer {
    position: absolute;
    bottom: 25px;
    right: 25px;

    .ant-btn-primary {
      width: 103px;
    }

    .ant-btn-default {
      width: 84px;
    }
  }
`;

const ContainerFileNameStyle = styled(Space)`
  svg {
    display: flex;
  }
  .ant-typography {
    font-size: ${sizes.medium};
    font-weight: ${fontWeights.bold};
    color: ${GRAY_700};
  }
`;

const UploadStyle = styled(Upload)`
  .ant-upload-list {
    height: 75px;
    overflow-y: auto;
    margin-top: 5px;
    padding-right: 3px;
  }

  .ant-upload.ant-upload-select {
    width: 100%;
  }
`;

export const MB = 1000000;
export const MAX_FILE_SIZE = 12 * MB;

// mapping from extension to MIME type
const fileTypeMappingExtensionToMime = {
  // Text files
  ".txt": "text/plain",

  // Images
  ".jpg": "image/jpeg",
  ".jpeg": "image/jpeg",
  ".png": "image/png",

  // Applications
  ".pdf": "application/pdf",
  ".doc": "application/msword",
  ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
};

const fileTypes = {
  XLSX: ".xlsx",
  XLS: ".xls",
  DOC: ".doc",
  DOCX: ".docx",
  PDF: ".pdf",
  JPEG: ".jpeg",
  JPG: ".jpg",
  PNG: ".png",
  CSV: ".csv",
  TIFF: ".tiff",
  PPT: ".ppt",
  PPTX: ".pptx"
};

const fileTypesAll = [
  fileTypes.XLS,
  fileTypes.XLSX,
  fileTypes.DOC,
  fileTypes.DOCX,
  fileTypes.PDF,
  fileTypes.JPG,
  fileTypes.JPEG,
  fileTypes.PNG,
  fileTypes.CSV,
  fileTypes.TIFF,
  fileTypes.PPT,
  fileTypes.PPTX
].join();

const isFileSizeValid = (file, maxSize) => file.size < maxSize;

const separateFiles = (fileList, maxSize) => {
  return fileList.reduce(
    (acc, file) => {
      if (isFileSizeValid(file, maxSize)) {
        acc.validFiles.push(file);
      } else {
        acc.invalidFiles.push(file);
      }
      return acc;
    },
    { validFiles: [], invalidFiles: [] }
  );
};

const handleFileUpload = (fileList, maxSize, errorMessage, setFileListFunc) => {
  const { validFiles, invalidFiles } = separateFiles(fileList, maxSize);
  invalidFiles.map((file) =>
    errorMessage(`File size extends ${MAX_FILE_SIZE / MB} MB max: ${(file.size / MB).toFixed(2)} MB`)
  );
  setFileListFunc(validFiles);
};

const customItemRender = (originNode, file, fileList, actions) => {
  return (
    <Space style={{ justifyContent: "space-between", width: "100%" }}>
      <ContainerFileNameStyle>
        <FileIcon />
        <Text>{file.name}</Text>
      </ContainerFileNameStyle>
      <Button onClick={() => actions.remove(file)} icon={<CloseOutlined style={{ color: GRAY_600 }} />} type="link" />
    </Space>
  );
};

const customAttachmentsItemRender = (originNode, file) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip title={file.name}>
        <span
          style={{
            maxWidth: "360px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }}
        >
          {originNode}
        </span>
      </Tooltip>
    </div>
  );
};

const UploaderComponent = ({
  getAttachmentsCount,
  formStyle,
  wrapperStyle,
  showTopLogo = true,
  isModalMode = true
}) => {
  const { t } = useTranslation();
  const { patientId } = useParams();
  const dispatch = useDispatch();

  const [fileList, setFileList] = useState([]);
  const [sort, setSort] = useState(CREATED_AT_DESCEND);
  const [showfilter, setShowfilter] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [canViewOrDownload, setCanViewOrDownload] = useState();

  const showSecureUpload = useSelector(selectShowSecureUpload);
  const closeSecureUploadModal = () => dispatch(ACTIONS.setShowSecureUpload(false));

  useEffect(() => {
    getAttachments(sort, showfilter);
  }, []);

  const getAttachments = async (sort, showfilter) => {
    if (!patientId) return;

    setIsFetching(true);
    try {
      const filterData = { sort, showfilter };
      const res = await getPatientAttachmentsAPI(patientId, filterData);
      if (res && res.data && res.data.data && res.data.data["phi-attachments"]) {
        setAttachments(res.data.data["phi-attachments"]);
        setCanViewOrDownload(res.data.canViewOrDownload);
        if (getAttachmentsCount) getAttachmentsCount();
      }
    } catch (error) {
      ErrorMessage(`Get attachments failed`);
    } finally {
      setIsFetching(false);
    }
  };

  const uploadFiles = async () => {
    setIsFetching(true);
    try {
      Promise.all(
        fileList.map(async (file) => {
          if (patientId) {
            return await uploadPatientAttachmentAPI(file, patientId);
          } else {
            return await uploadAttachmentAPI(file);
          }
        })
      ).then((attachmentsUploadRes) => {
        if (attachmentsUploadRes && attachmentsUploadRes[0]?.data?.status) {
          SuccessMessage(`File has been successfully uploaded`);
          getAttachments(sort, showfilter);
          setFileList([]);
          closeSecureUploadModal();
        } else if (
          attachmentsUploadRes &&
          attachmentsUploadRes[0] &&
          attachmentsUploadRes[0].data?.errors[0]?.message
        ) {
          ErrorMessage(`File upload failed: ${attachmentsUploadRes[0].data.errors[0].message}`);
          setIsFetching(false);
        } else if (attachmentsUploadRes && attachmentsUploadRes?.data?.errors[0]?.message) {
          ErrorMessage(`File upload failed: ${attachmentsUploadRes.data.errors[0].message}`);
          setIsFetching(false);
        } else {
          ErrorMessage(t("patientApplications.attachments.error.upload_generic"));
          setIsFetching(false);
        }
      });
    } catch (error) {
      ErrorMessage(t("patientApplications.attachments.error.upload_generic"));
      setIsFetching(false);
    } finally {
      if (!patientId) setIsFetching(false);
    }
  };

  const downloadAttachment = (attachment) => {
    setIsFetching(true);
    downloadPatientAttachmentAPI(attachment.id, attachment.attachmentUniqueId)
      .then((blob) => {
        const content = "application/pdf";
        download(blob, attachment.filename, content);
        setIsFetching(false);
      })
      .catch(() => {
        ErrorMessage(`Download attachment failed`);
        setIsFetching(false);
      });
  };

  const deleteAttachment = async (attachmentId, attachmentUniqueId) => {
    setIsFetching(true);
    try {
      const res = await deletePatientAttachmentAPI(attachmentId, attachmentUniqueId);
      if (res && res.data) {
        getAttachments(sort, showfilter);
      }
    } catch (error) {
      ErrorMessage(`Delete attachment failed`);
    } finally {
      setIsFetching(false);
    }
  };

  const uploadProps = {
    name: "file",
    multiple: true,
    accept: fileTypesAll,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (_, fileList) => handleFileUpload(fileList, MAX_FILE_SIZE, ErrorMessage, setFileList),
    fileList
  };

  const handleCloseModal = () => {
    closeSecureUploadModal();
    setFileList([]);
  };

  return (
    <>
      {isModalMode ? (
        <StyledModal
          open={showSecureUpload.visible}
          footer={false}
          onCancel={handleCloseModal}
          icon={<SecureUploadIcon />}
          centered
        >
          <Space direction="vertical" size={12} id="content">
            <SecureUploadIcon />
            <Text id="title-text">{t("uploader_secure_upload")}</Text>
            <Text id="sub-title-text">{t("uploader_form_title")}</Text>

            <UploadStyle {...uploadProps} itemRender={customItemRender}>
              <Space id="upload-container">
                <Button icon={<FolderOpenOutlined />}>{t("uploader_form_browse")}</Button>
                <Text id="uploader_file_size_text">{t("uploader_form_file_size")}</Text>
              </Space>
              {fileList.length > 0 && (
                <StyledTitleWithLine
                  style={{
                    fontWeight: fontWeights.semibold,
                    fontSize: sizes.small
                  }}
                >
                  {t("additional_attachments")}
                </StyledTitleWithLine>
              )}
            </UploadStyle>
          </Space>

          <Space id="footer">
            <Button onClick={handleCloseModal}>{t("cancel")}</Button>
            <Button type="primary" disabled={fileList.length === 0} loading={isFetching} onClick={uploadFiles}>
              {t("upload")}
            </Button>
          </Space>
        </StyledModal>
      ) : (
        <div>
          {(attachments.length > 0 || patientId) && (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Select
                  value={sort}
                  dropdownMatchSelectWidth={false}
                  style={{ fontWeight: 700 }}
                  bordered={false}
                  suffixIcon={<DownOutlined style={{ color: DARK_LIVER }} />}
                  onSelect={(value) => {
                    setSort(value);
                    getAttachments(value, showfilter);
                  }}
                >
                  <Option value={CREATED_AT_DESCEND}>{t("patientRSActions.by_date_nf")}</Option>
                  <Option value={CREATED_AT_ASCEND}>{t("patientRSActions.by_date_of")}</Option>
                  <Option value={ORIGINAL_FILENAME_ASCEND}>{t("patientRSActions.by_name_az")}</Option>
                  <Option value={ORIGINAL_FILENAME_DESCEND}>{t("patientRSActions.by_name_za")}</Option>
                </Select>
                <Select
                  value={showfilter}
                  dropdownMatchSelectWidth={false}
                  style={{ fontWeight: 700 }}
                  bordered={false}
                  suffixIcon={<DownOutlined style={{ color: DARK_LIVER }} />}
                  onSelect={(value) => {
                    setShowfilter(value);
                    getAttachments(sort, value);
                  }}
                >
                  <Option value={null}>{t("patientRSActions.all")}</Option>
                  <Option value={FN}>{t("patientRSActions.FN_attach")}</Option>
                  <Option value={PHARMACY}>{t("patientRSActions.Pharmacy_attach")}</Option>
                </Select>
              </div>
              <List
                style={{ padding: "0px 16px", width: "100%", overflowY: "auto", height: "210px" }}
                loading={isFetching}
                dataSource={attachments}
                renderItem={(attachment) => {
                  const { filename, updatedAt } = attachment;
                  return (
                    <AttachmentPermissionsPopover canViewOrDownload={canViewOrDownload}>
                      <List.Item>
                        <List.Item.Meta
                          avatar={<FileImageOutlined style={{ fontSize: "20px" }} />}
                          title={
                            <Row style={{ display: "flex", alignItems: "center" }}>
                              <Col span="20">{filename}</Col>
                              <Col span="2">
                                <Button
                                  type="text"
                                  disabled={!canViewOrDownload}
                                  icon={<DownloadOutlined />}
                                  onClick={() => downloadAttachment(attachment)}
                                />
                              </Col>
                              <Col span="2">
                                <Popconfirm
                                  overlayInnerStyle={{ padding: "0px 16px" }}
                                  placement="top"
                                  title={t("patientRSActions.message_confirm")}
                                  onConfirm={() => deleteAttachment(attachment.id, attachment.attachmentUniqueId)}
                                  disabled={!canViewOrDownload}
                                >
                                  <Button type="text" disabled={!canViewOrDownload} icon={<CloseOutlined />} />
                                </Popconfirm>
                              </Col>
                            </Row>
                          }
                          description={
                            <Row>
                              <Col span="22">
                                {t("patientRSActions.uploaded_at")} {dateFormatter(updatedAt)}
                              </Col>
                            </Row>
                          }
                        />
                      </List.Item>
                    </AttachmentPermissionsPopover>
                  );
                }}
              />
            </>
          )}
          <div style={wrapperStyle}>
            {showTopLogo && (
              <div
                style={{
                  width: "50%",
                  margin: "auto",
                  marginTop: "5rem",
                  padding: 16
                }}
              >
                <Text style={{ fontSize: "24px", fontWeight: "600", lineHeight: "24px", color: NEW_BLUE }}>
                  {t("uploader_secure_upload")}
                </Text>
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid rgba(34, 36, 38, 0.15)",
                borderRadius: "0.3rem",
                width: "50%",
                margin: "auto",
                padding: 16,
                boxShadow: "0 2px 14px 0 rgba(17, 34, 64, 0.22)",
                ...formStyle
              }}
            >
              <Text style={{ paddingBottom: 6 }}>{t("uploader_form_title")}</Text>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Upload {...uploadProps} itemRender={customAttachmentsItemRender}>
                  <Button icon={<FolderOpenOutlined style={{ fontSize: 16 }} />}>{t("uploader_form_browse")}</Button>
                  <Text style={{ color: GRAY, paddingLeft: 6 }}>{t("uploader_form_file_size")}</Text>
                </Upload>
                <Button type="primary" disabled={fileList.length === 0} loading={isFetching} onClick={uploadFiles}>
                  {"Upload"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Uploader = UploaderComponent;

export {
  isFileSizeValid,
  separateFiles,
  handleFileUpload,
  fileTypes,
  fileTypesAll,
  fileTypeMappingExtensionToMime,
  Uploader
};
