import { Row, Radio, Typography } from "antd";
import styled from "styled-components";

import { CustomForm } from "../../../customComponentNewDesign/CustomForm";
import {
  ERROR_300,
  ERROR_50,
  ERROR_600,
  GRAY_100,
  GRAY_300,
  GRAY_400,
  GRAY_500,
  GRAY_700,
  PRIMARY_50,
  PRIMARY_600,
  WHITE_GRAY
} from "../../../../constant/colors";
import { fontWeights, sizes } from "../../../../constant/styles";

const { Button: AntdRadioButton } = Radio;

const RadioButton = styled(AntdRadioButton).withConfig({
  shouldForwardProp: (prop) => !["showError"].includes(prop)
})`
  background: ${(props) => (props.showError ? ERROR_50 : null)};
  border: ${(props) => (props.showError ? `1px solid ${ERROR_300}` : null)};
  border-left: ${(props) => (props.showError ? `1px solid ${ERROR_300} !important` : `1px solid ${GRAY_300}`)};

  text-align: center;
  color: ${GRAY_400};
`;

const ExpressEnrollmentStyledForm = styled(CustomForm).attrs((props) => ({
  validateMessages: {
    required: ""
  },
  ...props
}))`
  padding: 0 1.5em 0 1.5em;
  .ant-picker {
    width: 100%;
  }

  .ant-input-number {
    width: 100%;
    color: ${GRAY_700};
  }

  .ant-input,
  .ant-select-selection-item {
    color: ${GRAY_700};
  }

  && .ant-form-item.ant-form-item-has-error {
    .ant-picker,
    .ant-radio-inner,
    .ant-input,
    .ant-select.ant-select-status-error .ant-select-selector,
    .ant-input-number,
    .ant-checkbox-inner {
      border-color: ${ERROR_300} !important;
      background: ${ERROR_50};
    }
  }

  .ant-input-number-group-wrapper-status-error .ant-input-number-group-addon {
    border-color: ${ERROR_300};
  }

  .ant-divider-inner-text {
    padding-left: 0;
  }

  .ant-form-item-explain-error {
    color: ${ERROR_600};
    font-weight: ${fontWeights.semibold};
    font-size: ${sizes.small};
  }

  .ant-form-item-label > label {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-radio-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  .ant-checkbox-wrapper {
    color: ${GRAY_700};
    font-weight: 600;
  }

  > .ant-row {
    align-items: flex-end;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${PRIMARY_600} !important;
    border-color: ${PRIMARY_600} !important;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: ${WHITE_GRAY} !important;
    border-color: ${GRAY_300} !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
    &::after {
      margin-left: 0px;
    }
  }
`;

const SidebarRow = styled(Row).withConfig({
  shouldForwardProp: (prop) => !["isActive"].includes(prop)
})`
  padding: 0.5em;
  margin: 0.5em 0;
  background: ${(props) => (props.isActive ? PRIMARY_50 : "")};
  border-radius: 0.5em;
  cursor: pointer;

  .ant-anchor-link {
    padding-left: 0px;
    width: 100%;
  }

  &:hover {
    background-color: ${GRAY_100};

    .sidebar-circle {
      background-color: white;
    }
  }
`;

const { Text } = Typography;

const FormDividerText = styled(Text)`
  color: ${GRAY_500};
  font-size: ${sizes.medium};
  font-weight: ${fontWeights.semibold};
`;

export { ExpressEnrollmentStyledForm, SidebarRow, RadioButton, FormDividerText };
