import React from "react";

import { PhysicianSelect } from "../../../../Select";
import { Form } from "antd";

export const PhysicanItem = ({
  name,
  label,
  required,
  rules = [],
  dropdownRender,
  onClear,
  onChange,
  isOpen,
  onDropdownVisibleChange,
  id,
  placeholder
}) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required }, ...rules]}>
      <PhysicianSelect
        onDropdownVisibleChange={onDropdownVisibleChange}
        open={isOpen}
        id={id}
        placeholder={placeholder}
        onChange={onChange}
        onClear={onClear}
        dropdownRender={dropdownRender}
      />
    </Form.Item>
  );
};
