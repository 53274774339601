import React from "react";
import { Col, Form, Row } from "antd";

import Sidebar from "../Patient/ApplicationFormEditor/sidebar";
import { GRAY_300 } from "../../constant/colors";
import { ApplicationForm } from "../Patient/ApplicationFormEditor/ApplicationForm";
import { HeaderSection } from "./HeaderSection";

const form_id = "online-wizard-form";

export const OnlinePreview = ({ sections, setJson }) => {
  const sideBarItems = Object.keys(sections);
  // TODO: remove the next line when real handle sectionsWithErrors
  sideBarItems?.shift();
  const normalizedSidebarItems = Object.entries(sections).map(([key, { href, title, icon }]) => ({
    key,
    href,
    title,
    icon
  }));

  const [form] = Form.useForm();
  const onErrors = (errors) => {
    console.log("errors", errors);
  };

  //TODO: handle sectionsWithErrors and activeFormSection
  return (
    <div>
      <Row style={{ minHeight: "75vh" }}>
        <Col span={4} style={{ paddingRight: "0.5em", borderRight: `1px solid ${GRAY_300}`, paddingTop: "1em" }}>
          <Sidebar
            sectionsWithErrors={sideBarItems}
            sidebarItems={normalizedSidebarItems}
            activeFormSection={`#${sections[sideBarItems[0]]?.href}`}
            formId={form_id}
            disabled={false}
          />
        </Col>
        <Col span={20}>
          <ApplicationForm form={form} onErrors={onErrors} formId={form_id}>
            {<HeaderSection sections={sections} setJson={setJson} />}
          </ApplicationForm>
        </Col>
      </Row>
    </div>
  );
};
