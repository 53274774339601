import React from "react";
import styled from "styled-components";

import { font12pxR } from "../../../../Typography";
import { GRAY_400 } from "../../../../../constant/colors";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";

const { Text } = Typography;

const TextStyled = styled(Text)`
  ${font12pxR}
  color: ${GRAY_400};
  display: flex;
`;

export const DetailsSaved = () => {
  const { t } = useTranslation();

  return (
    <TextStyled style={{ display: "flex", marginTop: "20px" }}>
      {t("patientApplications.enroll.enroll_result.details_saved")}
    </TextStyled>
  );
};
