import React from "react";
import { Col, Divider, Input, Row, Form, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { patientFields } from "../fieldNames";
import { GRAY_500 } from "../../../../../../../constant/colors";
import { emailInputRules, onlyWhitespacesRule, zipInputRules } from "../../../../utils";
import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { DobFormItem, FormDividerText, FormItem, InputPhoneWithPhoneTypeAddon } from "../../../../components";
import { SingleSelect } from "../../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../../constant/states";
import { GenderFormItem } from "../../../../GenderFormItem";

const { Option } = Select;

export default function GenentechCopayPatient() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const gender = Form.useWatch(patientFields.gender, form);

  return (
    <>
      <div id="enrollment-form-patient-information" />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
        <FormDividerText>{t("application_form_editor.genentech.copay.patient.header")}</FormDividerText>
      </Divider>

      <Form.Item name={patientFields.id} hidden />

      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={patientFields.firstName}
            label={t("application_form_editor.genentech.copay.patient.labels.first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.genentech.copay.patient.placeholders.first_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.lastName}
            label={t("application_form_editor.genentech.copay.patient.labels.last_name")}
          >
            <Input placeholder={t("application_form_editor.genentech.copay.patient.placeholders.last_name")} />
          </FormItem>
        </Col>
        <Col span={12}>
          <GenderFormItem
            name={patientFields.gender}
            label={t("application_form_editor.genentech.copay.patient.labels.gender")}
            showError={!gender}
          />
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <DobFormItem
            required
            name={patientFields.dob}
            label={t("application_form_editor.genentech.copay.patient.labels.dob")}
          />
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.address}
            label={t("application_form_editor.genentech.copay.patient.labels.street_address")}
          >
            <Input placeholder={t("application_form_editor.genentech.copay.patient.placeholders.street_address")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={patientFields.city}
            label={t("application_form_editor.genentech.copay.patient.labels.city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.genentech.copay.patient.placeholders.city")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            name={patientFields.state}
            label={t("application_form_editor.genentech.copay.patient.labels.state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.genentech.copay.patient.placeholders.state")}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={patientFields.zip}
            label={t("application_form_editor.genentech.copay.patient.labels.zip_code")}
          >
            <Input
              maxLength={5}
              placeholder={t("application_form_editor.genentech.copay.patient.placeholders.zip_code")}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={emailInputRules}
            name={patientFields.email}
            label={t("application_form_editor.genentech.copay.patient.labels.email")}
          >
            <Input
              addonBefore={<MailOutlined />}
              placeholder={t("application_form_editor.genentech.copay.patient.placeholders.email")}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={patientFields.phone}
            label={t("application_form_editor.genentech.copay.patient.labels.phone_number")}
          >
            <InputPhoneWithPhoneTypeAddon
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.genentech.copay.patient.placeholders.phone_number")}
              typeName={patientFields.phoneType}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
