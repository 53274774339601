import { Col, Form, Input, Radio, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FormItem } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { prescriptionFields } from "../../fieldNames";

const { TextArea } = Input;

export const PatientInformation = () => {
  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const allergies = Form.useWatch(prescriptionFields.allergies, form);

  useEffect(() => {
    form.validateFields();
  }, [allergies]);

  return (
    <>
      <Row gutter={14} wrap={false}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={prescriptionFields.concomitant}
            label={t("application_form_editor.pfizer.prescription.labels.concomitant_meds")}
          >
            <Input placeholder={t("application_form_editor.pfizer.prescription.placeholders.concomitant_meds")} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14} wrap={false}>
        <Col span={24}>
          <FormItem
            rules={[{ required: true }]}
            name={prescriptionFields.allergies}
            label={t("application_form_editor.pfizer.prescription.labels.allergies")}
          >
            <Radio.Group>
              <Radio value={true}>{t("application_form_editor.pfizer.prescription.yes")}</Radio>
              <Radio value={false}>{t("application_form_editor.pfizer.prescription.no")}</Radio>
            </Radio.Group>
          </FormItem>
        </Col>
      </Row>
      {allergies && (
        <Row gutter={14} wrap={false}>
          <Col span={24}>
            <FormItem
              rules={[{ required: true }, onlyWhitespacesRule]}
              name={prescriptionFields.allergiesDetails}
              label={t("application_form_editor.pfizer.prescription.labels.allergy_details")}
            >
              <TextArea
                autoSize={{ minRows: 3 }}
                placeholder={t("application_form_editor.pfizer.prescription.placeholders.allergy_details")}
              />
            </FormItem>
          </Col>
        </Row>
      )}
    </>
  );
};
