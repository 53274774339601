import { createSelector } from "reselect";
import moment from "moment-timezone";
import _ from "lodash";

import { defaultFilters } from "../constant/filters";
import { selectedViews } from "../constant/table";
import { deepEqual } from "../utils/misc";
import { priorities } from "../constant/assistanceProgram";
import { stringSort } from "../utils/sort";
import { SORT_DIRECTIONS } from "../constant/sort";
import { APPLICATIONS } from "../constant/applications";
import { tableColumns as allViewsColumns } from "../constant/tableColumns";
import { parseSelectedColumns } from "../utils/columns";
import { getApplicationDisplayName } from "../utils/applications";
import { ApplicationFormSignatureStatuses, SignatureTypes, SignerTypes } from "../constant/ESign";
import { ENROLLMENT_SIGNATURES_STATUSES } from "../constant/applicationFormSignatureEnrollment";
import { eligibleUserRoles as ALLOWED_USER_ROLES_FOR_ENROLLMENT_ACTIONS } from "../constant/roles";
import { DATE_DATA_FORMAT } from "../utils/date";

const selectState = (state) => state;

export const selectNetworkActions = createSelector(selectState, (state) => state && state.networkActions);

export const selectIsFetching = (actions) =>
  createSelector(selectNetworkActions, (networkActions) => {
    const pendingNetworkActions = Object.keys(networkActions)
      .filter((actionName) => networkActions[actionName].isFetching)
      .filter((action) => actions.indexOf(action) >= 0);

    return pendingNetworkActions.length > 0;
  });

export const selectNetworkActionsError = (actions) =>
  createSelector(selectNetworkActions, (networkActions) => {
    const pendingNetworkActions = Object.keys(networkActions)
      .filter((actionType) => actions.includes(actionType))
      .reduce((obj, key) => {
        obj[key] = networkActions[key];
        return obj;
      }, {});

    const fetchingNetworkAction = Object.values(pendingNetworkActions).filter(
      (networkAction) => !networkAction.isFetching && (networkAction.msg || networkAction.code)
    );

    return fetchingNetworkAction;
  });

export const selectEnvironment = createSelector(selectState, (state) => state && state.environment);

export const selectTier = createSelector(selectState, (state) => state && state.tier);

export const selectSearchedPatients = createSelector(selectState, (state) => state && state.searchedPatients);

export const selectUser = createSelector(selectState, (state) => state && state.user);

export const selectUnits = createSelector(selectState, (state) => state && state.units);

export const selectFilterList = createSelector(selectState, (state) => state && state.filterList);

export const selectIsSidebarCollapsed = createSelector(selectState, (state) => state && state.isSidebarCollapsed);

export const selectShouldReturnToRelevantPatient = createSelector(
  selectState,
  (state) => state && state.shouldReturnToRelevantPatient
);

export const selectFilterListStatuses = createSelector(selectState, (state) => state && state.filterListStatuses);

export const selectApplicationClaims = createSelector(selectState, (state) => state && state.applicationClaims);

export const selectApplicationClaimsFilter = createSelector(
  selectState,
  (state) => state && state.filterByClaimsStatus
);

export const selectCurrentClaimDetails = (claimId) =>
  createSelector(selectApplicationClaims, (applicationClaims) => {
    return applicationClaims?.claims?.find((item) => item.id === claimId);
  });

export const selectPatientInactiveAndUnknownInsuranceInfo = createSelector(
  selectState,
  (state) => state && state.unknownAndInactiveInsuranceInfo
);

export const selectVisitTypes = createSelector(selectState, (state) => state && state.visitType);

export const selectUserGroups = createSelector(selectState, (state) => state && state.userGroups);

export const selectAssignees = createSelector(selectState, (state) => state && state.assignees);

export const selectedSavedListFilters = createSelector(selectState, (state) => state && state.savedListFilters);
export const selectedFilterListQuery = createSelector(selectedSavedListFilters, (filters) => filters && filters.query);
export const selectedFilterListType = createSelector(
  selectedSavedListFilters,
  (filters) => filters && filters.filterBy
);
const selectedSavedListSortingStatues = createSelector(
  selectedSavedListFilters,
  (filters) => filters && filters.sortingStatuses
);
export const selectedFilterSorting = createSelector(
  selectedSavedListSortingStatues,
  selectedFilterListType,
  (sortingStatuses, listType) => sortingStatuses && listType && sortingStatuses[listType]
);

export const selectedSavedListActiveColumn = createSelector(
  selectedFilterSorting,
  (selectedFilterSorting) =>
    selectedFilterSorting && {
      name: selectedFilterSorting.orderBy,
      sortBy: selectedFilterSorting[selectedFilterSorting.orderBy]
    }
);

export const selectAssigneesAndUserGroups = createSelector(
  [selectAssignees, selectUserGroups],
  (assignees, userGroups) => {
    const ASSIGNEE_TYPE = 1;
    const USER_GROUP_TYPE = 2;

    if ((!assignees || !Object.values(assignees).length) && (!userGroups || !Object.values(userGroups).length))
      return [];
    const parsedAssignees = Object.values(assignees);
    parsedAssignees.forEach((assignee) => {
      assignee.displayName = `${assignee.lastName} ${assignee.firstName}`;
      assignee.assigneeType = ASSIGNEE_TYPE;
      assignee.type = "user";
    });

    const parsedUserGroups = Object.values(userGroups);
    parsedUserGroups.forEach((userGroup) => {
      userGroup.displayName = userGroup.name;
      userGroup.assigneeType = USER_GROUP_TYPE;
      userGroup.type = "user-group";
    });

    const assigneesAndUserGroups = parsedAssignees
      .concat(parsedUserGroups)
      .sort((itemA, itemB) => stringSort(itemA.displayName, itemB.displayName, SORT_DIRECTIONS));

    return assigneesAndUserGroups;
  }
);

export const selectUserId = createSelector(selectUser, (user) => user && user.id);

export const selectSelectedView = createSelector(selectState, (state) => state && state.selectedView);

export const selectDashboardCounters = createSelector(selectState, (state) => state && state.dashboardCounters);

export const selectAssistancePrograms = createSelector(selectState, (state) => state && state.assistancePrograms);

export const selectTableColumns = createSelector(selectState, (state) => state && state.tableColumns);

const filterSelectedColumns = (tableColumns, selectedView) => {
  const userSelectedColumns = [];

  tableColumns &&
    selectedView &&
    Object.values(selectedView).forEach((value) => {
      value.display && !value.mandatory ? userSelectedColumns.push(value.key) : "";
    });
  return userSelectedColumns;
};

export const getUserSelectedColumns = createSelector(
  selectTableColumns,
  selectSelectedView,
  (tableColumns, selectedView) => filterSelectedColumns(tableColumns, tableColumns[selectedView])
);

export const getFilterListSelectedColumns = createSelector(
  selectTableColumns,
  selectFilterList,
  (tableColumns, filtersList) => filterSelectedColumns(tableColumns, tableColumns[filtersList?.selectedView])
);

export const selectTableColumnsModified = createSelector(
  selectTableColumns,
  selectFilterList,
  (tableColumns, filtersList) => {
    const newTableColumns = JSON.parse(JSON.stringify(allViewsColumns));
    filtersList?.params?.selectedColumns &&
      tableColumns &&
      parseSelectedColumns(newTableColumns, filtersList.selectedView, filtersList.params.selectedColumns);
    return !deepEqual(newTableColumns, tableColumns);
  }
);

export const selectSelectedAssistanceProgramById = createSelector(
  selectState,
  (state) => state && state.selectedAssistanceProgramById
);

export const selectAssistanceProgramDrugs = createSelector(
  selectSelectedAssistanceProgramById,
  (assistanceProgram) => assistanceProgram?.rxnormDrugs
);

export const selectShowAssistanceProgramModal = createSelector(
  selectState,
  (state) => state && state.showAssistanceProgramModal
);

export const selectShowExpressEnrollModal = createSelector(
  selectState,
  (state) => state && state.showExpressEnrollModal
);

export const selectShowSupport = createSelector(selectState, (state) => state && state.showSupport);

export const selectShowSecureUpload = createSelector(selectState, (state) => state && state.showSecureUpload);

export const selectIsPhiAllowed = createSelector(selectState, (state) => state && state.isPhiAllowed);

export const selectSettings = createSelector(selectState, (state) => state && state.settings);

export const selectFilters = createSelector(selectState, (state) => state && state.filters);

export const selectIsFilterListFiltersModified = createSelector(
  selectFilters,
  selectFilterList,
  (filters, filtersList) => filters && filtersList?.params?.filters && !deepEqual(filters, filtersList.params.filters)
);

export const selectFiltersInputs = createSelector(selectState, (state) => state && state.filtersInputs);

export const selectTablesData = createSelector(selectState, (state) => state && state.tablesData);

export const selectViewTablesData = (view) =>
  createSelector(selectState, (state) => (state && state.tablesData[view]) || {});

export const selectedViewTableData = createSelector(
  selectTablesData,
  selectSelectedView,
  (tablesData, selectedView) => (tablesData && selectedView && tablesData[selectedView]) || {}
);

export const selectAllPatientsData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.allPatients]
);

export const selectAllApplicationsData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.allApplications]
);

export const selectAllClaimsData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.allClaims]
);

export const selectVisitScheduleData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.visitSchedule]
);

export const selectPendingRenewalData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.pendingRenewal]
);

export const selectRelevantPatientData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.topOpportunity]
);

export const selectHighPrioritiesData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.highPriority]
);

export const selectSavedListData = createSelector(
  selectState,
  (state) => state && state.tablesData[selectedViews.savedList]
);

export const selectIsSavedListInit = createSelector(selectState, (state) => state && state.isSavedListinitialized);

export const selectPatient = createSelector(selectState, (state) => state && state.patient);

export const selectOOPEstimations = createSelector(selectState, (state) => state && state.oopEstimations);

export const selectFormItemsDiagnoses = createSelector(selectState, (state) => state && state.formItemsDiagnoses);

export const selectPatientDetails = createSelector(selectPatient, (patient) => patient.details && patient.details[0]);

export const selectPatientDetailsFpl = createSelector(
  selectPatient,
  (patient) => patient.details && patient.details[0]?.fpl
);

export const selectProvidersFacility = createSelector(
  selectState,
  (state) => state && state.providers && state.providers.facility
);

export const selectProvidersDoctors = createSelector(
  (state) => state.providers.doctor,
  (doctors) => doctors?.map(({ stateLisence, ...doctor }) => ({ ...doctor, stateLicense: stateLisence }))
);

export const selectAPSponsors = createSelector(selectState, (state) => state && state.APSponsors);

export const selectUsers = createSelector(selectState, (state) => state && state.users);

export const selectFormPopups = createSelector(selectState, (state) => state && state.formPopups);

export const selectFiltersModified = createSelector(selectFilters, (filters) => !deepEqual(filters, defaultFilters));

export const selectSummaryCounters = createSelector(selectState, (state) => state && state.summaryCounters);

export const selectPatientJourney = createSelector(
  selectPatient,
  (patient) => (patient?.journey && patient?.journey[0]) || undefined
);

export const selectPatientJourneyEvents = createSelector(selectPatientJourney, (patientJourney) => {
  if (!patientJourney) return [];
  return patientJourney.events.filter((event) => {
    const isEventHidden = event?.drugs && event.drugs[0] && event.drugs[0].isHidden;
    return event.chapter === "treatment" && !isEventHidden;
  });
});

export const selectActiveDrugs = createSelector(selectPatientJourneyEvents, (journeyEvents) => {
  if (!journeyEvents) return [];

  const activeDrugEvents = journeyEvents?.map(({ drugEntries }) => {
    if (!drugEntries?.length) return [];

    const relevantDrugs =
      drugEntries?.filter((drugEntry) => {
        const relevantDates = [];
        const { numberOfCycles, daysBetweenCycles, cycle, numberOfRefillsAuthorized, date } = drugEntry;
        const cyclesAmount = numberOfCycles || numberOfRefillsAuthorized || 1;

        if (cyclesAmount === 1) {
          return moment(date).isBetween(moment().subtract(6, "month"), moment().add(6, "month"));
        }

        relevantDates.push(moment(date));
        for (let i = cycle + 1; i <= cyclesAmount; i++) {
          relevantDates.push(moment(date).add(daysBetweenCycles, "d"));
        }

        const isDateWithinHalfYearRange = relevantDates.find((date) =>
          moment(date).isBetween(moment().subtract(6, "month"), moment().add(6, "month"))
        );
        return isDateWithinHalfYearRange;
      }) || [];
    return relevantDrugs;
  });

  const activeDrugsEventsFiltered = _.flatten(activeDrugEvents);
  const activeDrugs = activeDrugsEventsFiltered.map(({ rxnormDrug, genericDrug }) => {
    const { bnName, pinName } = rxnormDrug;
    const pinDisplayName = pinName.split("|").join(" + ");

    return {
      ...rxnormDrug,
      hcpcs: genericDrug.hcpcs,
      label: bnName ? `${bnName} (${pinDisplayName})` : pinDisplayName
    };
  });

  const uniqActiveDrugs = _.uniqBy(activeDrugs, "id");

  return uniqActiveDrugs;
});

export const selectGenericServices = createSelector(selectPatient, (patient) => patient && patient.genericServices);

export const selectedJourneyEvent = createSelector(selectPatient, (patient) => patient && patient.selectedJourneyEvent);

export const selectCalculatedDrugEntries = createSelector(selectedJourneyEvent, (journeyEvent) => {
  if (!journeyEvent?.drugEntries?.length) return [];

  let billableEventData;
  let calculatedDrugEntries = [];
  let billableEventsIndex = 0;

  journeyEvent.drugEntries?.forEach((drugEntry) => {
    const cyclesAmount = drugEntry.numberOfCycles || drugEntry.numberOfRefillsAuthorized || 1;

    for (let cycleIndex = 0; cycleIndex < cyclesAmount; cycleIndex++) {
      let calculatedDate = drugEntry.date;

      billableEventData =
        (journeyEvent.billableEventsDisplay && journeyEvent?.billableEventsDisplay[billableEventsIndex]) || {};

      if (cycleIndex > 0 && drugEntry.date) {
        calculatedDate = moment(drugEntry.date)
          .add(drugEntry.daysBetweenCycles * cycleIndex, "d")
          .toISOString();
      }

      const entry = {
        ...drugEntry,
        ...billableEventData,
        date: calculatedDate,
        drugName: drugEntry.rxnormDrug?.tmDisplayName
      };

      calculatedDrugEntries.push(entry);
      billableEventsIndex++;
    }
  });
  return calculatedDrugEntries;
});

export const selectPatientPotentialSavings = createSelector([selectPatient, selectSettings], (patient) => {
  const prioritiesTypes = priorities;
  const prioritiesTypesValues = Object.values(prioritiesTypes).map((p) => p.value);

  const filteredPotentialSavings = patient.potentialSavings.filter(
    (p) => prioritiesTypesValues.includes(p.priority) || p.internal === true
  );
  return filteredPotentialSavings;
});

export const selectPotentialSavings = createSelector(
  [selectPatientPotentialSavings, selectPatientJourney],
  (potentialSavings, journey) => {
    const isPapNotDiscarded = (pap) =>
      !pap.applications?.some(({ status }) => {
        return status === "discarded";
      });
    if (!potentialSavings || !journey) return [];

    const { allPotentialSavingsGroupedByType } = journey;
    const allPotentialSavingsGroupedByTypeMerged = _.flatten(Object.values(allPotentialSavingsGroupedByType));
    return potentialSavings.filter(isPapNotDiscarded).map((potentialSaving) => {
      const potentialSavingExtraData = allPotentialSavingsGroupedByTypeMerged.find(
        (p) => p.potentialSavingId === potentialSaving.id
      );

      const { displayName, drugName } = getPotentialSavingDisplayName(potentialSaving);
      return {
        ...potentialSavingExtraData,
        ...potentialSaving,
        displayName,
        drugDisplayName: drugName
      };
    });
  }
);

export const selectApplicationAttachmentsData = createSelector(
  selectPatient,
  (patient) => patient?.applicationAttachments
);

export const selectPatientApplications = createSelector(selectPotentialSavings, (potentialSavings) => {
  const activeApplications = [];
  const inActiveApplications = [];
  if (potentialSavings) {
    potentialSavings.forEach((potentialSaving) => {
      potentialSaving.applications.forEach((application) => {
        const applicationData = {
          ...application,
          displayName: getApplicationDisplayName({
            associatedDrugs: application.associatedDrugs,
            potentialSaving,
            displayName: potentialSaving.displayName
          }),
          drugDisplayName: potentialSaving.drugDisplayName,
          potentialSaving
        };
        const isActive =
          [
            APPLICATIONS.statuses.prepare,
            APPLICATIONS.statuses.applied,
            APPLICATIONS.statuses.appliedToWaitlist,
            APPLICATIONS.statuses.missingInformation,
            APPLICATIONS.statuses.temporarilyApproved
          ].includes(application.status) ||
          (application.status === APPLICATIONS.statuses.approved &&
            !moment(application.validThrough).isBefore(moment(DATE_DATA_FORMAT)));
        if (isActive) {
          activeApplications.push(Object.assign(applicationData, { active: true }));
        } else {
          inActiveApplications.push(Object.assign(applicationData, { active: false }));
        }
      });
    });
  }

  return { activeApplications, inActiveApplications };
});

export const selectPatientOpportunity = createSelector(selectPatient, (patient) => patient && patient.opportunity);

export const selectApplicationForm = createSelector(selectPatient, (patient) => patient && patient.applicationForm);

export const selectApplicationFormSignerDetails = createSelector(selectApplicationForm, (applicationForm) => {
  let signerDetails = {};

  const patientDetails = _.get(applicationForm, "formData.patient");

  if (patientDetails) {
    signerDetails[SignerTypes.PATIENT] = {
      name:
        patientDetails.firstName || patientDetails.lastName
          ? `${patientDetails.firstName ?? ""} ${patientDetails.lastName ?? ""}`
          : null,
      phone: patientDetails.phone?.toString(),
      email: patientDetails.email,
      signerType: SignerTypes.PATIENT
    };
  }

  const caregiverDetails = _.get(applicationForm, "formData.patient.caregiver");

  // when application form wasn't filled yet, we get the caregiver details from the patient object (mpd)
  // we want to make sure that the caregiver details were filled in EE so we check the name as well
  if (caregiverDetails && caregiverDetails.firstName && caregiverDetails.lastName && caregiverDetails.dob) {
    signerDetails[SignerTypes.CAREGIVER] = {
      name: `${caregiverDetails.firstName ?? ""} ${caregiverDetails.lastName ?? ""}`.trim(),
      phone: caregiverDetails.phone?.toString(),
      email: caregiverDetails.email,
      signerType: SignerTypes.CAREGIVER
    };
  }
  return signerDetails;
});

export const selectExpressEnrollmentConfiguration = createSelector(
  selectPatient,
  (patient) => patient && patient.expressEnrollmentConfiguration
);

export const selectPotentialSavingsJourney = createSelector(
  selectPatient,
  (patient) => patient && patient.potentialSavingsJourney
);

export const selectDrugs = createSelector(selectState, (state) => state && state.drugs);
export const selectCurrentApplication = createSelector(selectState, (state) => state && state.currentApplication);

export const selectAssociatedDrugs = createSelector(selectPatientJourney, (patientJourney) => {
  if (!patientJourney) {
    return;
  }

  if (patientJourney && patientJourney) {
    const patientEvents = patientJourney.events;
    let associatedDrugs = [];
    patientEvents.map((event) => {
      const drugList = event.drugs || [];

      associatedDrugs.push(...drugList);
    });
    return associatedDrugs;
  }
});

export const selectPatientCoverages = createSelector(selectPatient, (patient) => patient && patient.coverages);

export const selectPatientPolicies = createSelector(selectPatient, (patient) => patient && patient.policies);

export const selectPatientShippingOptions = createSelector(
  selectState,
  (state) => state.patient && state.patient.shippingOptions
);

// export const selectSmartEditorFields = createSelector([selectPatientDetails], (patientDetails) => {
//   return {};
// });
export const isCopyPastePopoverOpen = createSelector(selectState, (state) => state && state.isCopyPastePopoverOpen);

export const isExpressEnrollFormOpen = createSelector(selectState, (state) => state && state.isExpressEnrollFormOpen);

function getPotentialSavingDisplayName(potentialSaving) {
  const { name, priority } = potentialSaving;
  let displayName = name;
  let drugName;

  if ([priorities.COPAY_CARD.value, priorities.FREE_DRUGS.value].includes(priority)) {
    drugName = potentialSaving.drugDisplayName;
  } else if ([priorities.COPAY.value].includes(priority)) {
    const currentApplication = potentialSaving.applications.find(
      (app) => app.id === potentialSaving.currentApplication
    );

    if (currentApplication && currentApplication.associatedDrugs && currentApplication.associatedDrugs.length) {
      const drugsNames = currentApplication.associatedDrugs.join(", ");
      drugName = drugsNames;
    }
  }

  if (drugName) {
    displayName = `${name} (${drugName})`;
  }
  return { displayName, drugName };
}

export const selectIDPConfig = createSelector(selectState, (state) => state && state.idpConfig);
export const selectOktaClientId = createSelector(selectState, (state) => state && state.oktaClientId);

// Enrollment Store Selector
const selectEnrollmentStore = createSelector(selectState, (state) => state.enrollment || {});
export const selectIsUpdatingFile = createSelector(
  selectEnrollmentStore,
  (enrollment) => enrollment.isUpdatingFile || false
);

export const selectPdfMetaData = createSelector(selectEnrollmentStore, (enrollment) => enrollment.pdfMetaData || {});

export const selectWetRequiredSignerType = createSelector(
  selectPdfMetaData,
  ({ isPartiallyWetSignPdf, requiredSigners = [] }) =>
    isPartiallyWetSignPdf && requiredSigners.find((signer) => signer.isWetSignature)?.signerType
);

export const selectApplicationFormSignatures = createSelector([selectEnrollmentStore], (enrollment) => {
  return enrollment.applicationFormSignatures || [];
});

export const selectApplicationFormSignatureByRequiredSignerType = (requiredSignerType) =>
  createSelector(
    [selectApplicationFormSignatures],
    (signatures) => signatures.find((signature) => signature.requiredSignerType === requiredSignerType) || {}
  );

export const selectIsWetSignerSigned = createSelector(
  [selectWetRequiredSignerType, selectApplicationFormSignatures],
  (wetRequiredSignerType, applicationFormSignatures) =>
    applicationFormSignatures.some(
      (signature) =>
        signature.requiredSignerType === wetRequiredSignerType &&
        signature.type === SignatureTypes.WET &&
        signature.status === ApplicationFormSignatureStatuses.MANUALLY_SIGNED
    )
);

export const selectEnrollmentSignatureStatus = createSelector(
  selectApplicationFormSignatures,
  (applicationFormSignatures) => {
    // @var hasNotSignedRequest - Check if there is at least one signer that has NOT signed the request
    // @var hasSignedRequest - Check if there is at least one signer that has signed the request
    const hasNotSignedRequest = applicationFormSignatures?.some((signature) =>
      [ApplicationFormSignatureStatuses.PENDING, ApplicationFormSignatureStatuses.EXPIRED].includes(signature.status)
    );
    const hasSignedRequest = applicationFormSignatures?.some(
      (signature) => signature.status === ApplicationFormSignatureStatuses.SIGNED
    );

    if (hasNotSignedRequest) {
      return ENROLLMENT_SIGNATURES_STATUSES.PENDING;
    }

    if (hasSignedRequest) {
      return ENROLLMENT_SIGNATURES_STATUSES.SIGNED;
    }

    return "";
  }
);

export const selectRemoteSignatureRequests = createSelector(
  selectApplicationFormSignatures,
  (applicationFormSignatures) => _.compact(applicationFormSignatures.map((sig) => sig.phiRemoteSignatureRequest)) || []
);

export const selectRemoteRequestErrorMessage = createSelector(
  selectEnrollmentStore,
  (enrollment) => enrollment.remoteRequestErrorMessage || {}
);

export const selectIsUserEligibleForEnrollmentActions = createSelector(selectUser, (user) =>
  ALLOWED_USER_ROLES_FOR_ENROLLMENT_ACTIONS.includes(user.role)
);

export const selectIsApplicationFormSigned = createSelector(
  [selectApplicationFormSignatures, selectApplicationForm],
  (applicationFormSignatures, applicationForm) => {
    return (
      applicationForm?.status === ApplicationFormSignatureStatuses.SIGNED ||
      (applicationFormSignatures?.length > 0 &&
        applicationFormSignatures.every((signature) =>
          [ApplicationFormSignatureStatuses.SIGNED, ApplicationFormSignatureStatuses.MANUALLY_SIGNED].includes(
            signature.status
          )
        ))
    );
  }
);

export const selectIsAllDigitalRequiredSignersSigned = createSelector(
  [selectApplicationFormSignatures, selectWetRequiredSignerType],
  (applicationFormSignatures, wetRequiredSignerType) => {
    const nonWetSignatures = applicationFormSignatures.filter(
      (signature) => wetRequiredSignerType !== signature.requiredSignerType
    );

    return (
      nonWetSignatures.length > 0 &&
      nonWetSignatures.every((signature) => signature.status === ApplicationFormSignatureStatuses.SIGNED)
    );
  }
);

export const selectLoadingWidgets = createSelector(selectEnrollmentStore, (enrollment) => enrollment.loadingWidgets);

export const selectIsApplicationFormModalVisible = createSelector(
  selectEnrollmentStore,
  (enrollment) => enrollment.isApplicationFormModalVisible
);

export const selectBadgeCounts = createSelector(selectState, (state) => state && state.badgeCounts);
