const patientFields = {
  firstName: ["patient", "firstName"],
  lastName: ["patient", "lastName"],
  dob: ["patient", "dob"],
  gender: ["patient", "gender"],
  address: ["patient", "address"],
  city: ["patient", "city"],
  state: ["patient", "state"],
  zip: ["patient", "zip"],
  email: ["patient", "email"],
  phone: ["patient", "phone"],
  phoneType: ["patient", "phoneType"],
  caregiver: ["patient", "hasCaregiver"],
  caregiverFirstName: ["patient", "caregiver", "firstName"],
  caregiverLastName: ["patient", "caregiver", "lastName"],
  caregiverDob: ["patient", "caregiver", "dob"],
  caregiverGender: ["patient", "caregiver", "gender"],
  caregiverAddress: ["patient", "caregiver", "address"],
  caregiverCity: ["patient", "caregiver", "city"],
  caregiverState: ["patient", "caregiver", "state"],
  caregiverZip: ["patient", "caregiver", "zip"],
  caregiverEmail: ["patient", "caregiver", "email"],
  caregiverPhone: ["patient", "caregiver", "phone"],
  caregiverPhoneType: ["patient", "caregiver", "phoneType"]
};
const consentFields = {
  programEligibilityCriteria: ["consent", "programEligibilityCriteria"],
  privacyAuthorization: ["consent", "privacyAuthorization"],
  marketingCommunication: ["consent", "marketingCommunication"]
};

export { patientFields, consentFields };
