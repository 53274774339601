import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { useTranslation } from "react-i18next";

import { insuranceFields } from "../../fieldNames";
import { FormItem, InputPhone } from "../../../../components";
import { onlyWhitespacesRule } from "../../../../utils";
import { InsuranceTypeFormItem, pfizerPapPlanMarkets } from "./InsuranceTypeFormItem";
import { phoneNumberLengthRegEx } from "../../../../../../../utils/formValidation";
import { PolicyholderDetails } from "./PolicyholderDetails";
import { PRIMARY_INSURANCE } from "./Insurance";

export const PrescriptionInsuranceDetails = ({ type }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const relevantInsuranceType =
    type === PRIMARY_INSURANCE ? insuranceFields.primaryPrescriptionType : insuranceFields.secondaryPrescriptionType;

  const insuranceType = Form.useWatch(relevantInsuranceType, form);

  const prescriptionPolicyholderSameAsPatient =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionPolicyholderSameAsPatient
      : insuranceFields.secondaryPrescriptionPolicyholderSameAsPatient;

  const prescriptionPolicyholderName =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionPolicyholderName
      : insuranceFields.secondaryPrescriptionPolicyholderName;

  const prescriptionPayerName =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionPayerName
      : insuranceFields.secondaryPrescriptionName;

  const prescriptionGroupId =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionGroupId
      : insuranceFields.secondaryPrescriptionGroup;

  const prescriptionMemberId =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionMemberId
      : insuranceFields.secondaryPrescriptionMember;

  const prescriptionPCN =
    type === PRIMARY_INSURANCE ? insuranceFields.primaryPrescriptionPCN : insuranceFields.secondaryPrescriptionPCN;

  const prescriptionBIN =
    type === PRIMARY_INSURANCE ? insuranceFields.primaryPrescriptionBIN : insuranceFields.secondaryPrescriptionBIN;

  const prescriptionPhoneNumber =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionPhoneNumber
      : insuranceFields.secondaryPrescriptionPhone;

  const otherInsuranceTypeField =
    type === PRIMARY_INSURANCE
      ? insuranceFields.primaryPrescriptionOtherTypeName
      : insuranceFields.secondaryPrescriptionOtherTypeName;

  useEffect(() => {
    form.validateFields();
  }, [insuranceType]);

  return (
    <>
      <Row>
        <InsuranceTypeFormItem
          type={type}
          name={relevantInsuranceType}
          OtherOptionFormItemName={otherInsuranceTypeField}
        />
      </Row>
      {Object.values(pfizerPapPlanMarkets).includes(insuranceType) && insuranceType !== pfizerPapPlanMarkets.NONE && (
        <>
          <Row gutter={14}>
            <Col span={12}>
              <FormItem
                name={prescriptionPayerName}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_payer_name")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_payer_name")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={prescriptionGroupId}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_group_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_group_id")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                name={prescriptionMemberId}
                label={t("application_form_editor.pfizer.insurance.labels.prescription_member_id")}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={20}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_member_id")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label={t("application_form_editor.pfizer.insurance.labels.prescription_pcn")}
                rules={[{ required: true }, onlyWhitespacesRule]}
                name={prescriptionPCN}
              >
                <Input
                  maxLength={10}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_pcn")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                label={t("application_form_editor.pfizer.insurance.labels.prescription_bin")}
                name={prescriptionBIN}
                rules={[{ required: true }, onlyWhitespacesRule]}
              >
                <Input
                  maxLength={6}
                  placeholder={t("application_form_editor.pfizer.insurance.placeholders.prescription_bin")}
                />
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem
                rules={[{ pattern: phoneNumberLengthRegEx, message: "" }, { required: true }]}
                name={prescriptionPhoneNumber}
                label={t("application_form_editor.pfizer.insurance.labels.phone_number")}
              >
                <InputPhone placeholder={t("application_form_editor.pfizer.insurance.placeholders.phone_number")} />
              </FormItem>
            </Col>
          </Row>
          <PolicyholderDetails
            policyholderSameAsPatientField={prescriptionPolicyholderSameAsPatient}
            policyholderNameField={prescriptionPolicyholderName}
          />
        </>
      )}
    </>
  );
};
