import React from "react";
import { Input } from "antd";
import { MailOutlined } from "@ant-design/icons";

import { Form } from "antd";
import { emailInputRules } from "../../utils";
import { BLACK } from "../../../../../constant/colors";

export const EmailItem = ({
  name,
  label,
  placeholder,
  required,
  rules = [],
  fieldProps = {},
  additionalRules = []
}) => {
  return (
    <Form.Item rules={[{ required }, ...emailInputRules, ...rules, ...additionalRules]} name={name} label={label}>
      <Input
        addonBefore={<MailOutlined style={{ color: BLACK }} />}
        placeholder={fieldProps.disabled ? "" : placeholder}
        {...fieldProps}
      />
    </Form.Item>
  );
};
