import React from "react";
import { Form, Input, Radio, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

import FormItem from "../../../../../../FormItem";
import { PLAN_MARKETS } from "../../../../../../../constant/planMarkets";
import { onlyWhitespacesRule } from "../../../../utils";
import { MEDICAL_INSURANCE } from "./InsuranceDetails";
import { PRIMARY_INSURANCE, SECONDARY_INSURANCE } from "./Insurance";
import { insuranceFields } from "../../fieldNames";

const { Text } = Typography;

const pfizerPapPlanMarkets = Object.freeze({
  COMMERCIAL: PLAN_MARKETS.Commercial,
  MEDICARE_PART_D: PLAN_MARKETS.MedicarePartD,
  MEDICARE_ADVANTAGE: PLAN_MARKETS.MedicareAdvantage,
  MEDICARE_AB_ONLY: PLAN_MARKETS.MedicarePartAB,
  MEDICAID: PLAN_MARKETS.Medicaid,
  VA_BENEFITS: PLAN_MARKETS.VA,
  OTHER: "other",
  NONE: "none"
});

const getInsuranceTypeFormItemName = (type) => {
  switch (type) {
    case MEDICAL_INSURANCE:
      return insuranceFields.medicalType;
    case PRIMARY_INSURANCE:
      return insuranceFields.primaryPrescriptionType;
    case SECONDARY_INSURANCE:
      return insuranceFields.secondaryPrescriptionType;
  }
};
const InsuranceTypeFormItem = ({ type, name, OtherOptionFormItemName }) => {
  const { t } = useTranslation();

  const insuranceTypeFormItemName = getInsuranceTypeFormItemName(type);
  const insuranceType = Form.useWatch(insuranceTypeFormItemName, Form);

  return (
    <FormItem
      rules={[{ required: true }]}
      name={name}
      label={<Text>{t(`application_form_editor.pfizer.insurance.labels.${type}_insurance_type`)}</Text>}
    >
      <Radio.Group style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <Row>
          <Radio value={pfizerPapPlanMarkets.COMMERCIAL}>
            {t("application_form_editor.pfizer.insurance.commercial")}
          </Radio>
          <Radio value={pfizerPapPlanMarkets.MEDICARE_PART_D}>
            {t("application_form_editor.pfizer.insurance.medicare_part_d")}
          </Radio>
          <Radio value={pfizerPapPlanMarkets.MEDICARE_ADVANTAGE}>
            {t("application_form_editor.pfizer.insurance.medicare_advantage")}
          </Radio>
          <Radio value={pfizerPapPlanMarkets.MEDICARE_AB_ONLY}>
            {t("application_form_editor.pfizer.insurance.medicare_ab_only")}
          </Radio>
          <Radio value={pfizerPapPlanMarkets.MEDICAID}>{t("application_form_editor.pfizer.insurance.medicaid")}</Radio>
        </Row>
        <Row>
          <Radio value={pfizerPapPlanMarkets.VA_BENEFITS} style={{ marginTop: 10 }}>
            {t("application_form_editor.pfizer.insurance.va_benefits")}
          </Radio>
          <Radio style={{ marginTop: 10 }} value={pfizerPapPlanMarkets.NONE}>
            {t("application_form_editor.pfizer.insurance.none")}
          </Radio>

          <Radio style={{ marginTop: 10, marginLeft: 77 }} value={pfizerPapPlanMarkets.OTHER}>
            {t("application_form_editor.pfizer.insurance.other")}
          </Radio>
          {insuranceType === pfizerPapPlanMarkets.OTHER && (
            <div style={{ display: "inline-block", marginLeft: 8, height: 0 }}>
              <FormItem name={OtherOptionFormItemName} rules={[{ required: true }, onlyWhitespacesRule]}>
                <Input placeholder={t("application_form_editor.pfizer.insurance.placeholders.other")} />
              </FormItem>
            </div>
          )}
        </Row>
      </Radio.Group>
    </FormItem>
  );
};

export { pfizerPapPlanMarkets, InsuranceTypeFormItem };
