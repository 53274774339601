import _ from "lodash";
import { Buffer } from "buffer";

const THOUSAND = 1000;
const MILLION = 1000000;

export const getFileSizeByBytes = (sizeInBytes) => {
  if (!sizeInBytes) return;
  const formatValue = (value) => {
    const formatted = value.toFixed(2);
    return formatted.endsWith(".00") ? formatted.slice(0, -3) : formatted;
  };

  if (sizeInBytes < MILLION) {
    return `${formatValue(sizeInBytes / THOUSAND)} KB`;
  }

  return `${formatValue(sizeInBytes / MILLION)} MB`;
};
export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }

  return true;
}

function isObject(object) {
  return object != null && typeof object === "object";
}

export const flattenObject = (obj) => {
  let result = {};

  for (const i in obj) {
    if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
      const temp = flattenObject(obj[i]);
      for (const j in temp) {
        result[j] = temp[j];
      }
    } else {
      result[i] = obj[i];
    }
  }
  return result;
};

export const checkEmpty = (obj) => {
  for (let key in obj) {
    if (obj[key] instanceof Object === true) {
      if (checkEmpty(obj[key]) === false) return false;
    } else {
      if (obj[key] && obj[key].length !== 0) return false;
    }
  }
  return true;
};

export const getObjectDiff = (a, b) =>
  _.reduce(
    _.uniq(_.concat(_.keys(a), _.keys(b))),
    function (result, key) {
      if (_.isEqual(a[key], b[key])) {
        return result;
      } else if (
        (typeof a[key] === "string" && typeof b[key] === "number" && Number(a[key]) === b[key]) ||
        (typeof a[key] === "number" && typeof b[key] === "string" && a[key] === Number(b[key])) ||
        (typeof a[key] === "string" && typeof b[key] === "boolean" && a[key] === String(b[key])) ||
        (typeof a[key] === "boolean" && typeof b[key] === "string" && String(a[key]) === b[key])
      ) {
        return result;
      } else {
        return result.concat(key);
      }
    },
    []
  );

export function getColorByName(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ("00" + value.toString(16)).substr(-2);
  }
  return colour;
}

export const generateHashFromObj = (obj) => {
  return Buffer.from(JSON.stringify(obj)).toString("base64");
};

export const isInRange = (value, min, max) => {
  value = Number(value);
  return value > min && value < max;
};

export const getPurePhoneNumber = (phoneNumber) => phoneNumber.toString().replace(/[^0-9]/g, "");

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
