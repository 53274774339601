import React, { useCallback, useContext, useState } from "react";
import { Layout, Dropdown, Menu, Space, Typography, Button, Affix } from "antd";
import { BuildOutlined, HomeFilled, LogoutOutlined, UploadOutlined } from "@ant-design/icons";
import { withRouter, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { withOktaAuth } from "@okta/okta-react";
import styled from "styled-components";

import ACTIONS from "./../store/action";
import { GRAY_50, HEADER_BLUE, WHITE_GRAY } from "../constant/colors";
import { Routes } from "../constant/routes";
import {
  selectIsPhiAllowed,
  selectUser,
  selectIDPConfig,
  selectSelectedView,
  selectFilterListStatuses
} from "../store/selector";
import { HeaderSearchBar } from "./HeaderSearchBar";
import { Tasks } from "./Tasks/Tasks";
import { Notifications } from "./Notifications";
import { PatientConfirmationAlertContext } from "../context/PatientConfirmationAlertContext";
import { Tooltip } from "./UI/Tooltip";
import { logout } from "@tailormed/common-client/services/auth";
import { defaultFilters } from "../constant/filters";
import { oktaConfig } from "../constant/okta";
import { selectIsDevEnvironment } from "@tailormed/common-client/store/selector";

const { Text } = Typography;
const { Header: AntHeader } = Layout;

const Header = styled(AntHeader)`
  padding: 40px 30px;
  background-color: ${GRAY_50};
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;

const HeaderSearchBarHolder = styled(Space)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px auto;
`;

const IconsHolder = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  > :not(:last-child) {
    margin-right: 16px;
  }
`;

const DropdownCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: ${HEADER_BLUE};
  margin: auto 0;
`;

const MainHeaderComponent = ({
  t,
  history,
  user,
  clearSelectedView,
  selectedView,
  clearSorterAct,
  clearFiltersInputs,
  isPhiAllowed,
  setFiltersAct,
  idpConfig,
  authState,
  resetCurrentSavedListStatus,
  isDevEnvironment
}) => {
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const { pathname } = useLocation();
  const { showCancelConfirm } = useContext(PatientConfirmationAlertContext);
  const handleLogOut = useCallback(() => {
    logout(user, idpConfig, oktaConfig.loginURI);
  }, []);

  const filterListStatuses = useSelector(selectFilterListStatuses);

  const openSecureUploadModal = () => dispatch(ACTIONS.setShowSecureUpload(true));
  const updateFilterListStatusesAct = (filterListID, canModify, showDiscardModal, nextSelectedView) =>
    dispatch(ACTIONS.updateFilterListStatuses(filterListID, canModify, showDiscardModal, nextSelectedView));

  const handleDashboardClick = useCallback(() => {
    if (pathname.includes(Routes.NEW_PATIENT)) {
      return showCancelConfirm(Routes.ROOT);
    }

    if (pathname.includes(Routes.FILTER_LIST) && filterListStatuses?.canModify) {
      setMenuVisible(false);
      updateFilterListStatusesAct(filterListStatuses?.id, filterListStatuses.canModify, true, Routes.ROOT);
      return;
    }

    if (pathname !== Routes.ROOT) {
      clearSorterAct(selectedView);
      setMenuVisible(false);
      setFiltersAct(defaultFilters);
      clearSelectedView();
      clearFiltersInputs();
      resetCurrentSavedListStatus();
      history.push(Routes.ROOT);
    }
  }, [pathname, filterListStatuses]);

  const handleUploadClick = useCallback(() => {
    if (pathname.includes(Routes.NEW_PATIENT)) {
      return showCancelConfirm(Routes.UPLOADER);
    }
    if (pathname !== Routes.UPLOADER) {
      setMenuVisible(false);
      openSecureUploadModal();
    }
  }, [pathname]);

  const handleOnlineWizardClick = useCallback(() => {
    if (pathname !== Routes.ONLINE_WIZARD) {
      setMenuVisible(false);
      history.push(Routes.ONLINE_WIZARD);
    }
  }, [pathname]);

  const menuKeys = {
    DASHBOARD: "dashboard",
    UPLOADER: "uploader",
    ONLINE_WIZARD: "online_wizard",
    LOGOUT: "logout"
  };

  const menu = (
    <Menu>
      {authState?.isAuthenticated &&
        user && [
          <Menu.Item key={menuKeys.DASHBOARD} onClick={handleDashboardClick} hidden>
            <Space>
              <HomeFilled />
              <Text>{t("dashboard")}</Text>
            </Space>
          </Menu.Item>,
          <Menu.Item key={menuKeys.UPLOADER} onClick={handleUploadClick} id="secure_upload">
            <Space>
              <UploadOutlined />
              <Text>{t("uploader_secure_upload")}</Text>
            </Space>
          </Menu.Item>,
          <Menu.Item
            key={menuKeys.ONLINE_WIZARD}
            onClick={handleOnlineWizardClick}
            style={{ display: isDevEnvironment && !user.isExternal ? "block" : "none" }}
          >
            <Space>
              <BuildOutlined />
              <Text>{t("online_wizard.title")}</Text>
            </Space>
          </Menu.Item>,
          <Menu.Item key={menuKeys.LOGOUT} onClick={handleLogOut} id="sign_out">
            <Space>
              <LogoutOutlined />
              <Text>{t("sign_out")}</Text>
            </Space>
          </Menu.Item>
        ]}
    </Menu>
  );

  const dropdownOnOpenChangeEvent = useCallback((event) => {
    setMenuVisible(event);
  }, []);
  return (
    <Affix>
      <Header>
        <HeaderSearchBarHolder>
          <HeaderSearchBar />
        </HeaderSearchBarHolder>
        <Space size={16}>
          <IconsHolder>
            {isPhiAllowed && <Tasks />}
            <Notifications />
          </IconsHolder>

          <Tooltip title={t("profile.tooltip")} overlayStyle={{ ...(menuVisible ? { display: "none" } : {}) }}>
            <Dropdown
              trigger="click"
              placement="bottomRight"
              overlay={menu}
              open={menuVisible && authState?.isAuthenticated}
              onOpenChange={dropdownOnOpenChangeEvent}
              overlayStyle={{ paddingTop: 10 }}
            >
              <Button type="text" style={{ padding: 0 }}>
                <DropdownCircle>
                  <Text strong style={{ color: WHITE_GRAY }}>
                    {`${user ? user.firstName.substring(0, 1) : ""} ${user ? user.lastName.substring(0, 1) : ""}`}
                  </Text>
                </DropdownCircle>
              </Button>
            </Dropdown>
          </Tooltip>
        </Space>
      </Header>
    </Affix>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearSelectedView: () => dispatch(ACTIONS.clearSelectedView()),
  clearSorterAct: (view) => dispatch(ACTIONS.clearSorterAction(view)),
  clearFiltersInputs: () => dispatch(ACTIONS.clearFiltersInputs()),
  setFiltersAct: (filters) => dispatch(ACTIONS.setFiltersAction(filters)),
  resetCurrentSavedListStatus: () => dispatch(ACTIONS.markSavedFilterAsChanged(undefined, false))
});

const mapStateToProps = createStructuredSelector({
  user: selectUser,
  selectedView: selectSelectedView,
  isPhiAllowed: selectIsPhiAllowed,
  idpConfig: selectIDPConfig,
  isDevEnvironment: selectIsDevEnvironment
});

const MainHeader = compose(
  withOktaAuth,
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(MainHeaderComponent);
export { MainHeader };
