import React from "react";
import { useSelector } from "react-redux";
import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { GRAY_50 } from "../../../../../constant/colors";
import { useSelectedApplication } from "../../../hooks";
import {
  selectApplicationForm,
  selectPatientDetails,
  selectSelectedAssistanceProgramById
} from "../../../../../store/selector";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";

const { Text } = Typography;

const CardStyled = styled(Card)`
  .ant-card-body {
    padding: 10px 10px 10px 15px;
  }
  border-radius: 8px;
  background: ${GRAY_50};
  width: 353px;
`;

const FieldNameStyledDiv = styled(Text)`
  font-weight: 300;
  color: #1b1d44;
  font-style: normal;
  line-height: 130%;
`;

const FieldValueStyledText = styled(Text)`
  color: #1b1d44;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
`;

export const EnrollResultCard = ({ enrollData }) => {
  const { t } = useTranslation();
  const application = useSelectedApplication();
  const patientDetails = useSelector(selectPatientDetails);
  const applicationForm = useSelector(selectApplicationForm);
  const selectedAssistanceProgramById = useSelector(selectSelectedAssistanceProgramById);

  const expressEnrollMapping = getExpressEnrollmentMapping();
  const { getEnrollResultData = () => ({}), getAdditionalResultCardInformation } = expressEnrollMapping || {};
  const enrollResultData = enrollData || getEnrollResultData({ application, patientDetails, applicationForm, t });

  const getDetailsToDisplayOnCard = () => {
    const detailsToDisplay = Object.entries(enrollResultData).map(([field, data]) => {
      const value = typeof data === "object" ? data?.value : data;
      const style = data?.style;
      return (
        <div key={field} style={style}>
          <FieldNameStyledDiv>{t(`patientApplications.enroll.enroll_result.${field}`)}</FieldNameStyledDiv>
          <FieldValueStyledText>{value}</FieldValueStyledText>
        </div>
      );
    });
    return detailsToDisplay;
  };

  const detailsToDisplay = getDetailsToDisplayOnCard() || null;
  const additionalResultCardInformation = !enrollData && getAdditionalResultCardInformation;
  const phone = selectedAssistanceProgramById?.phone1;

  return (
    <CardStyled>
      {detailsToDisplay}
      {additionalResultCardInformation && (
        <FieldValueStyledText style={{ display: "flex", marginTop: "10px" }}>
          {t(additionalResultCardInformation, {
            phone: phone
          })}
        </FieldValueStyledText>
      )}
    </CardStyled>
  );
};

export default EnrollResultCard;
