import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "antd";

import { CommonFieldBankController } from "../../../../components/common/commonFieldBankController";
import { CheckboxItem, InputItem, RadioItem } from "../../../../components/form";
import { consentFields, patientFields } from "./form/fieldNames";
import { pfizerCopayOralIQVIAi18nRoutes } from "./form/fieldLabels";
import { useGetLabels } from "../../../../../../../hooks/useGetLabels";
import { formSections } from "./form/sections";
import { DobFormItem } from "../../../../components";
import { GenderFormItem } from "../../../../GenderFormItem";

const { Paragraph } = Typography;

export const getPfizerCopayOralIQVIASpecificSections = () => {
  const { getAllSections } = useGetLabels(pfizerCopayOralIQVIAi18nRoutes);
  const { [formSections.PATIENT_SECTION]: patientLabels, [formSections.CONSENT_SECTION]: consentLabels } =
    getAllSections();

  const commonFields = CommonFieldBankController();

  return {
    patient: {
      ...commonFields.common,
      ...commonFields.patient,
      email: ({ required }) =>
        commonFields.common.email({
          name: patientFields.email,
          label: patientLabels.email,
          required,
          key: "patient-email"
        }),
      gender: ({
        name = patientFields.gender,
        label = patientLabels.sex,
        showError,
        thirdOption = "None",
        width = "100%",
        radioStyle = { display: "flex" },
        additionalOptions,
        showThirdOption = true,
        widthForAdditionalOptions,
        minWidth,
        minWidthForAdditionalOptions,
        required,
        key = "patient-gender"
      }) => (
        <GenderFormItem
          required={required}
          name={name}
          label={label}
          showError={showError}
          width={width}
          thirdOption={thirdOption}
          radioStyle={radioStyle}
          additionalOptions={additionalOptions}
          showThirdOption={showThirdOption}
          widthForAdditionalOptions={widthForAdditionalOptions}
          minWidth={minWidth}
          minWidthForAdditionalOptions={minWidthForAdditionalOptions}
          key={key}
        />
      ),
      caregiver: ({ required, disabled }) => (
        <RadioItem
          name={patientFields.caregiver}
          label={patientLabels.caregiver}
          rules={[{ required }]}
          groupStyle={{ display: "flex", flexDirection: "row" }}
          optionItems={[
            { value: true, displayValue: "Yes" },
            { value: false, displayValue: "No" }
          ]}
          key={"caregiver-pfizer-pfizer-copay-oral-iqvia"}
          fieldProps={{ disabled }}
        />
      ),
      caregiver_firstName: ({ required }) => (
        <InputItem
          name={patientFields.caregiverFirstName}
          label={patientLabels.firstName}
          required={required}
          placeholder={patientLabels.firstNamePlaceholder}
          key={"caregiver_firstName-pfizer-copay-oral-iqvia"}
        />
      ),
      caregiver_lastName: ({ required }) =>
        commonFields.common.lastName({
          name: patientFields.caregiverLastName,
          label: patientLabels.lastName,
          required,
          placeholder: patientLabels.lastNamePlaceholder,
          key: "caregiver_lastName-pfizer-copay-oral-iqvia"
        }),
      caregiver_dob: ({ required }) => (
        <DobFormItem
          required={required}
          name={patientFields.caregiverDob}
          label={patientLabels.birthDate}
          key={"caregiver-dob-pfizer-copay-oral-iqvia"}
        />
      ),
      caregiver_gender: ({
        name = patientFields.caregiverGender,
        label = patientLabels.sex,
        showError,
        thirdOption = "None",
        width = "100%",
        radioStyle = { display: "flex" },
        additionalOptions,
        showThirdOption = true,
        widthForAdditionalOptions,
        minWidth,
        minWidthForAdditionalOptions,
        required,
        key = "patient-gender"
      }) => (
        <GenderFormItem
          required={required}
          name={name}
          label={label}
          showError={showError}
          width={width}
          thirdOption={thirdOption}
          radioStyle={radioStyle}
          additionalOptions={additionalOptions}
          showThirdOption={showThirdOption}
          widthForAdditionalOptions={widthForAdditionalOptions}
          minWidth={minWidth}
          minWidthForAdditionalOptions={minWidthForAdditionalOptions}
          key={key}
        />
      ),
      caregiver_address: ({ required }) =>
        commonFields.common.address({
          required,
          name: patientFields.caregiverAddress,
          label: patientLabels.address,
          placeholder: patientLabels.addressPlaceholder,
          key: "caregiver-address-pfizer-copay-oral-iqvia"
        }),
      caregiver_city: ({ required }) =>
        commonFields.common.city({
          required,
          name: patientFields.caregiverCity,
          label: patientLabels.city,
          placeholder: patientLabels.cityPlaceholder,
          key: "caregiver-city-pfizer-copay-oral-iqvia"
        }),
      caregiver_state: ({ required }) =>
        commonFields.common.state({
          required,
          name: patientFields.caregiverState,
          label: patientLabels.state,
          placeholder: patientLabels.statePlaceholder,
          key: "caregiver-state-pfizer-copay-oral-iqvia"
        }),
      caregiver_zip_code: ({ required }) =>
        commonFields.common.zip_code({
          required,
          name: patientFields.caregiverZip,
          label: patientLabels.zipCode,
          placeholder: patientLabels.zipCodePlaceholder,
          key: "caregiver-zip-pfizer-copay-oral-iqvia"
        }),
      caregiver_email: ({ required }) =>
        commonFields.common.email({
          name: patientFields.caregiverEmail,
          label: patientLabels.email,
          required,
          key: "caregiver-email-pfizer-copay-oral-iqvia"
        }),
      caregiver_phone_number: ({ required }) =>
        commonFields.common.phone_number({
          required,
          name: patientFields.caregiverPhone,
          label: patientLabels.phoneNumber,
          placeholder: patientLabels.phoneNumberPlaceholder,
          typeName: patientFields.caregiverPhoneType,
          style: { display: "flex" },
          key: "caregiver-phone-number-pfizer-copay-oral-iqvia"
        })
    },
    consent: {
      eligibility_criteria_text: ({ shouldAttestAbove18 }) => (
        <Paragraph key={"program-eligibility-criteria-pfizer-copay-oral-iqvia"}>
          <Trans
            i18nKey={consentLabels.programEligibilityCriteriaTextHeader}
            components={{
              bold: <strong />,
              br: <br />
            }}
          />
          {shouldAttestAbove18 && (
            <Trans
              i18nKey={consentLabels.programEligibilityCriteriaTextAbove18}
              components={{
                bold: <strong />,
                br: <br />
              }}
            />
          )}
          <Trans
            i18nKey={consentLabels.programEligibilityCriteriaText}
            components={{
              bold: <strong />,
              br: <br />
            }}
          />
        </Paragraph>
      ),
      eligibility_criteria: ({ required }) => (
        <CheckboxItem
          name={consentFields.programEligibilityCriteria}
          label={consentLabels.programEligibilityCriteria}
          required={required}
          errorMessage={consentLabels.programEligibilityCriteriaError}
          key={"program-eligibility-criteria-checkbox-pfizer-copay-oral-iqvia"}
        />
      ),
      privacy_authorization_text: () => (
        <Paragraph key={"privacy-authorization-pfizer-copay-oral-iqvia"}>
          <Trans
            i18nKey={consentLabels.privacyAuthorizationText}
            components={{
              bold: <strong />
            }}
          />
        </Paragraph>
      ),
      privacy_authorization: ({ required }) => (
        <CheckboxItem
          name={consentFields.privacyAuthorization}
          label={consentLabels.privacyAuthorization}
          required={required}
          errorMessage={consentLabels.privacyAuthorizationError}
          key={"eligibility-criteria-checkbox-pfizer-copay-oral-iqvia"}
        />
      ),
      marketing_communication_text: () => (
        <Paragraph key={"marketing-communication-pfizer-copay-oral-iqvia"}>
          <Trans
            i18nKey={consentLabels.marketingCommunicationText}
            components={{
              bold: <strong />
            }}
          />
        </Paragraph>
      ),
      marketing_communication: ({ required }) => (
        <CheckboxItem
          name={consentFields.marketingCommunication}
          label={consentLabels.marketingCommunication}
          required={required}
          key={"marketing-communication-checkbox-pfizer-copay-oral-iqvia"}
        />
      )
    }
  };
};
