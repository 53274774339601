import React from "react";
import { Form, Input } from "antd";

import { onlyWhitespacesRule } from "../../utils";

export const InputItem = ({
  name,
  label,
  required = false,
  rules = [],
  placeholder,
  fieldProps = {},
  formItemProps = {},
  style
}) => {
  const rulesArr = rules;

  if (required) {
    rulesArr.push(onlyWhitespacesRule);
  }

  return (
    <Form.Item name={name} label={label} rules={[{ required }, ...rulesArr]} style={style} {...formItemProps}>
      <Input {...fieldProps} placeholder={placeholder} />
    </Form.Item>
  );
};
