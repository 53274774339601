import React from "react";
import { Col } from "antd";

import UnhandledType from "./fields/UnhandledType";
import { BOOLEAN_OBJECT } from "./sharedData";
import formFieldComponents from "../Patient/ApplicationFormEditor/components/form/utils/formFieldComponents";

export const PreviewField = ({ field, span, value, handleFieldChange }) => {
  if (!field || !field.type) {
    console.error("Invalid field:", field);
    return null;
  }
  const type = field.type?.replace(/ /g, "_");

  const FieldComponent = formFieldComponents[type] || UnhandledType;
  const isRequired = field.required === BOOLEAN_OBJECT.true;

  return (
    <Col span={span}>
      <FieldComponent
        value={value}
        field={field}
        label={field.label}
        onChange={(value) => handleFieldChange(field.label, value)}
        {...(type === "sex" && { required: isRequired, formItemName: field.label, showThirdOption: false })}
        {...(type === "gender" && { required: isRequired, formItemName: field.label })}
      />
    </Col>
  );
};
