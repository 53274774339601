import React from "react";

import getGenentechCopaySidebarItems from "./sidebarItems";
import * as genentechCopayFieldNames from "./form/fieldNames";
import { getGenentechCopayFormInitialValues } from "./form/initialValues";
import GenentechCopayInsurance from "./form/formSections/Insurance";
import GenentechCopayPatient from "./form/formSections/Patient";
import { PhysicianAndFacility } from "./form/formSections/PhysicianAndFacility";
import { SupportOptions } from "../../components/SupportOptions";
import { DynamicQuestionnaire } from "../../components/DynamicQuestionnaire";
import { SubmittedFormResultModal } from "../../../PatientApplications/enrollTab/submission/SubmittedFormResultModal";
import { SaveButton } from "../../header";
import { EEsignatureStatuses } from "../../../../../constant/EEsignatureStatuses";
import { HEADER_SUBMIT_BUTTON_TYPES } from "../../constants";
import { onSaveGenentechForm } from "../freeDrug/useGenentechFreeDrugConfiguration";
import { CompleteFormButton } from "../../CompleteFormButton";
import { SubTitleStyled } from "../../../PatientApplications/enrollTab/submission/ApprovedResult";
import { selectSelectedAssistanceProgramById } from "../../../../../store/selector";
import { useSelector } from "react-redux";
import { dateFormatter } from "../../../../../utils/date";

const renderApplicationApprovedSubtitle = (t, assignDebitcard) => {
  const selectedAssistanceProgramById = useSelector(selectSelectedAssistanceProgramById);
  const phone = selectedAssistanceProgramById?.phone1;
  return (
    <div style={{ width: "540px", marginBottom: "1rem" }}>
      <SubTitleStyled style={{ whiteSpace: "normal", lineHeight: "24px" }}>
        {t("patientApplications.enroll.enroll_result.application_approved_subtitle", { phone })}
      </SubTitleStyled>
      {assignDebitcard && (
        <SubTitleStyled style={{ whiteSpace: "normal", lineHeight: "24px" }}>
          {t("patientApplications.enroll.enroll_result.application_approved_assign_debitcard_text")}
        </SubTitleStyled>
      )}
    </div>
  );
};

const genentechCopayConfigurations = {
  activeFormSection: "#enrollment-form-eligibility-questionnaire",
  formId: "genentech_copay_smart_editor",
  resultId: "genentech_copay_enroll_result",
  fieldNames: genentechCopayFieldNames,
  getFormInitialValues: getGenentechCopayFormInitialValues,
  getSidebarItems: getGenentechCopaySidebarItems,
  getFormSectionComponents: () => (
    <>
      <SupportOptions />
      <DynamicQuestionnaire
        DividerText={"application_form_editor.genentech.copay.eligibility_questionnaire.header"}
        ineligibleAnswerError={
          "application_form_editor.genentech.copay.eligibility_questionnaire.ineligible_answer_error"
        }
      />
      <GenentechCopayPatient />
      <GenentechCopayInsurance />
      <PhysicianAndFacility />
    </>
  ),
  getViewDetailsModal: (onCancel, visible) => <SubmittedFormResultModal onCancel={onCancel} visible={visible} />,
  applicationFormHeaderSubmission: (props) => <CompleteFormButton id={"EE_genentech_copay_submit"} {...props} />,
  headerSubmitButtonType: HEADER_SUBMIT_BUTTON_TYPES.SUBMIT_FORM,
  saveButton: (props) => <SaveButton onSave={onSaveGenentechForm} id={"EE_genentech_copay_save_form"} {...props} />,
  shouldSaveAppear: () => true,
  getShowEnrollResult: (applicationForm) => applicationForm?.status === EEsignatureStatuses.SUBMITTED,
  prepareButtonId: "EE_genentech_copay_prepare_application_form",
  viewDetailsButtonId: "EE_genentech_copay_view_submission_details",
  applicationSupportsEEId: "application_supports_genentech_copay_EE",
  getEnrollResultData: ({ application }) => ({
    rxbin: application.bin,
    pcn: application.pcn,
    group: application.group,
    member_id: application.memberId,
    card_activation_date: {
      value: dateFormatter(application.approvedDate),
      style: { marginTop: "44.6px" }
    }
  }),
  getApprovedResultDescription: (t, assignDebitcard) => renderApplicationApprovedSubtitle(t, assignDebitcard),
  approvalDetailsButtonId: "EE_genentech_copay_view_approval_details",
  afterSubmissionPayload: () => ({})
};

export default genentechCopayConfigurations;
