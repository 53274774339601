import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { EnrollResultData } from "./EnrollResultData";
import { EnrollResultCard } from "./EnrollResultCard";
import { font14pxR } from "../../../../Typography";
import { GRAY_500 } from "../../../../../constant/colors";
import { ReactComponent as CircledElectricityIcon } from "../../../../../assets/svg/circledElectricityIcon.svg";
import { getExpressEnrollmentMapping } from "../../../ApplicationFormEditor/configurations";
import { getApplicationFormByApplicationIdApi } from "../../../../../api/api";

const { Text } = Typography;

export const SubTitleStyled = styled(Text)`
  ${font14pxR}
  color: ${GRAY_500};
  display: flex;
  text-align: center;
  white-space: pre-line;
`;

const getApplicationFormGetResponse = async (applicationId) => {
  return await getApplicationFormByApplicationIdApi(applicationId);
};

export const ApprovedResult = ({ title, applicationId, hideDescription = false, titleStyle }) => {
  const { t } = useTranslation();
  const [applicationFormGetResponse, setApplicationFormGetResponse] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getApplicationFormGetResponse(applicationId);
        setApplicationFormGetResponse(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [applicationId]);
  const expressEnrollMapping = getExpressEnrollmentMapping();
  const assignDebitcard =
    applicationFormGetResponse?.data?.applicationForm?.formData?.eligibilityQuestionnaire?.["assign-debitcard"] ===
    "Yes";
  const description =
    (!hideDescription && expressEnrollMapping?.getApprovedResultDescription?.(t, assignDebitcard)) || null;

  return (
    <EnrollResultData
      icon={<CircledElectricityIcon />}
      title={title}
      content={<EnrollResultCard />}
      description={description}
      titleStyle={titleStyle}
    />
  );
};
