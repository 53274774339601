import React, { useEffect, useState } from "react";
import { Button, Divider, Form } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import SectionPart from "../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../formSectionsController";
import { ConfigurableRow, ROW_OPTIONS } from "../../../../ConfigurableRow";
import { insuranceFields as commonInsuranceFields, patientFields } from "../../../../components/common/fieldNames";
import { insuranceFields } from "../fieldNames";
import { FormDividerText } from "../../../../components";
import { GRAY_500 } from "../../../../../../../constant/colors";
import { ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME } from "../../../../constants";
import { buildFieldValueMap } from "../../../../../../../utils/form";

const SELF_SUBSCRIBER = "self";

const questionnaireEligibilityReasonQuestion = [ELIGIBILITY_QUESTIONNAIRE_FIELD_NAME, "eligibility-reason"];

const noInsuranceMandatoryQuestionnaireAnswer = "No Insurance";
const insuranceMandatoryQuestionnaireAnswers = [
  "Insured: Can't afford Medication",
  "Insured: No Coverage for Medication"
];

const setInsuranceFormFields = ({
  form,
  isPrimary,
  subscriberFirstName,
  subscriberLastName,
  subscriberDob,
  relationToPatient,
  isInsured
}) => {
  const subscriberFirstNameField = isPrimary
    ? insuranceFields.primarySubscriberFirstName
    : insuranceFields.secondarySubscriberFirstName;
  const subscriberLastNameField = isPrimary
    ? insuranceFields.primarySubscriberLastName
    : insuranceFields.secondarySubscriberLastName;
  const subscriberDOBField = isPrimary ? insuranceFields.primarySubscriberDOB : insuranceFields.secondarySubscriberDOB;
  const relationToPatientField = isPrimary
    ? insuranceFields.primaryRelationToPatient
    : insuranceFields.secondaryRelationToPatient;

  const fieldsToUpdate = buildFieldValueMap([
    ...(isInsured !== undefined ? [[commonInsuranceFields.isInsured, isInsured]] : []),
    ...(subscriberFirstName ? [[subscriberFirstNameField, subscriberFirstName]] : []),
    ...(subscriberLastName ? [[subscriberLastNameField, subscriberLastName]] : []),
    ...(subscriberDob ? [[subscriberDOBField, subscriberDob]] : []),
    ...(relationToPatient ? [[relationToPatientField, relationToPatient]] : [])
  ]);

  form.setFieldsValue(fieldsToUpdate);
};

const InsuranceComponent = ({ isPrimary }) => {
  const form = Form.useFormInstance();
  const insuranceFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)().insurance;

  const isInsured = Form.useWatch(commonInsuranceFields.isInsured, form);
  const patientFirstName = Form.useWatch(patientFields.firstName, form);
  const patientLastName = Form.useWatch(patientFields.lastName, form);
  const patientDOB = Form.useWatch(patientFields.dob, form);

  const relationToPatient = Form.useWatch(
    isPrimary ? insuranceFields.primaryRelationToPatient : insuranceFields.secondaryRelationToPatient,
    form
  );
  const isPatientTheSubscriber = relationToPatient === SELF_SUBSCRIBER;

  useEffect(() => {
    if (relationToPatient === SELF_SUBSCRIBER) {
      setInsuranceFormFields({
        form,
        isPrimary,
        subscriberFirstName: patientFirstName,
        subscriberLastName: patientLastName,
        subscriberDob: patientDOB
      });
    }
    form.validateFields();
  }, [isInsured, patientFirstName, patientLastName, patientDOB]);

  const onChangeRelationToPatient = (isPrimary) => (value) => {
    setInsuranceFormFields({
      form,
      isPrimary,
      subscriberFirstName: value === SELF_SUBSCRIBER ? patientFirstName : "",
      subscriberLastName: value === SELF_SUBSCRIBER ? patientLastName : "",
      subscriberDob: value === SELF_SUBSCRIBER ? patientDOB : "",
      relationToPatient: value
    });

    form.validateFields();
  };

  return (
    <>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_2_1_1_1}>
        {[
          insuranceFormComponents.payerName({
            required: true,
            name: isPrimary ? commonInsuranceFields.primaryPayerName : commonInsuranceFields.secondaryPayerName
          }),
          insuranceFormComponents.subscriberId({
            required: true,
            name: isPrimary ? insuranceFields.primarySubscriberId : insuranceFields.secondarySubscriberId
          }),
          insuranceFormComponents.groupId({
            required: false,
            name: isPrimary ? insuranceFields.primaryGroupId : insuranceFields.secondaryGroupId
          }),
          insuranceFormComponents.relationToPatient({
            required: true,
            onChange: onChangeRelationToPatient(isPrimary),
            name: isPrimary ? insuranceFields.primaryRelationToPatient : insuranceFields.secondaryRelationToPatient
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_1_2}>
        {[
          insuranceFormComponents.subscriberFirstName({
            name: isPrimary ? insuranceFields.primarySubscriberFirstName : insuranceFields.secondarySubscriberFirstName,
            required: true,
            fieldProps: { disabled: isPatientTheSubscriber }
          }),
          insuranceFormComponents.subscriberLastName({
            name: isPrimary ? insuranceFields.primarySubscriberLastName : insuranceFields.secondarySubscriberLastName,
            required: true,
            fieldProps: { disabled: isPatientTheSubscriber }
          }),
          insuranceFormComponents.subscriberDateOfBirth({
            name: isPrimary ? insuranceFields.primarySubscriberDOB : insuranceFields.secondarySubscriberDOB,
            required: true,
            disabled: isPatientTheSubscriber
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_1_BLANK}>
        {[
          insuranceFormComponents.payerPhone({
            name: isPrimary ? insuranceFields.primaryPayerPhone : insuranceFields.secondaryPayerPhone,
            typeName: isPrimary ? insuranceFields.primaryPayerPhoneType : insuranceFields.secondaryPayerPhoneType,
            required: true
          })
        ]}
      </ConfigurableRow>
    </>
  );
};

export default function GenentechFreeDrugInsurance() {
  const [secondInsuranceEnabled, setSecondInsuranceEnabled] = useState(false);
  const { t } = useTranslation();

  const insuranceFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.GENENTECH_PAP)().insurance;
  const form = Form.useFormInstance();

  const eligibilityReason = Form.useWatch(questionnaireEligibilityReasonQuestion, form);
  const didUserChooseNoInsurance = eligibilityReason === noInsuranceMandatoryQuestionnaireAnswer;
  const didUserChooseInsurance = insuranceMandatoryQuestionnaireAnswers.includes(eligibilityReason);

  const isInsured = Form.useWatch(commonInsuranceFields.isInsured, form);

  const onAddSecondaryInsuranceClicked = () => setSecondInsuranceEnabled(true);
  const onDeleteSecondaryInsuranceClicked = () => setSecondInsuranceEnabled(false);

  useEffect(() => {
    if (!isInsured) {
      setSecondInsuranceEnabled(false);
    } else {
      const secondaryPayerName = form.getFieldValue(commonInsuranceFields.secondaryPayerName);

      if (secondaryPayerName) {
        setSecondInsuranceEnabled(true);
      }
    }
  }, [isInsured]);

  useEffect(() => {
    if (!eligibilityReason) return;

    if (didUserChooseNoInsurance) {
      form.setFieldValue(commonInsuranceFields.isInsured, false);
    } else if (didUserChooseInsurance) {
      form.setFieldValue(commonInsuranceFields.isInsured, true);
    }
  }, [eligibilityReason]);

  return (
    <SectionPart
      sectionId="insurance"
      headerTranslationKey="application_form_editor.genentech.free_drug.insurance.labels.header"
    >
      <ConfigurableRow config={ROW_OPTIONS.PROPORTION_ALL_1}>
        {[
          insuranceFormComponents.isInsured({
            required: true,
            formItemProps: { hidden: didUserChooseInsurance || didUserChooseNoInsurance }
          })
        ]}
      </ConfigurableRow>
      {didUserChooseNoInsurance
        ? insuranceFormComponents.informationSection({ isInsured: false })
        : isInsured && (
            <>
              {didUserChooseInsurance && insuranceFormComponents.informationSection({ isInsured: true })}
              <Divider id={`primary-insurance`} orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
                <FormDividerText>
                  {t("application_form_editor.genentech.free_drug.insurance.primary_insurance")}
                </FormDividerText>
              </Divider>
              <InsuranceComponent isPrimary={true} />
              {!secondInsuranceEnabled ? (
                <Button
                  type="link"
                  style={{ margin: 0, padding: 0, fontSize: 14 }}
                  onClick={onAddSecondaryInsuranceClicked}
                >
                  <PlusOutlined /> {t("application_form_editor.genentech.free_drug.insurance.add_secondary_insurance")}
                </Button>
              ) : (
                <>
                  <Divider
                    id={`secondary-insurance`}
                    orientation="left"
                    orientationMargin="0"
                    style={{ color: GRAY_500 }}
                  >
                    <FormDividerText>{"Secondary Insurance"}</FormDividerText>
                  </Divider>
                  <InsuranceComponent isPrimary={false} />
                  <Button type="link" style={{ fontSize: "14px" }} onClick={onDeleteSecondaryInsuranceClicked}>
                    <DeleteOutlined />
                    {t("application_form_editor.genentech.free_drug.insurance.delete_secondary_insurance")}
                  </Button>
                </>
              )}
            </>
          )}
    </SectionPart>
  );
}
