import React from "react";
import { Trans } from "react-i18next";
import { Alert, Typography, Button } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  insuranceFields,
  patientFields,
  practiceFields,
  submitAttestationFields,
  patientConsentFields,
  prescriptionFields,
  medicalFields,
  questionnaireTreatmentSiteFields
} from "./form/fieldNames";
import {
  RadioItem,
  SelectItem,
  InputItem,
  CheckboxItem,
  DiagnosisDetails,
  PreferredLanguageItem
} from "../../components/form";
import { DobFormItem } from "../../components";
import { genentechPAPi18nRoutes } from "./form/fieldLabels";
import { formSections } from "./form/sections";
import { CommonFieldBankController } from "../../components/common/commonFieldBankController";
import { useGetLabels } from "../../../../../hooks/useGetLabels";
import { TransHyperlink } from "../../components/form/TransHyperlink";
import { BLUE_3, LIGHT_BLUE_5 } from "../../../../../constant/colors";
import { FacilitiesItem } from "../../components/form/FacilitiesItem";
import { InputNumberItem } from "../../components/form/InputNumberItem";
import { minNumberValidation, numberValidation, rangeValidationRule } from "../../../../../utils/formValidation";
import { fontWeights } from "../../../../../constant/styles";

const { Text, Paragraph } = Typography;

export const getGenentechSpecificSections = () => {
  const { getLabelsBySection } = useGetLabels(genentechPAPi18nRoutes);
  const patientLabels = getLabelsBySection(formSections.PATIENT_SECTION);
  const insuranceLabels = getLabelsBySection(formSections.INSURANCE_SECTION);
  const practiceLabels = getLabelsBySection(formSections.PRACTICE_SECTION);
  const medicalLabels = getLabelsBySection(formSections.MEDICAL_SECTION);
  const submitAttestationLabels = getLabelsBySection(formSections.SUBMIT_ATTESTATION_SECTION);
  const patientConsentLabels = getLabelsBySection(formSections.PATIENT_CONSENT_SECTION);
  const prescriptionLabels = getLabelsBySection(formSections.PRESCRIPTION_SECTION);
  const treatmentSiteLabels = getLabelsBySection(formSections.TREATMENT_SITE_SECTION);
  const commonFields = CommonFieldBankController();

  return {
    patient: {
      ...commonFields.common,
      ...commonFields.patient,
      preferred_language: ({ required }) => (
        <PreferredLanguageItem
          required={required}
          name={patientFields.primaryLanguage}
          label={patientLabels.preferredLanguage}
          placeholder={patientLabels.preferredLanguagePlaceholder}
          key={"preferred_language-genentech-free-drug"}
        />
      ),
      alternative_contact: ({ required }) => (
        <RadioItem
          name={patientFields.alternativeContact}
          rules={[{ required }]}
          label={patientLabels.alternativeContact}
          optionItems={[
            { value: true, displayValue: "Yes" },
            { value: false, displayValue: "No" }
          ]}
          key={"alternative_contact-genentech-free-drug"}
        />
      ),
      relationship_to_patient: ({ required }) => (
        <SelectItem
          name={patientFields.relationToPatient}
          rules={[{ required }]}
          label={patientLabels.relationToPatient}
          placeholder={patientLabels.relationToPatientPlaceholder}
          optionItems={relationshipToPatientObjects}
          style={{ width: "100%" }}
          bordered={true}
          useCommonStyle={false}
          key={"relationship_to_patient-genentech-free-drug"}
        />
      ),
      patient_divider: () =>
        commonFields.common.divider({ id: "applicatio-form-patient-divider", text: patientLabels.divider }),
      related_firstName: ({ required }) => (
        <InputItem
          name={patientFields.relatedFirstName}
          label={patientLabels.firstName}
          required={required}
          placeholder={patientLabels.firstNamePlaceholder}
          key={"related_firstName-genentech-free-drug"}
        />
      ),
      related_lastName: ({ required }) =>
        commonFields.common.lastName({
          name: patientFields.relatedLastName,
          label: patientLabels.lastName,
          required,
          placeholder: patientLabels.lastNamePlaceholder,
          key: "related_lastName-genentech-free-drug"
        }),
      related_dob: ({ required }) => (
        <DobFormItem
          required={required}
          name={patientFields.relatedDoB}
          label={patientLabels.dob}
          key={"related-dob-genentech-free-drug"}
        />
      ),
      related_phone_number: ({ required }) =>
        commonFields.common.phone_number({
          required,
          name: patientFields.relatedPhoneNumber,
          label: patientLabels.phoneNumber,
          placeholder: patientLabels.phoneNumberPlaceholder,
          typeName: patientFields.relatedPhoneNumberType,
          style: { display: "flex" },
          key: "related-patient-phone-number-genentech-free-drug"
        }),
      related_additional_phone_number: ({ required }) =>
        commonFields.patient.additional_phone_number({
          required,
          name: patientFields.relatedAdditionalPhoneNumber,
          label: patientLabels.additionalPhoneNumber,
          placeholder: patientLabels.additionalPhoneNumberPlaceholder,
          typeName: patientFields.relatedAdditionalPhoneNumberType,
          style: { display: "flex" },
          key: "related-patient-additional-phone-number-genentech-free-drug"
        }),
      signed_patient_authorization: ({ required }) => (
        <CheckboxItem
          name={patientFields.relatedSignedPersontAuth}
          label={"Signed Patient Authorization"}
          required={required}
          key={"signed-patient-authorization-genentech-free-drug"}
        />
      )
    },
    insurance: {
      ...commonFields.insurance,
      subscriberId: ({
        required,
        name = insuranceFields.primarySubscriberId,
        label = insuranceLabels.primarySubscriberId
      }) => (
        <InputItem
          name={name}
          label={label}
          placeholder={insuranceLabels.subscriberIdPlaceholder}
          required={required}
          key={"subscriber-id-genentech-free-drug"}
        />
      ),
      groupId: ({ required, name = insuranceFields.primaryGroupId }) => (
        <InputItem
          name={name}
          label={insuranceLabels.groupId}
          placeholder={insuranceLabels.groupIdPlaceholder}
          required={required}
          key={"group-id-genentech-free-drug"}
        />
      ),
      relationToPatient: ({ required, onChange, name = insuranceFields.primaryRelationToPatient }) => (
        <SelectItem
          name={name}
          label={insuranceLabels.policyholderSameAsPatient}
          placeholder={insuranceLabels.policyholderSameAsPatientPlaceholder}
          rules={[{ required }]}
          optionItems={policyholderSameAsPatientObjects}
          bordered={true}
          onChange={onChange}
          useCommonStyle={false}
          key={"relation-to-patient-genentech-free-drug"}
        />
      ),
      subscriberFirstName: ({ required, fieldProps, name = insuranceFields.primarySubscriberFirstName }) =>
        commonFields.common.firstName({
          required,
          name,
          label: insuranceLabels.subscriberFirstName,
          placeholder: insuranceLabels.subscriberFirstNamePlaceholder,
          fieldProps,
          key: "subscriber-first-name-genentech-free-drug"
        }),
      subscriberLastName: ({ required, fieldProps, name = insuranceFields.primarySubscriberLastName }) =>
        commonFields.common.lastName({
          required,
          name,
          label: insuranceLabels.subscriberLastName,
          placeholder: insuranceLabels.subscriberLastNamePlaceholder,
          fieldProps,
          key: "subscriber-last-name-genentech-free-drug"
        }),
      subscriberDateOfBirth: ({ required, disabled = false, name = insuranceFields.primarySubscriberDOB }) => (
        <DobFormItem
          fieldProps={{ disabled }}
          name={name}
          label={insuranceLabels.subscriberDOB}
          required={required}
          key={"subscriber-dob-genentech-free-drug"}
        />
      ),
      payerPhone: ({ name = insuranceFields.primaryPayerPhone, typeName = insuranceFields.primaryPayerPhoneType }) =>
        commonFields.common.phone_number({
          name,
          label: insuranceLabels.payerPhone,
          required: false,
          placeholder: insuranceLabels.payerPhonePlaceholder,
          typeName,
          key: "payer-phone-genentech-free-drug"
        }),
      isPrescriptionPlan: () => (
        <RadioItem
          name={insuranceFields.isPrescriptionPlan}
          label={insuranceLabels.isPrescriptionPlan}
          optionItems={[
            { value: true, displayValue: "Yes" },
            { value: false, displayValue: "No" }
          ]}
          key={"is-prescription-plan-genentech-free-drug"}
        />
      )
    },
    practice: {
      ...commonFields.common,
      ...commonFields.practice,
      facilitySelect: ({
        name = practiceFields.facilityId,
        label = practiceLabels.facilityName,
        required,
        onDropdownVisibleChange,
        isOpen,
        onChange,
        onClear,
        dropdownRender,
        placeholder = practiceLabels.facilityNamePlaceholder
      }) => (
        <FacilitiesItem
          name={name}
          label={label}
          required={required}
          onDropdownVisibleChange={onDropdownVisibleChange}
          isOpen={isOpen}
          onChange={onChange}
          onClear={onClear}
          dropdownRender={dropdownRender}
          placeholder={placeholder}
          key={"facility-select-genentech-free-drug"}
        />
      ),
      facilityName: ({ required }) => (
        <InputItem
          name={practiceFields.facilityName}
          label={practiceLabels.facilityName}
          required={required}
          placeholder={practiceLabels.facilityInputPlaceholder}
          key={"facility-name-genentech-free-drug"}
        />
      ),
      address: ({ required }) =>
        commonFields.common.address({
          required,
          name: practiceFields.address,
          label: practiceLabels.address,
          placeholder: practiceLabels.addressPlaceholder,
          key: "address-genentech-free-drug"
        }),
      city: ({ required }) =>
        commonFields.common.city({
          required,
          name: practiceFields.city,
          label: practiceLabels.city,
          placeholder: practiceLabels.cityPlaceholder,
          key: "city-genentech-free-drug"
        }),
      state: ({ required }) =>
        commonFields.common.state({
          required,
          name: practiceFields.state,
          label: practiceLabels.state,
          placeholder: practiceLabels.statePlaceholder,
          key: "state-genentech-free-drug"
        }),
      zip: ({ required }) =>
        commonFields.common.zip_code({
          required,
          name: practiceFields.zip,
          label: practiceLabels.zip,
          placeholder: practiceLabels.zipPlaceholder,
          key: "zip-genentech-free-drug"
        }),
      facilityNpi: ({ required }) =>
        commonFields.common.npi({
          required,
          name: practiceFields.facilityNpi,
          label: practiceLabels.facilityNpi,
          placeholder: practiceLabels.facilityNpiPlaceholder,
          key: "facility-npi-genentech-free-drug"
        }),
      prescriberNpi: ({ required }) => commonFields.common.npi({ required, key: "prescriber-npi-genentech-free-drug" }),
      facilityTaxId: ({ required }) =>
        commonFields.common.groupTaxId({
          required,
          name: practiceFields.facilityTaxId,
          label: practiceLabels.facilityTaxId,
          placeholder: practiceLabels.facilityTaxIdPlaceholder,
          key: "facility-tax-id-genentech-free-drug"
        }),
      contactFirstName: ({ required }) =>
        commonFields.common.firstName({
          required,
          name: practiceFields.contactFirstName,
          label: practiceLabels.contactFirstName,
          placeholder: practiceLabels.contactFirstNamePlaceholder,
          key: "contact-first-name-genentech-free-drug"
        }),
      contactLastName: ({ required }) =>
        commonFields.common.lastName({
          required,
          name: practiceFields.contactLastName,
          label: practiceLabels.contactLastName,
          placeholder: practiceLabels.contactLastNamePlaceholder,
          key: "contact-last-name-genentech-free-drug"
        }),
      contactPhone: ({ required, typeName = null }) =>
        commonFields.common.phone_number({
          required,
          typeName,
          name: practiceFields.contactPhone,
          label: practiceLabels.contactPhone,
          placeholder: practiceLabels.contactPhonePlaceholder,
          addon: false,
          key: "contact-phone-genentech-free-drug"
        }),
      contactFax: ({ required, typeName = null }) =>
        commonFields.common.phone_number({
          typeName,
          required,
          name: practiceFields.contactFax,
          label: practiceLabels.contactFax,
          placeholder: practiceLabels.contactFaxPlaceholder,
          addon: false,
          key: "contact-fax-genentech-free-drug"
        }),
      practiceDivider: () =>
        commonFields.common.divider({
          id: "application-form-practice-divider",
          text: practiceLabels.practiceDivider
        })
    },
    prescription: {
      product: ({ required, fieldProps, formItemProps }) => (
        <InputItem
          name={prescriptionFields.product}
          label={prescriptionLabels.product}
          fieldProps={fieldProps}
          required={required}
          formItemProps={formItemProps}
          key={"product-genentech-free-drug"}
        />
      ),
      prescriptionType: ({ required, optionItems, onChange, value }) => (
        <SelectItem
          name={prescriptionFields.prescriptionType}
          label={prescriptionLabels.prescriptionType}
          rules={[{ required }]}
          optionItems={optionItems}
          bordered={true}
          value={value}
          onChange={onChange}
          useCommonStyle={false}
          key={"prescription-type-genentech-free-drug"}
        />
      ),
      prescriptionOption: ({ required, optionItems, onChange, disabled }) => (
        <SelectItem
          disabled={disabled}
          name={prescriptionFields.prescriptionOption}
          label={prescriptionLabels.prescriptionOption}
          rules={[{ required }]}
          optionItems={optionItems}
          bordered={true}
          onChange={onChange}
          useCommonStyle={false}
          key={"prescription-option-genentech-free-drug"}
        />
      ),
      dosageAmount: ({ required, fieldProps, isArray, optionItems, minNumber, maxNumber }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.dosage}
            label={prescriptionLabels.dosage}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
            key={"dosage-amount-genentech-free-drug"}
          />
        ) : (
          <InputNumberItem
            name={prescriptionFields.dosage}
            label={prescriptionLabels.dosage}
            rules={[{ required }, minNumberValidation(minNumber), ...numberValidation(maxNumber)]}
            placeholder={prescriptionLabels.dosage}
            fieldProps={fieldProps}
            key={"dosage-amount-genentech-free-drug"}
          />
        ),
      dosageUnit: ({ required, fieldProps, isArray, optionItems }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.dosageUnit}
            label={prescriptionLabels.dosageUnit}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
          />
        ) : (
          <InputItem
            name={prescriptionFields.dosageUnit}
            label={prescriptionLabels.dosageUnit}
            required={required}
            placeholder={prescriptionLabels.dosageUnit}
            fieldProps={fieldProps}
            key={"dosage-unit-genentech-free-drug"}
          />
        ),
      expectedSupplyDurationUnit: ({ isArray, optionItems, required, fieldProps }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.supplyDuration}
            label={prescriptionLabels.supply_duration}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
            key={"expected-supply-duration-unit-list-genentech-free-drug"}
          />
        ) : (
          <InputItem
            name={prescriptionFields.supplyDuration}
            label={prescriptionLabels.supply_duration}
            required={required}
            placeholder={prescriptionLabels.supply_duration}
            fieldProps={fieldProps}
            key={"expected-supply-duration-unit-genentech-free-drug"}
          />
        ),
      expectedSupplyDurationValue: ({ required, fieldProps, isArray, optionItems, minNumber, maxNumber }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.supplyDurationValue}
            label={prescriptionLabels.supply_duration_value}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
            key={"expected-supply-duration-value-list-genentech-free-drug"}
          />
        ) : (
          <InputItem
            name={prescriptionFields.supplyDurationValue}
            label={prescriptionLabels.supply_duration_value}
            placeholder={prescriptionLabels.supply_duration_value}
            fieldProps={fieldProps}
            required={required}
            rules={[minNumberValidation(minNumber), ...numberValidation(maxNumber)]}
            key={"expected-supply-duration-value-genentech-free-drug"}
          />
        ),
      frequency: ({ required, fieldProps, isArray, optionItems }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.frequency}
            label={prescriptionLabels.frequency}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
            key={"frequency-list-genentech-free-drug"}
          />
        ) : (
          <InputItem
            name={prescriptionFields.frequency}
            label={prescriptionLabels.frequency}
            required={required}
            placeholder={prescriptionLabels.frequency}
            fieldProps={fieldProps}
            key={"frequency-genentech-free-drug"}
          />
        ),
      sig: ({ required, fieldProps }) => (
        <InputItem
          name={prescriptionFields.sig}
          label={prescriptionLabels.sig}
          required={required}
          placeholder={prescriptionLabels.sig}
          fieldProps={fieldProps}
          key={"sig-genentech-free-drug"}
        />
      ),
      dispenseQuantity: ({ required, fieldProps, minNumber, maxNumber }) => (
        <InputNumberItem
          name={prescriptionFields.dispenseQuantity}
          label={prescriptionLabels.dispenseQuantity}
          rules={[{ required }, minNumberValidation(minNumber), ...numberValidation(maxNumber)]}
          placeholder={prescriptionLabels.dispenseQuantity}
          fieldProps={fieldProps}
          key={"dispense-quantity-genentech-free-drug"}
        />
      ),
      dispenseUnit: ({ required, fieldProps, isArray, optionItems }) =>
        isArray ? (
          <SelectItem
            name={prescriptionFields.dispenseUnit}
            label={prescriptionLabels.dispenseUnit}
            rules={[{ required }]}
            optionItems={optionItems}
            bordered={true}
            useCommonStyle={false}
            key={"dispense-unit-list-genentech-free-drug"}
          />
        ) : (
          <InputItem
            name={prescriptionFields.dispenseUnit}
            label={prescriptionLabels.dispenseUnit}
            required={required}
            fieldProps={fieldProps}
            key={"dispense-unit-genentech-free-drug"}
          />
        ),
      refills: ({ required, fieldProps, minNumber, maxNumber }) => (
        <InputNumberItem
          name={prescriptionFields.refills}
          label={prescriptionLabels.refills}
          placeholder={prescriptionLabels.refills}
          rules={[{ required }, ...rangeValidationRule(minNumber, maxNumber)]}
          fieldProps={fieldProps}
          key={"refills-genentech-free-drug"}
        />
      ),
      informationSection: ({ isReplacement }) => (
        <Alert
          style={{ backgroundColor: LIGHT_BLUE_5, borderRadius: 8, marginBottom: 20 }}
          message={
            <Text style={{ color: BLUE_3, whiteSpace: "pre-line" }}>
              {isReplacement
                ? prescriptionLabels.information_replacement_shipment
                : prescriptionLabels.information_notice}
            </Text>
          }
          type="info"
          showIcon
          icon={<InfoCircleOutlined style={{ alignSelf: "flex-start", paddingTop: isReplacement ? 4 : 8 }} />}
        />
      )
    },
    siteOfTreatment: {
      practiceSelect: ({
        name = questionnaireTreatmentSiteFields.facilityId,
        label = practiceLabels.facilityName,
        required,
        onDropdownVisibleChange,
        isOpen,
        onChange,
        onClear,
        placeholder = practiceLabels.facilityNamePlaceholder
      }) => (
        <FacilitiesItem
          name={name}
          label={label}
          required={required}
          onDropdownVisibleChange={onDropdownVisibleChange}
          isOpen={isOpen}
          onChange={onChange}
          onClear={onClear}
          placeholder={placeholder}
          key={"facility-select-genentech-free-drug"}
        />
      ),
      practiceName: ({ required }) => (
        <InputItem
          name={questionnaireTreatmentSiteFields.treatmentSiteName}
          label={treatmentSiteLabels.treatmentSiteName}
          placeholder={treatmentSiteLabels.treatmentSiteNamePlaceholder}
          required={required}
          key={"practice-name-genentech-free-drug"}
        />
      ),
      practiceAddress: ({ required }) => (
        <InputItem
          name={questionnaireTreatmentSiteFields.treatmentAddress}
          label={treatmentSiteLabels.treatmentAddress}
          placeholder={treatmentSiteLabels.treatmentAddressPlaceholder}
          required={required}
          key={"practice-address-genentech-free-drug"}
        />
      ),
      practiceCity: ({ required }) => (
        <InputItem
          name={questionnaireTreatmentSiteFields.treatmentSiteCity}
          label={treatmentSiteLabels.treatmentSiteCity}
          required={required}
          placeholder={treatmentSiteLabels.treatmentSiteCityPlaceholder}
          key={"practice-city-genentech-free-drug"}
        />
      ),
      practiceState: ({ required }) =>
        commonFields.common.state({
          name: questionnaireTreatmentSiteFields.treatmentSiteState,
          required,
          key: "practice-state-genentech-free-drug"
        }),
      practiceZip: ({ required }) =>
        commonFields.common.zip_code({
          name: questionnaireTreatmentSiteFields.treatmentSiteZip,
          required,
          key: "practice-zip-genentech-free-drug"
        }),
      practiceNPI: ({ required }) =>
        commonFields.common.npi({
          required,
          name: questionnaireTreatmentSiteFields.treatmentSiteNPI,
          label: treatmentSiteLabels.treatmentSiteNPI,
          placeholder: treatmentSiteLabels.treatmentSiteNPIPlaceholder,
          key: "practice-npi-genentech-free-drug"
        }),
      practiceTaxId: ({ required }) => (
        <InputItem
          name={questionnaireTreatmentSiteFields.treatmentSiteTaxId}
          label={treatmentSiteLabels.treatmentSiteTaxId}
          required={required}
          placeholder={treatmentSiteLabels.treatmentSiteTaxIdPlaceholder}
          key={"practice-tax-id-genentech-free-drug"}
        />
      ),
      practiceContactName: ({ required }) => (
        <InputItem
          name={questionnaireTreatmentSiteFields.treatmentSiteContactName}
          label={treatmentSiteLabels.treatmentSiteContactName}
          required={required}
          placeholder={treatmentSiteLabels.treatmentSiteContactNamePlaceholder}
          key={"practice-contact-name-genentech-free-drug"}
        />
      ),
      practiceContactPhone: ({ required }) =>
        commonFields.common.phone_number({
          name: questionnaireTreatmentSiteFields.treatmentSiteContactPhone,
          label: treatmentSiteLabels.treatmentSiteContactPhone,
          placeholder: treatmentSiteLabels.treatmentSiteContactPhonePlaceholder,
          typeName: null,
          required,
          key: "practice-contact-phone-genentech-free-drug"
        }),
      practiceContactFax: ({ required }) =>
        commonFields.common.phone_number({
          required,
          name: questionnaireTreatmentSiteFields.treatmentSiteContactFax,
          label: treatmentSiteLabels.treatmentSiteContactFax,
          typeName: null,
          placeholder: treatmentSiteLabels.treatmentSiteContactFaxPlaceholder,
          style: { display: "flex" },
          key: "practice-contact-fax-genentech-free-drug"
        }),
      practiceContactEmail: ({ required }) =>
        commonFields.common.email({
          name: questionnaireTreatmentSiteFields.treatmentSiteContactEmail,
          required,
          key: "practice-contact-email-genentech-free-drug"
        }),
      siteOfTreatment: () =>
        commonFields.common.divider({
          id: "site_of_treatment_divider_id",
          text: treatmentSiteLabels.divider,
          key: "site-of-treatment-genentech-free-drug"
        })
    },
    medical: {
      diagnosisIcd: ({ onDelete, index, allowClear, allowDelete }) => (
        <DiagnosisDetails
          name={medicalFields[index]}
          required={index === 0}
          onDelete={onDelete}
          label={medicalLabels[index]}
          placeholder={medicalLabels.diagnosisPlaceholder}
          allowClear={allowClear}
          allowDelete={allowDelete}
          key={`diagnosis-icd-genentech-free-drug-${index}`}
        />
      ),
      addDiagnosis: ({ onClick, disabled = false }) => (
        <Button type="link" disabled={disabled} onClick={onClick} style={{ margin: 0, padding: 0, fontSize: "14px" }}>
          <PlusOutlined /> {medicalLabels.addDiagnosis}
        </Button>
      )
    },
    submitAttestation: {
      hipaaAuthConsentText: () => (
        <Paragraph key={"hipaa-auth-consent-genentech-free-drug"}>
          <Trans
            i18nKey={submitAttestationLabels.hipaaAuthConsentText}
            components={{
              bold: <strong />
            }}
          />
        </Paragraph>
      ),
      hipaaAuthConsent: ({ required }) => (
        <CheckboxItem
          name={submitAttestationFields.hipaaAuthConsent}
          label={submitAttestationLabels.hipaaAuthConsent}
          required={required}
          errorMessage={submitAttestationLabels.hipaaAuthConsentError}
          key={"hipaa-auth-consent-checkbox-genentech-free-drug"}
        />
      )
    },
    patientConsent: {
      ...commonFields.common,
      patientResourcesConsentText: ({ isEvrysdiDrug }) => (
        <Paragraph key={"patient-resoruces-consent-text-genentech-free-drug"}>
          <Trans
            i18nKey={
              isEvrysdiDrug
                ? patientConsentLabels.patientResourcesConsentTextEvrysdi
                : patientConsentLabels.patientResourcesConsentText
            }
            components={{
              bold: <strong />
            }}
          />
        </Paragraph>
      ),
      patientResourcesConsent: ({ required, isEvrysdiDrug }) => (
        <CheckboxItem
          name={patientConsentFields.patientResourcesConsent}
          label={
            isEvrysdiDrug
              ? patientConsentLabels.patientResourcesConsentEvrysdi
              : patientConsentLabels.patientResourcesConsent
          }
          required={required}
          key={"patient-resources-consent-checkbox-genentech-free-drug"}
        />
      ),
      patientResourcesConsentTextBelow: () => (
        <Paragraph key={"patient-resources-consent-text-genentech-free-drug"}>
          <Trans
            i18nKey={patientConsentLabels.patientResourcesConsentTextBelow}
            components={{
              bold: <strong />,
              br: <br />,
              a_privacy: (
                <TransHyperlink target="_blank" rel="noopener noreferrer" url="http://www.gene.com/privacy-policy" />
              ),
              a_terms_conditions: (
                <TransHyperlink
                  target="_blank"
                  rel="noopener noreferrer"
                  url="http://www.gene.com/terms-conditions/sms-text-message-program-terms-conditions"
                />
              )
            }}
          />
        </Paragraph>
      ),
      authorizationRequiredDivider: () =>
        commonFields.common.divider({
          id: "application-form-patient-authorization-required-divider",
          text: patientConsentLabels.authorizationRequiredDivider
        }),
      authorizationRequiredText: () => (
        <Paragraph key={"authorization-required-text-gententech-free-drug"}>
          <Trans
            i18nKey={patientConsentLabels.authorizationRequiredText}
            components={{
              bold: <strong style={{ fontWeight: fontWeights.bold }} />,
              br: <br />,
              a_privacy: (
                <TransHyperlink target="_blank" rel="noopener noreferrer" url="http://www.gene.com/privacy-policy" />
              )
            }}
          />
        </Paragraph>
      ),
      personalInfoConsent: ({ required }) => (
        <CheckboxItem
          name={patientConsentFields.personalInfoConsent}
          label={patientConsentLabels.personalInfoConsent}
          required={required}
          errorMessage={patientConsentLabels.personalInfoConsentError}
          key={"personal-info-consent-checkbox-genentech-free-drug"}
        />
      ),
      personalInfoConsentSignerRelationship: ({ required, onChange }) => (
        <SelectItem
          name={patientConsentFields.personalInfoConsentSignerRelationship}
          label={patientConsentLabels.personalInfoConsentSignerRelationship}
          rules={[{ required }]}
          placeholder={patientConsentLabels.personalInfoConsentSignerRelationshipPlaceholder}
          optionItems={policyholderSameAsPatientObjects}
          bordered={true}
          onChange={onChange}
          useCommonStyle={false}
          key={"personal-info-consent-signer-relationship-genentech-free-drug"}
        />
      ),
      personalInfoConsentSignerFirstName: ({ required, ...props }) =>
        commonFields.common.firstName({
          required,
          name: patientConsentFields.personalInfoConsentSignerFirstName,
          label: practiceLabels.personalInfoConsentSignerFirstName,
          key: "personal-info-consent-signer-first-name-genentech-free-drug",
          ...props
        }),
      personalInfoConsentSignerLastName: ({ required, ...props }) =>
        commonFields.common.lastName({
          required,
          name: patientConsentFields.personalInfoConsentSignerLastName,
          label: practiceLabels.personalInfoConsentSignerLastName,
          key: "personal-info-consent-signer-last-name-genentech-free-drug",
          ...props
        }),
      personalInfoConsentSignerEmail: ({ required, ...props }) =>
        commonFields.common.email({
          required,
          name: patientConsentFields.personalInfoConsentSignerEmail,
          label: patientConsentLabels.personalInfoConsentSignerEmail,
          placeholder: patientConsentLabels.personalInfoConsentSignerPlaceholderEmail,
          key: "personal-info-consent-signer-email-genentech-free-drug",
          ...props
        })
    }
  };
};
const policyholderSameAsPatientObjects = [
  { value: "self", displayValue: "Self" },
  { value: "spouse", displayValue: "Spouse" },
  { value: "child", displayValue: "Child" },
  { value: "other", displayValue: "Other caregiver" }
];

export const relationshipToPatientObjects = [
  { value: "Caregiver", displayValue: "Caregiver" },
  { value: "Child", displayValue: "Child" },
  { value: "Family", displayValue: "Family" },
  { value: "Friend", displayValue: "Friend" },
  { value: "Parent", displayValue: "Parent" },
  { value: "Partner", displayValue: "Partner" },
  { value: "Spouse", displayValue: "Spouse" }
];
