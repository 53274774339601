import { useEffect } from "react";
import { Form } from "antd";

const useConditionalValidation = (name, shouldValidateFields) => {
  if (!shouldValidateFields) return;

  const form = Form.useFormInstance();
  const field = Form.useWatch(name, form);

  useEffect(() => {
    form.validateFields();
  }, [field]);
};

export default useConditionalValidation;
