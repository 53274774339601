import React, { useState } from "react";
import { Anchor } from "antd";
import styled from "styled-components";

import SidebarItem from "./sidebarItem";

const StyledAnchor = styled(Anchor)`
  .ant-anchor-ink {
    display: none;
  }
`;

const StyledAnchorDisabled = ({ children, ...props }) => (
  <StyledAnchor onClick={(event) => event.preventDefault()} {...props}>
    {children}
  </StyledAnchor>
);

export default function Sidebar({ sectionsWithErrors, sidebarItems, activeFormSection, formId, disabled }) {
  const [activeSection, setActiveSection] = useState(activeFormSection);
  const AnchorComponent = disabled ? StyledAnchorDisabled : StyledAnchor;
  return (
    <AnchorComponent
      onChange={(activeHref) => setActiveSection(activeHref)}
      getContainer={() => document.getElementById(formId)}
    >
      {sidebarItems.map(({ href, title, key, icon }) => (
        <SidebarItem
          key={key}
          href={href}
          title={title}
          icon={icon}
          isActive={activeSection === href}
          isCompleted={!sectionsWithErrors.includes(key)}
        />
      ))}
    </AnchorComponent>
  );
}
