import { formSections } from "./sections";

export const pfizerCopayOralIQVIAi18nRoutes = {
  [formSections.PATIENT_SECTION]: {
    firstName: "application_form_editor.pfizer.copay.oral.patient.labels.first_name",
    lastName: "application_form_editor.pfizer.copay.oral.patient.labels.last_name",
    birthDate: "application_form_editor.pfizer.copay.oral.patient.labels.birth_date",
    sex: "application_form_editor.pfizer.copay.oral.patient.labels.sex",
    address: "application_form_editor.pfizer.copay.oral.patient.labels.address",
    city: "application_form_editor.pfizer.copay.oral.patient.labels.city",
    state: "application_form_editor.pfizer.copay.oral.patient.labels.state",
    zipCode: "application_form_editor.pfizer.copay.oral.patient.labels.zip_code",
    email: "application_form_editor.pfizer.copay.oral.patient.labels.email",
    phoneNumber: "application_form_editor.pfizer.copay.oral.patient.labels.phone_number",
    caregiver: "application_form_editor.pfizer.copay.oral.patient.labels.caregiver",

    firstNamePlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.first_name",
    lastNamePlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.last_name",
    addressPlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.address",
    cityPlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.city",
    statePlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.state",
    zipCodePlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.zip_code",
    emailPlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.email",
    phoneNumberPlaceholder: "application_form_editor.pfizer.copay.oral.patient.placeholders.phone_number"
  },
  [formSections.CONSENT_SECTION]: {
    programEligibilityCriteriaTextHeader:
      "application_form_editor.pfizer.copay.oral.consent.labels.program_eligibility_criteria_text_header",
    programEligibilityCriteriaTextAbove18:
      "application_form_editor.pfizer.copay.oral.consent.labels.program_eligibility_criteria_text_above_18",
    programEligibilityCriteriaText:
      "application_form_editor.pfizer.copay.oral.consent.labels.program_eligibility_criteria_text",
    programEligibilityCriteria: "application_form_editor.pfizer.copay.oral.consent.labels.program_eligibility_criteria",
    programEligibilityCriteriaError:
      "application_form_editor.pfizer.copay.oral.consent.errors.program_eligibility_criteria",
    privacyAuthorizationText: "application_form_editor.pfizer.copay.oral.consent.labels.privacy_authorization_text",
    privacyAuthorization: "application_form_editor.pfizer.copay.oral.consent.labels.privacy_authorization",
    privacyAuthorizationError: "application_form_editor.pfizer.copay.oral.consent.errors.privacy_authorization",
    marketingCommunicationText: "application_form_editor.pfizer.copay.oral.consent.labels.marketing_communication_text",
    marketingCommunication: "application_form_editor.pfizer.copay.oral.consent.labels.marketing_communication",
    marketingCommunicationError: "application_form_editor.pfizer.copay.oral.consent.errors.marketing_communication"
  }
};
