import React from "react";
import { Col, Divider, Input, Row, Form, Checkbox, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { patientFields } from "../fieldNames";
import { phoneNumberLengthRegEx } from "../../../../../../utils/formValidation";
import { GRAY_500 } from "../../../../../../constant/colors";
import { DobFormItem, FormDividerText, FormItem, InputPhoneWithPhoneTypeAddon } from "../../../components";
import { emailInputRules, onlyWhitespacesRule, zipInputRules } from "../../../utils";
import { SingleSelect } from "../../../../../customComponent/CustomSelect";
import { States as STATES } from "../../../../../../constant/states";
import { GenderFormItem } from "../../../GenderFormItem";

const { Option } = Select;

export default function PfizerFreeDrugPatient() {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const gender = Form.useWatch(patientFields.gender, form);
  const caregiverName = Form.useWatch(patientFields.caregiverName, form);

  return (
    <>
      <div id="enrollment-form-patient-information-anchor" />
      <Divider orientation="left" orientationMargin="0" style={{ color: GRAY_500 }}>
        <FormDividerText>{t("application_form_editor.pfizer.patient.labels.header")}</FormDividerText>
      </Divider>

      <Form.Item name={patientFields.id} hidden />

      <Row gutter={14}>
        <Col span={8}>
          <FormItem name={patientFields.reEnrollment} valuePropName="checked">
            <Checkbox>{t("application_form_editor.pfizer.patient.labels.reEnrollment")}</Checkbox>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={patientFields.firstName}
            label={t("application_form_editor.pfizer.patient.labels.first_name")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.first_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={patientFields.middleName}
            label={t("application_form_editor.pfizer.patient.labels.middle_initial")}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.middle_initial")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.lastName}
            label={t("application_form_editor.pfizer.patient.labels.last_name")}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.last_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <DobFormItem
            required
            name={patientFields.dob}
            label={t("application_form_editor.pfizer.patient.labels.dob")}
          />
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={12}>
          <GenderFormItem
            showError={!gender}
            name={patientFields.gender}
            label={t("application_form_editor.pfizer.patient.labels.sex")}
            thirdOption={t("application_form_editor.pfizer.patient.other")}
          />
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, { pattern: phoneNumberLengthRegEx, message: "" }]}
            name={patientFields.phone}
            label={t("application_form_editor.pfizer.patient.labels.phone_number")}
          >
            <InputPhoneWithPhoneTypeAddon
              placeholder={t("application_form_editor.pfizer.patient.placeholders.phone_number")}
              typeName={patientFields.phoneType}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={emailInputRules}
            name={patientFields.email}
            label={t("application_form_editor.pfizer.patient.labels.email")}
          >
            <Input
              addonBefore={<MailOutlined />}
              placeholder={t("application_form_editor.pfizer.patient.placeholders.email")}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, onlyWhitespacesRule]}
            name={patientFields.address}
            label={t("application_form_editor.pfizer.patient.labels.street_address")}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.street_address")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={patientFields.city}
            label={t("application_form_editor.pfizer.patient.labels.city")}
            rules={[{ required: true }, onlyWhitespacesRule]}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.city")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }]}
            name={patientFields.state}
            label={t("application_form_editor.pfizer.patient.labels.state")}
          >
            <SingleSelect
              useCommonStyle={false}
              bordered={true}
              placeholder={t("application_form_editor.pfizer.patient.placeholders.state")}
            >
              {Object.values(STATES).map((state, index) => (
                <Option key={index} value={state.code}>
                  {state.name}
                </Option>
              ))}
            </SingleSelect>
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[{ required: true }, ...zipInputRules]}
            name={patientFields.zip}
            label={t("application_form_editor.pfizer.patient.labels.zip_code")}
          >
            <Input
              maxLength={5}
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.patient.placeholders.zip_code")}
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={14}>
        <Col span={6}>
          <FormItem
            name={patientFields.primaryLanguage}
            label={t("application_form_editor.pfizer.patient.labels.primary_language")}
          >
            <Input
              style={{ width: "100%" }}
              placeholder={t("application_form_editor.pfizer.patient.placeholders.primary_language")}
            />
          </FormItem>
        </Col>
        <Col span={18}>
          <FormItem
            label={t("application_form_editor.pfizer.patient.labels.contact_time")}
            name={patientFields.contactTime}
          >
            <Checkbox.Group>
              <Row gutter={14}>
                <Col>
                  <Checkbox value="morning">{t("application_form_editor.pfizer.patient.morning")}</Checkbox>
                </Col>
                <Col>
                  <Checkbox value="afternoon">{t("application_form_editor.pfizer.patient.afternoon")}</Checkbox>
                </Col>
                <Col>
                  <Checkbox value="evening">{t("application_form_editor.pfizer.patient.evening")}</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </FormItem>
        </Col>
      </Row>

      <Row gutter={14}>
        <Col span={12}>
          <FormItem
            name={patientFields.caregiverName}
            label={t("application_form_editor.pfizer.patient.labels.caregiver_full_name")}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.caregiver_full_name")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            name={patientFields.caregiverRelationship}
            label={t("application_form_editor.pfizer.patient.labels.caregiver_relationship")}
          >
            <Input placeholder={t("application_form_editor.pfizer.patient.placeholders.caregiver_relationship")} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem
            rules={[
              { pattern: phoneNumberLengthRegEx, message: "" },
              { required: !!caregiverName, message: "" }
            ]}
            name={patientFields.caregiverPhone}
            label={t("application_form_editor.pfizer.patient.labels.caregiver_phone")}
          >
            <InputPhoneWithPhoneTypeAddon
              placeholder={t("application_form_editor.pfizer.patient.placeholders.caregiver_phone")}
              typeName={patientFields.caregiverPhoneType}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
}
