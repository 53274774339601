import React, { useState, useEffect, useRef } from "react";
import { Select, DatePicker } from "antd";
import { withTranslation } from "react-i18next";
import { CalendarOutlined } from "@ant-design/icons";

import { SingleSelect } from "../customComponent/CustomSelect";
import {
  dateFilterTypes,
  ALL_TIMES,
  DIAGNOSIS_FILTER_DATE,
  DRUG_FILTER_CREATED_DATE,
  TABLE_FILTER_CONTAINER_ID
} from "../../constant/filters";
import { DATE_FORMAT, setDateRange } from "../../utils/date";
import moment from "moment-timezone";

const { RangePicker } = DatePicker;
const { Option } = Select;

const DateRangeFilterComponent = ({
  t,
  timeRange,
  disabled,
  onSelect,
  dateFilters,
  handleInput,
  id,
  disabledDate,
  allowClear,
  onClear,
  defaultValue,
  placeHolder,
  withRangePicker = true
}) => {
  const fromDate = timeRange.fromDate ? moment(timeRange.fromDate, DATE_FORMAT) : null;
  const toDate = timeRange.toDate ? moment(timeRange.toDate, DATE_FORMAT) : null;
  const selectedValue = timeRange.value;
  const isRangeSelected = fromDate && toDate && !selectedValue;
  const [isRangeOpen, setIsRangeOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);

  const updateDropdownPosition = (scrollingEnabled = false) => {
    const dropdown = dropdownRef.current;
    const container = containerRef.current;

    if (dropdown && container) {
      const containerRect = container.getBoundingClientRect();
      const notDrugFilterCreatedDate = id != DRUG_FILTER_CREATED_DATE;
      const notDiagnosisFilterDate = id != DIAGNOSIS_FILTER_DATE;

      let adjustmentOffsetBottom = 0;
      if (id === DIAGNOSIS_FILTER_DATE) adjustmentOffsetBottom = 200;
      if (id === DRUG_FILTER_CREATED_DATE) adjustmentOffsetBottom = 100;

      const dropdownListElement = document.querySelector(".ant-select-dropdown:not(.ant-select-dropdown-hidden)");

      if (dropdownListElement && (notDiagnosisFilterDate || notDrugFilterCreatedDate)) {
        const collapseRect = dropdownListElement.getBoundingClientRect();
        adjustmentOffsetBottom = -(adjustmentOffsetBottom + collapseRect.height);
      }

      dropdown.style.top = `${containerRect.bottom + adjustmentOffsetBottom}px`;

      const arrow = dropdown.querySelector(".ant-picker-range-arrow");
      if (arrow && scrollingEnabled && (notDiagnosisFilterDate || notDrugFilterCreatedDate)) {
        arrow.style.top = "unset";
        arrow.style.transform = "rotate(45deg)";
        arrow.style.bottom = "2.5px";
      }
    }
  };

  useEffect(() => {
    if (isRangeOpen) {
      const dropdown = document.querySelector(".ant-picker-dropdown:not(.ant-picker-dropdown-hidden)");
      if (dropdown) {
        dropdownRef.current = dropdown;
        dropdown.style.position = "absolute";
        updateDropdownPosition();
      }
    }
  }, [isRangeOpen]);

  const handleOpenChange = (open) => {
    setIsRangeOpen(open);
    if (open) {
      setTimeout(updateDropdownPosition, 0);
    }
  };

  const setRangePicker = (values) => {
    setOpen(false);

    const formattedDates = setDateRange(values[0], values[1]);
    onSelect(...formattedDates);

    // Note: reset the date value for the filter input.
    handleInput();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isRangeOpen) updateDropdownPosition(true);
    };

    const scrollContainer = document.getElementById(TABLE_FILTER_CONTAINER_ID);
    scrollContainer.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateDropdownPosition);

    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateDropdownPosition);
    };
  }, [isRangeOpen]);

  return (
    <div id={id ? id : undefined} ref={containerRef}>
      <SingleSelect
        showSearch={false}
        placeholder={placeHolder}
        disabled={disabled}
        open={open}
        onClick={() => {
          if (isFocus && !open) setOpen(true);
        }}
        onFocus={() => {
          setIsFocus(true);
          setOpen(true);
        }}
        onBlur={() => {
          setIsFocus(false);
          if (!isRangeOpen) setOpen(false);
        }}
        onSelect={(value) => {
          setOpen(false);

          if (value === selectedValue) {
            return;
          } else {
            handleInput(value);

            if (value === defaultValue) {
              onClear();
            } else {
              onSelect(null, null, value);
            }
          }
        }}
        suffixIcon={<CalendarOutlined />}
        value={
          //: value of null will make the placeHolder take effect.
          isRangeSelected
            ? `${fromDate.format("MM-DD-YYYY")} - ${toDate.format("MM-DD-YYYY")}`
            : selectedValue === defaultValue
            ? null
            : selectedValue
        }
        dropdownRender={(menu) => (
          <div>
            {menu}
            {withRangePicker && (
              <RangePicker
                value={isRangeSelected ? [fromDate, toDate] : null}
                disabledDate={disabledDate}
                suffixIcon={null}
                clearIcon={null}
                separator={null}
                bordered={false}
                allowEmpty={[false, false]}
                onChange={setRangePicker}
                onOpenChange={handleOpenChange}
              />
            )}
          </div>
        )}
        allowClear={allowClear}
        onClear={onClear}
      >
        <Option value={ALL_TIMES} id={"all_times"}>
          {t("all_times")}
        </Option>
        {dateFilterTypes[dateFilters].map((filterType) => (
          <Option value={filterType.value} key={filterType.value} id={filterType.id || undefined}>
            {t(filterType.value)}
          </Option>
        ))}
      </SingleSelect>
    </div>
  );
};

const DateRangeFilter = withTranslation()(DateRangeFilterComponent);

export { DateRangeFilter };
