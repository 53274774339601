import React from "react";
import { useSelector } from "react-redux";

import SectionPart from "../../../../../../components/SectionPart";
import { formSectionsController, vendors } from "../../../../../../formSectionsController";
import { ConfigurableRow } from "../../../../../../ConfigurableRow";
import { selectCurrentApplication } from "../../../../../../../../../store/selector";

const bosulifAndXalkori = ["bosulif", "xalkori"];
export default function PfizerCopayOralConsent() {
  const ConsentFormComponents = formSectionsController().getSectionFieldsByVendor(vendors.PFIZER_COPAY_ORAL_IQVIA)()
    .consent;

  const currentApplication = useSelector(selectCurrentApplication);
  const associatedDrug = currentApplication?.drugDisplayName.toLowerCase();
  const isBosulifOrXalkori = bosulifAndXalkori.includes(associatedDrug);

  return (
    <SectionPart
      sectionId="Consent"
      headerTranslationKey="application_form_editor.pfizer.copay.oral.consent.labels.header"
    >
      <ConfigurableRow>
        {[ConsentFormComponents.eligibility_criteria_text({ shouldAttestAbove18: !isBosulifOrXalkori })]}
      </ConfigurableRow>
      <ConfigurableRow>{[ConsentFormComponents.eligibility_criteria({ required: true })]}</ConfigurableRow>
      <ConfigurableRow>{[ConsentFormComponents.privacy_authorization_text()]}</ConfigurableRow>
      <ConfigurableRow>
        {[
          ConsentFormComponents.privacy_authorization({
            required: true
          })
        ]}
      </ConfigurableRow>
      <ConfigurableRow>{[ConsentFormComponents.marketing_communication_text()]}</ConfigurableRow>
      <ConfigurableRow>{[ConsentFormComponents.marketing_communication({ required: false })]}</ConfigurableRow>
    </SectionPart>
  );
}
