import React from "react";
import { InputNumber, Form } from "antd";

export const InputNumberItem = ({ name, label, required = false, rules, placeholder, fieldProps, style }) => {
  return (
    <Form.Item name={name} label={label} rules={[{ required }, ...rules]} style={style}>
      <InputNumber {...fieldProps} placeholder={placeholder} />
    </Form.Item>
  );
};
